import React from 'react';
import { SignInForms } from '../../../modules/auth';
import { CenteredContainer } from 'shared/containers';

const AuthSignInPage = () => {
   return (
      <CenteredContainer>
         <SignInForms />
      </CenteredContainer>
   );
};

export default AuthSignInPage;
