import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import theme from './styles/theme';

import AppRouters from 'components/AppRouter';

function App() {
   return (
      <div className="App">
         <ThemeProvider theme={theme}>
            <AppRouters />
         </ThemeProvider>
      </div>
   );
}

export default App;
