import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ILeadApiResponse } from '@projectTypes/lead';

import {
   apiDashboard,
   apiDashboardLead,
   apiDashboardLeadRuntime,
} from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const leadApi = createApi({
   reducerPath: 'leadApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      lead: builder.query<ILeadApiResponse, void>({
         query: () => ({
            url: apiDashboardLead as string,
         }),
      }),
      leadRuntime: builder.query<ILeadApiResponse, void>({
         query: () => ({
            url: apiDashboardLeadRuntime as string,
         }),
      }),
   }),
});

export const { useLeadRuntimeQuery, useLeadQuery } = leadApi;
