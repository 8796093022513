import React, { FC } from 'react';

import { useGoBack } from '@hooks/useGoBack';

import { Box, Grid } from '@mui/material';
import { ButtonDefault } from '@shared/buttons';

import { ReactComponent as IconArrow } from '@assets/icons/arrow.svg';

import type { VisualScriptBoxActionProps } from './type';
import type { IRuntimeReaction } from '@projectTypes/runtime';

import {
   visualScriptBoxActionButtonStyles,
   visualScriptBoxActionStyles,
   visualScriptBoxActionButtonGoBackStyles,
} from './styles';

export const VisualScriptBoxAction: FC<VisualScriptBoxActionProps> = ({
   currentNode,
   isEnd,
   handleReactionClick,
   setReactionType,
   isLoading,
}) => {
   const goBack = useGoBack();

   if (isEnd || !currentNode) {
      return null;
   }

   const onClick = (reaction: IRuntimeReaction) => {
      const { properties } = reaction;

      setReactionType(properties.reaction_type);
      handleReactionClick(reaction);
   };

   return (
      <Box sx={{ ...visualScriptBoxActionStyles() }}>
         <ButtonDefault
            onClick={goBack}
            startIcon={<IconArrow />}
            label="верунтсья назад"
            sx={visualScriptBoxActionButtonGoBackStyles() as object}
            disabled={isLoading}
         />
         <Grid container spacing={2}>
            {currentNode.map((reaction: IRuntimeReaction) => (
               <Grid item xs={12} md={6} key={reaction.id}>
                  <ButtonDefault
                     sx={{
                        ...visualScriptBoxActionButtonStyles({
                           reactin: reaction.properties.reaction_type,
                        }),
                     }}
                     onClick={() => onClick(reaction)}
                     label={reaction.properties.text}
                     disabled={isLoading}
                  />
               </Grid>
            ))}
         </Grid>
      </Box>
   );
};
