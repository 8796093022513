import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const clientsHeaderBoxStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'flex-start',

   gap: '8px',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
   },
});

export const clientsHeaderButtonStyles = (): SxProps<Theme> => ({
   justifyContent: 'center',
   alignItems: 'center',
   minWidth: '24px',
   padding: '3px',
   '& > span': {
      margin: 0,

      display: 'inherit',
   },
});
