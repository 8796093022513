import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light, light500, black50 } = colorsUI;

export const headerLogoStyles: CSSProperties = {
   maxWidth: '219px',
   height: '43px',
};

export const headerLogoBox: CSSProperties = {
   display: 'flex',
   gap: '4px',
   alignItems: 'center',
};
export const headerchangeThemeButton: SxProps<Theme> = (theme: Theme) => ({
   padding: 0,
   minWidth: '32px',
});

export const headerStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   flexDirection: 'row',
   background: light,
   border: `1px solid ${black50}`,
   boxShadow: 'none',
   padding: '32px 48px',
   [theme.breakpoints.down('lg')]: {
      padding: '24px 15px',
   },
});

export const headerNavigationItemStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   justifyContent: 'space-around',
   alignItems: 'center',
   gap: '24px',
   [theme.breakpoints.down('lg')]: {
      display: 'none',
   },
});
export const headerNavigationItemLinkStyles: CSSProperties = {
   border: `1px solid ${light500}`,
   padding: '10px 24px',
};
