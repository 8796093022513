import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, Typography } from '@mui/material';

import type { ListItemSelectProps } from '../types';

import { listItemSelectStyles } from './style';

export const ListItemSelect: FC<ListItemSelectProps> = ({
   Icon,
   label,
   onClick,
   positon = 'before',
   variantTypography = 'body1',
   stylesIcon,
   stylesInner,
   stylesLabel,
   disable = false,
   error = false,
   selected = false,
}) => {
   return (
      <ListItem
         onClick={onClick}
         sx={{
            ...listItemSelectStyles(disable, error, selected),
            ...(stylesInner && typeof stylesInner === 'object' ? stylesInner : {}),
         }}
      >
         {positon === 'before' && <Icon style={stylesIcon} />}
         <Typography variant={variantTypography} sx={stylesLabel}>
            {label}
         </Typography>
         {positon === 'after' && <Icon style={stylesIcon} />}
      </ListItem>
   );
};
