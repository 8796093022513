import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getBreadcrumbs } from './getBreadcrumbs';

import { breadcrumbsLinkStyles, breadcrumbsStyles } from './styles';

export const Breadcrumbs = () => {
   const location = useLocation();

   const breadcrumbsItem = getBreadcrumbs(location.pathname);

   return (
      <>
         {location.pathname !== '/' && (
            <Box sx={breadcrumbsStyles}>
               {breadcrumbsItem.map(({ path, message }, i) => (
                  <Typography
                     variant="overline"
                     component={Link}
                     sx={breadcrumbsLinkStyles}
                     to={path}
                     key={path}
                  >
                     {message}
                     {i !== breadcrumbsItem.length - 1 && '/'}
                  </Typography>
               ))}
            </Box>
         )}
      </>
   );
};
