export const setTokenInCookie = (token: string) => {
   document.cookie = `authToken=${token}; path=/; SameSite=Strict`;
};

export const removeTokenFromCookie = () => {
   document.cookie = `authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict`;
};

export const getTokenFromCookie = (): string | null => {
   const cookie = document.cookie.split('; ').find((row) => row.startsWith('authToken='));
   return cookie ? cookie.split('=')[1] : null;
};
