import React, { FC, ChangeEvent, ReactNode } from 'react';

import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';

import { ChexboxDefault } from '../../chexboxs/chexboxDefault/index';

import type { SelectCheckboxProps, SelectCheckboxOptionType } from '../types';

import ArrowSelectIcon from '../icons/arrowSelect';

import {
   CustomOutlinedInput,
   CustomMenuItemCheckBox,
   CustomSelect,
   customMenuItemCheckBoxListItemTextStyles,
} from '../styles';
import { Typography } from '@mui/material';

export const SelectCheckbox: FC<SelectCheckboxProps> = ({
   arrValues,
   value,
   setValue,
   placeholder = 'placeholder',
   itemHeight = 48,
   itemPaddingTop = 8,
   styles,
   labelId = 'custom-select-label',
   id = 'custom-select',
   label,
   labelStyles,
   labelVariant = 'body2',
}) => {
   const MenuProps = {
      PaperProps: {
         style: {
            maxHeight: itemHeight * 4.5 + itemPaddingTop,
            width: 250,
         },
      },
   };

   const handleChange = (event: ChangeEvent<{ value: SelectCheckboxOptionType[] }>) => {
      const {
         target: { value },
      } = event;

      console.log(value);

      let duplicateRemoved: SelectCheckboxOptionType[] = [];

      value.forEach((item: SelectCheckboxOptionType) => {
         if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
            duplicateRemoved = duplicateRemoved.filter((x) => x.id !== item.id);
         } else {
            duplicateRemoved.push(item);
         }
      });

      setValue(duplicateRemoved);
   };

   return (
      <FormControl sx={{ ...styles }}>
         <CustomSelect
            labelId={labelId}
            id={id}
            multiple
            displayEmpty
            value={value}
            onChange={handleChange as () => void}
            input={<CustomOutlinedInput />}
            renderValue={(selected: unknown) => {
               const selectedOptions = selected as SelectCheckboxOptionType[]; // Приведение типа
               if (selectedOptions.length === 0) {
                  return (
                     <Typography
                        sx={customMenuItemCheckBoxListItemTextStyles}
                        variant="body2"
                     >
                        {placeholder}
                     </Typography>
                  );
               }
               return selectedOptions.map((x) => x.label).join(', ');
            }}
            MenuProps={MenuProps}
            IconComponent={ArrowSelectIcon}
         >
            {arrValues.map((variant: SelectCheckboxOptionType) => (
               <CustomMenuItemCheckBox key={variant.id} value={variant as any}>
                  <ChexboxDefault
                     checked={value.findIndex((item) => item.id === variant.id) >= 0}
                  />
                  <ListItemText primary={variant.label} />
               </CustomMenuItemCheckBox>
            ))}
         </CustomSelect>
         {label && (
            <Typography variant={labelVariant} style={labelStyles}>
               {label}
            </Typography>
         )}
      </FormControl>
   );
};
