import { useProfileUpdateFIOMutation } from 'app/dashboard/store/api/profileApi';

import type { IUpdateFIORequest } from '@projectTypes/profile';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProfileUpdateFIO = () => {
   const [updateFIO, { isError, error, isLoading, isSuccess }] =
      useProfileUpdateFIOMutation();

   const updateFIOData = async (data: IUpdateFIORequest) => {
      console.log(data);
      try {
         const result = await updateFIO({
            data,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      updateFIOData,
      isError,
      error: error as IErrorResponse,
      isLoading,
      isSuccess,
   };
};
