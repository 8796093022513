import React from 'react';

import { ReactComponent as IconCopy } from '@assets/icons/copy.svg';
import { ReactComponent as IconSave } from '@assets/icons/save.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';
import { Box } from '@mui/material';
import { ButtonAction } from 'shared/buttons';
import { mobileBoxButtonStyles } from './style';

import { colorsUI } from '@constants/colorsUI';
import { buttonSaveStyles } from '../../style';

const { transperent, black500, black600, success, successActive, errorColor } = colorsUI;

export const MobileBoxButton = () => {
   const onClickCopy = () => {
      alert('copy!');
   };
   const onClickSave = () => {
      alert('save');
   };
   const onClickDelete = () => {
      alert('delete!');
   };

   return (
      <Box sx={mobileBoxButtonStyles}>
         <ButtonAction
            color={black500}
            colorHover={black600}
            backgroundColor={transperent}
            backgroundColorHover={transperent}
            onClick={onClickCopy}
            label="копировать"
            Icon={IconCopy}
            iconStyles={{ fill: 'none' }}
            sx={buttonSaveStyles}
         />
         <ButtonAction
            backgroundColor={success}
            backgroundColorHover={successActive}
            onClick={onClickSave}
            iconStyles={{ fill: 'none' }}
            label="сохранить"
            Icon={IconSave}
         />
         <ButtonAction
            backgroundColor={errorColor}
            backgroundColorHover={errorColor}
            onClick={onClickDelete}
            iconStyles={{ fill: 'none' }}
            label="Удалить проект"
            Icon={IconDelete}
         />
      </Box>
   );
};
