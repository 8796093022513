import { Dispatch, FormEvent, SetStateAction } from 'react';
import { ErrorStateSignUp, SignUpData } from '../../../schemas';
import { validateSignUpForm } from './validateSignUpForm';

import { IErrorResponse } from '@projectTypes/apiTypes';

type SignUpSubmitParams = {
   e: FormEvent;
   email: string;
   setErrors: Dispatch<SetStateAction<ErrorStateSignUp | null>>;

   handleSignUp: (email: string) => Promise<{ token: string }>;
};

export const onSubmitForm = async ({
   e,
   email,
   setErrors,

   handleSignUp,
}: SignUpSubmitParams) => {
   e.preventDefault();

   const data: SignUpData = { email };
   const result = validateSignUpForm({ data, setErrors });
   console.log(result);

   if (!result) {
      try {
         await handleSignUp(data.email);
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            console.log(err);
            setErrors({ emailOtherErrorMessage: customError.data.data.message });
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   }
};
