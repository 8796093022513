
export const wrapperStyles = {
    width:"100%",
    maxWidth:"512px",
    display:"flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "space-between",
    margin:"auto",
    gap:"24px",
    padding:"24px",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius:"12px"
}
export const boxButtonStyles = {
    width:"100%",
    display:"flex",
    alignItems: "center",
    justifyContent: "end",
}

export const buttonStyles ={
    '@media (max-width: 360px)': {
        width:"100%"
    },
}
