import React, { FC, FormEvent, useEffect, useRef, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useProfileUpdateFIO } from '@hooks/hooksApi';
import { useToggleModal as useToggleAlert } from '@hooks/hooksUI';

import { AlertSnackbar } from '@shared/alert';
import { FormAccount } from '@components/forms';
import { Box } from '@mui/material';
import { InputDefault } from '@shared/inputs';
import { ButtonDefault } from '@shared/buttons';
import { ErrorMessage } from '@shared/errorMessage';
import { LoaderDefault } from '@shared/loader';

import handleInput from 'utils/handleInput';

import type { IUpdateFIORequest } from '@projectTypes/profile';
import type { FormEditFIOProps } from './type';

import {
   accountPersonalDataFormItemStyles,
   accountPersonalDataFormTextfieldStyles,
} from './style';

import { colorsUI } from '@constants/colorsUI';

const { light } = colorsUI;

export const FormEditFIO: FC<FormEditFIOProps> = ({ firstName, lastName, surname }) => {
   const [firstNameState, setFirstNameState] = useState(firstName ?? '');
   const [lastNameState, setLastNameState] = useState(lastName ?? '');
   const [surnameState, setSurnameState] = useState(surname ?? '');

   const { theme } = useTheme();

   const { updateFIOData, error, isError, isLoading, isSuccess } = useProfileUpdateFIO();

   const { isOpen, setIsOpen } = useToggleAlert();

   useEffect(() => {
      if (isSuccess) {
         setIsOpen(true);
         const timer = setTimeout(() => {
            setIsOpen(false);
         }, 3000);

         return () => clearTimeout(timer);
      }
   }, [isSuccess, setIsOpen]);

   const onSubmit = async (e: FormEvent) => {
      e.preventDefault();

      const data: IUpdateFIORequest = {
         first_name: firstNameState,
         last_name: lastNameState,
         surname: surnameState,
      };

      await updateFIOData(data);
   };

   return (
      <FormAccount onSubmit={onSubmit}>
         <AlertSnackbar setOpen={setIsOpen} open={isOpen} message="fdfs" />
         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Фамилия"
               value={lastNameState}
               onChange={(e) => handleInput(e, setLastNameState)}
            />
         </Box>
         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Имя"
               value={firstNameState}
               onChange={(e) => handleInput(e, setFirstNameState)}
            />
         </Box>
         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               placeholder="Отчество"
               value={surnameState}
               onChange={(e) => handleInput(e, setSurnameState)}
            />
         </Box>
         {isError && <ErrorMessage message={error.data.data.message} />}
         <ButtonDefault type="submit">
            {isLoading ? <LoaderDefault color={light} /> : 'Отправить'}
         </ButtonDefault>
      </FormAccount>
   );
};
