import React, { FC } from 'react';

import { Button, SxProps } from '@mui/material';

import type { ButtonBurgerProps } from '../types';

import { buttonBurgerStyles } from '../styles';

export const ButtonBurger: FC<ButtonBurgerProps> = ({
   isOpen,
   setIsOpen,
   sx = {},
   styles,
}) => {
   return (
      <Button
         sx={(theme) => ({
            ...buttonBurgerStyles({ open: isOpen }, theme),
            ...sx,
         })}
         style={styles}
         onClick={setIsOpen}
      >
         <div />
         <div />
         <div />
      </Button>
   );
};
