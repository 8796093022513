import React, { FC, useState, ChangeEvent } from 'react';
import RadioDefault from 'shared/radio/radioDefault';

import { RadioGroupProps } from './type';
import { Box } from '@mui/material';

import { radioGroupStyles } from './styles';

const RadioGroup: FC<RadioGroupProps> = ({
   options,
   name,
   defaultValue,
   onChange,
   disable = false,
   stylesWrapper,
}) => {
   const [selectedValue, setSelectedValue] = useState<string>(defaultValue || '');

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSelectedValue(value);
      if (onChange) onChange(value);
   };

   return (
      <Box
         role="radiogroup"
         aria-labelledby={name}
         sx={{ ...stylesWrapper, ...radioGroupStyles }}
      >
         {options.map((option) => (
            <RadioDefault
               key={option.value}
               checked={selectedValue === option.value}
               onChange={handleChange}
               value={option.value}
               label={option.label}
               color={option.color}
               sx={option.sx}
               colorChecked={option.colorChecked || 'light500'}
               disable={disable ? disable : option.disable}
            />
         ))}
      </Box>
   );
};

export default RadioGroup;
