import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material';

import { shadowUI } from '@constants/shadowUI';
import { colorsUI } from '@constants/colorsUI';

const { light200, light900, light500, black50, black200, black400, black600 } = colorsUI;
const { shadowSmall } = shadowUI;

export const tabsHeaderSmoothStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   boxShadow: shadowSmall,
   overflow: 'auto',
   '& .MuiTabs-flexContainer': {
      overflowX: 'scroll',
      scrollbarWidth: 'thin',
      scrollbarColor: `${light900} transparent`,

      '&::-webkit-scrollbar': {
         width: '4px',
         height: '4px',
         backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
         backgroundColor: light900,
         borderRadius: '8px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
         backgroundColor: light900,
      },
   },
});

export const tabsHeaderSmoothTabStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   width: '100%',
   color: black200,
   fontWeight: '400',
   textTransform: 'none',
   fontSize: '1.5rem',
   transition: 'all .5s',

   '&.Mui-selected': {
      color: light900,
      fontWeight: '700',
   },
   '&:hover': {
      color: black400,
      backgroundColor: black50,
   },
   [theme.breakpoints.down('md')]: {
      width: '50%',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      padding: '8px 12px',
      borderRadius: '8px',
      '&.Mui-selected': {
         color: black600,
         background: light200,
         border: `1px solid ${light500}`,
         fontWeight: '700',
         padding: '0px 12px',
      },
   },
});
