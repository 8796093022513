import { styled } from '@mui/material/styles';
import {
   Select as MuiSelect,
   MenuItem as MuiMenuItem,
   OutlinedInput,
} from '@mui/material';

import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

interface SelectProps {
   disabled?: boolean;
}

const {
   textFieldBorderColor,
   textFieldBorderColorHover,
   placeholderColor,
   light50,
   light100,
   light500,
   light800,
   black50,
   black100,
   black200,
   black400,
   black500,
   disabledText,
   disabledBackground,
   transperent,
} = colorsUI;

export const CustomSelect = styled(MuiSelect)<SelectProps>(({ disabled }) => ({
   '.MuiInputBase-root-MuiOutlinedInput-root': {
      border: '3px solid red',
   },
   '& .MuiSelect-select': {
      padding: '10px 14px',
      fontSize: '16px',
      fontWeight: 500,
      backgroundColor: disabled ? disabledBackground : transperent,
      color: disabled ? disabledText : placeholderColor,
      cursor: disabled ? 'not-allowed' : 'pointer',
      transition: 'all .5s',
   },
   '&.Mui-focused .MuiSelect-select': {
      color: disabled ? disabledText : light500,
   },
   '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${textFieldBorderColor}`,
   },
   '&:hover .MuiOutlinedInput-notchedOutline': {
      color: disabled ? disabledText : light500,
      border: `1px solid ${textFieldBorderColorHover}`,
   },
   '& .MuiSelect-icon': {
      color: disabled ? disabledText : placeholderColor,
   },

   '&.Mui-focused .MuiSelect-icon': {
      color: light500,
   },
   '&:hover .MuiSelect-icon': {
      color: light500,
   },
   '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
         border: `1px solid ${light500}`,
      },
   },
}));

export const CustomMenuItem = styled(MuiMenuItem)<SelectProps>(({ disabled }) => ({
   fontSize: '1rem',
   padding: '10px 16px',
   backgroundColor: disabled ? disabledBackground : transperent,
   color: disabled ? disabledText : black400,
   borderBottom: `1px solid ${black50}`,

   '&:hover': {
      backgroundColor: disabled ? disabledBackground : light50,
   },
   '&.Mui-selected': {
      backgroundColor: light100,
      '&:hover': {
         backgroundColor: ` ${light50} !important`,
      },
   },
   '&.Mui-selected:hover': {
      backgroundColor: light50,
   },
}));

export const CustomOutlinedInput = styled(OutlinedInput)<SelectProps>(({ disabled }) => ({
   '& .MuiOutlinedInput-notchedOutline': {
      borderColor: disabled ? disabledText : textFieldBorderColor,
      transition: 'all .5s',
   },
   '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: disabled ? disabledText : black500,
   },
   backgroundColor: disabled ? disabledBackground : transperent,
   cursor: disabled ? 'not-allowed' : 'pointer',
   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${light500}`,
   },
   '& .MuiInputBase-input::placeholder': {
      color: light500,
   },
}));

export const CustomSelectPlaceholderStyles: CSSProperties = {
   color: black200,
};
