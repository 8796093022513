import { SxProps, Theme } from '@mui/material';
import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { light50, black50, black500 } = colorsUI;

export const tableStyles: CSSProperties = {
   boxShadow: 'none',
   userSelect: 'text',
};

export const tableHeaderStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   background: light50,

   [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
   },
});

export const tableHeaderCellStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   fontFamily: 'Exo2-Bold',
   fontWeight: 700,
   color: black500,

   [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
   },
});

export const tableRowCellStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   fontFamily: 'Exo2-Regular',
   color: black500,

   fontWeight: 400,
   [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
   },
});

export const tableCellStyles: CSSProperties = {
   padding: '24px',
   border: `1px solid ${black50}`,
};

export const tableCellActionStylesMobile: CSSProperties = {
   width: '100%',
};

export const tableCellStylesMobile: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
};
