import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material/styles';

const { danger, dangerActive, accentColor, success, successActive, transperent } =
   colorsUI;

export const visualScriptBoxActionButtonSuccsessStyles = (): SxProps<Theme> => ({
   background: success,
   '&:hover': {
      background: successActive,
   },
});
export const visualScriptBoxActionButtonNegativeStyles = (): SxProps<Theme> => ({
   background: danger,
   '&:hover': {
      background: dangerActive,
   },
});

export const visualScriptBoxActionButtonObjectionStyles = (): SxProps<Theme> => ({
   background: accentColor,
   border: `1px solid ${transperent}`,
   '&:hover': {
      background: transperent,
      border: `1px solid ${accentColor}`,
      color: accentColor,
   },
});
