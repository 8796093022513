import {
   visualScriptBoxActionButtonNegativeStyles,
   visualScriptBoxActionButtonSuccsessStyles,
   visualScriptBoxActionButtonObjectionStyles,
} from '../../styles';

import type { getStylesButtonParams, getStylesButtonReturnParms } from './type';

export const getStylesButton = ({
   reaction,
}: getStylesButtonParams): getStylesButtonReturnParms => {
   switch (reaction) {
      case 'objection':
         return visualScriptBoxActionButtonObjectionStyles();
      case 'negative':
         return visualScriptBoxActionButtonNegativeStyles();
      default:
         return visualScriptBoxActionButtonSuccsessStyles();
   }
};
