export const businessAreaLabels: Record<string, string> = {
   advertising: 'Реклама',
};

export const scenarioTypeLabels: Record<string, string> = {
   distance_selling: 'Дистанционные продажи',
   invite_to_selling_spot: 'Приглашение в точку продаж',
   consulting: 'Информирование и консультирование',
   hiring: 'Предложение вакансий и трудоустройства',
   technical_support: 'Тех. обслуживание',
   clients_support: 'Работа с негативом и сопровождение клиентов',
   social_poll: 'Социальные опросы',
   kyc: 'Верификация и валидация контактов',
};

export const contactBaseTypeLabels: Record<string, string> = {
   cold_base: 'Холодные новые контакты',
   hot_base: 'Теплые новые контакты',
   active_base: 'Действующая база контактов',
   active_clients: 'Действующие клиенты (уже купили)',
   old_base: 'Старая база контактов и отказники',
   random_base: 'Вперемешку (не имеет значения)',
};
