import { SxProps, Theme } from "@mui/material";

export const formAuthStyles=(gap:string): SxProps<Theme>  =>( {
    padding: '24px', // Внутренние отступы
    borderRadius: '12px', // Радиус скругления углов
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Тень
    backgroundColor: '#ffffff', // Фоновый цвет
    gap: gap?gap :"24px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"space-between",
    alignItems:"center",
    width: '100%', // Ширина формы
    margin: '0 auto', // Центрирование формы по горизонтали
  });