import { BlockSelect } from '../interfaces/blockSelect';

export const blockTypes: Array<BlockSelect> = [
   { text: 'Текст', type: 'node' },
   { text: 'Подсказка', type: 'node' },
   { text: 'Отработка возражения', type: 'node' },
   { text: 'Стадия', type: 'node' },
   { text: 'Положительный ответ', type: 'reaction' },
   { text: 'Отрицательный ответ', type: 'reaction' },
   { text: 'Возражение', type: 'reaction' },
   { text: 'Триггер', type: 'reaction' },
   { text: 'Исход', type: 'reaction' },
];
