import type { CSSProperties } from 'react';

import { ReactionEnum } from '@enums/runtime';

import {
   visualScriptQuizNegativeStyles,
   visualScriptQuizSuccsessStyles,
} from '../../styles';

export const getStatusBackgroundColor = (status: ReactionEnum): CSSProperties =>
   status === 'positive'
      ? visualScriptQuizSuccsessStyles
      : visualScriptQuizNegativeStyles;
