import { z } from 'zod';

import { errorsResetPassword } from '../errors';

const {invalidEmai} = errorsResetPassword

export const resetPasswordSchema = z.object({
    email: z.string().email({message:invalidEmai})
}).strip();


export type ResetPasswordData = z.infer<typeof resetPasswordSchema>;
export type ErrorStateResetPassword = Partial<ResetPasswordData> & { unknownError?: string }; 