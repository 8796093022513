import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black100, black200, black300, light400, light500, light600 } = colorsUI;

export const datePickerDefaultCalendarStyles = (open: boolean): SxProps<Theme> => ({
   transition: 'all .5s',
   maxHeight: open ? '485px' : '0px',
   opacity: open ? 1 : 0,
   overflow: 'hidden',
});

export const datePickerDefaultTextFilendStyles = (): SxProps<Theme> => ({
   color: light500,
   borderRadius: '4px',
   transition: 'all .5s',

   '& .MuiInputBase-input': {
      padding: '10px 16px',
      color: black200,
      lineHeight: '1.25rem',
      fontSize: '1rem',
   },

   '& .MuiOutlinedInput-root': {
      '& fieldset': {
         borderColor: black100,
      },
      '&:hover fieldset': {
         borderColor: light600,
      },
      '&.Mui-focused fieldset': {
         borderColor: light400,
      },
   },

   '.MuiInputAdornment-root': {
      color: black300,
      '&:hover': {
         color: light500,
      },
   },

   '&:hover': {
      transition: 'all .5s',

      '& .MuiInputAdornment-root svg > path': {
         transition: 'all .5s',

         stroke: light500,
      },
   },

   '&:focus-within': {
      transition: 'all .5s',

      color: light400,
      '& .MuiInputBase-input': {
         color: light400,
      },
      '& .MuiInputAdornment-root svg > path': {
         stroke: light400,
      },
   },
});
