import { CSSProperties } from 'react';

export const formCreateStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
};

export const formCreateContentStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
};
