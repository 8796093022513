import { useProfileConfirmCodeMutation } from 'app/dashboard/store/api/profileApi';

import type { IConfirmCodeRequest } from '@projectTypes/profile';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProfileConfirmCode = () => {
   const [confirmCode, { isError, error }] = useProfileConfirmCodeMutation();

   const confirmCodeData = async (data: IConfirmCodeRequest) => {
      console.log(data);
      try {
         const result = await confirmCode({
            data,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return { confirmCodeData, isError, error: error as IErrorResponse };
};
