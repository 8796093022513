import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const managersDataStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '24px',
};

export const managersDataFilterStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   justifyContent: 'start',
};

export const managersDataFilterTextFieldStyles = (theme: Theme): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});
