import React, { useCallback, useState } from 'react';

import { useTheme } from '@hooks/useTheme';

import FormCreateManagerItem from '../formCreateManagerItem';
import { Box } from '@mui/material';
import { ButtonAction } from '@shared/buttons';
import { ColumnWrapper } from '@shared/column';

import { v4 as uuidv4 } from 'uuid';

import type { ManagersFormItemComponent } from './types';

import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';
import { ReactComponent as IconSave } from '@assets/icons/save.svg';

import {
   buttonBoxFooterStyles,
   buttonLabelStyles,
   buttonsaveBoxStyles,
   buttonsaveStyles,
} from './style';

import { colorsUI } from '@constants/colorsUI';

const {
   light,
   light500,
   light600,
   danger,
   dangerActive,
   transperent,
   success,
   successActive,
} = colorsUI;

export const FormCreateManagerContent = () => {
   const [managers, setManagers] = useState<ManagersFormItemComponent[]>([
      {
         idComponent: '1',
         data: {
            email: '',
            fio: '',
            projectsManager: '',
         },
      },
   ]);

   const [activeManager, setActiveManager] = useState(managers[0].idComponent);

   const onClickActiveManager = (id: string) => {
      setActiveManager(id);
   };

   const addNewManagerForm = () => {
      setManagers((newManagers) => [
         ...newManagers,
         {
            idComponent: uuidv4(),

            data: {
               email: '',
               fio: '',
               projectsManager: '',
            },
         },
      ]);
   };

   const onClickDeleteManagers = (id: string) => {
      setManagers((managers) => managers.filter(({ idComponent }) => idComponent !== id));
   };

   const saveManagers = () => {
      console.log(managers);
   };

   const { theme } = useTheme();

   const updateManagerData = useCallback((id: string, updatedData: any) => {
      setManagers((prevManagers) =>
         prevManagers.map((manager) =>
            manager.idComponent === id
               ? { ...manager, data: { ...manager.data, ...updatedData } }
               : manager,
         ),
      );
   }, []);

   return (
      <ColumnWrapper maxWidth="750px" gap={'16px'} gapMiddle={'16px'}>
         {managers.map(({ data, idComponent }) => (
            <FormCreateManagerItem
               key={idComponent}
               id={idComponent}
               email={data.email}
               fio={data.fio}
               projectsManager={data.projectsManager}
               onClickActive={onClickActiveManager}
               activeIdComponet={activeManager}
               onUpdateManagerData={updateManagerData}
            />
         ))}
         <Box sx={buttonBoxFooterStyles(theme)}>
            <ButtonAction
               backgroundColor={transperent}
               backgroundColorHover={transperent}
               color={light500}
               colorHover={light600}
               onClick={addNewManagerForm}
               label="Добавить еще"
               Icon={IconPlus}
               sx={buttonLabelStyles}
            />
            <ButtonAction
               backgroundColor={transperent}
               backgroundColorHover={transperent}
               color={danger}
               colorHover={dangerActive}
               onClick={() => onClickDeleteManagers(activeManager)}
               label="Удалить выбранное"
               Icon={IconDelete}
               sx={buttonLabelStyles}
            />
         </Box>
         <Box sx={buttonsaveBoxStyles}>
            <ButtonAction
               backgroundColor={success}
               backgroundColorHover={successActive}
               color={light}
               colorHover={light}
               label="Cохранить"
               Icon={IconSave}
               sx={buttonsaveStyles(theme) as object}
            />
         </Box>
      </ColumnWrapper>
   );
};
