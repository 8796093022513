import React from 'react';

import { CenteredContainer } from '@shared/containers';
import { LoaderDefault } from '@shared/loader';

export const LayoutLoader = () => {
   return (
      <CenteredContainer>
         <LoaderDefault />
      </CenteredContainer>
   );
};
