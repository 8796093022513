import React, { ChangeEvent, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { InputDefault } from '@shared/inputs';
import { SelectDefault } from '@shared/selects';
import { ButtonDefault } from '@shared/buttons';
import {
   labelStyles,
   formCreateProjectsStyles,
   inputStyles,
   formBoxButtonStyles,
} from './style';
import { colorsUI } from 'constants/colorsUI';
import { useAddProjectMutation } from 'app/dashboard/store/api/projectApi';
import { IProjectCreate } from '@projectTypes/projects/projectTypes';
import { useProjectData } from '@hooks/hooksApi/useProjectData';
import {
   businessAreaLabels,
   scenarioTypeLabels,
   contactBaseTypeLabels,
} from '../options';

const { light, light900, errorColor } = colorsUI;

const businessAreaOptions = Object.entries(businessAreaLabels).map(([key, label]) => ({
   value: key,
   label,
}));

const scenarioTypeOptions = Object.entries(scenarioTypeLabels).map(([key, label]) => ({
   value: key,
   label,
}));

const contactBaseTypeOptions = Object.entries(contactBaseTypeLabels).map(
   ([key, label]) => ({
      value: key,
      label,
   }),
);

export const FormCreateProjects = () => {
   const [addProject, { isLoading, isSuccess, isError }] = useAddProjectMutation();
   const { refetch } = useProjectData();
   const [formData, setFormData] = useState<IProjectCreate>({
      name: '',
      business_area: '',
      scenario_type: '',
      contact_base_type: '',
      is_draft: true,
   });

   const [errors, setErrors] = useState({
      name: false,
      business_area: false,
      scenario_type: false,
      contact_base_type: false,
   });

   const [formError, setFormError] = useState(false);

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();

      const hasErrors =
         Object.keys(formData).some(
            (key) => key !== 'is_draft' && !formData[key as keyof IProjectCreate],
         ) || formData.name.length < 10;

      if (hasErrors) {
         setErrors({
            name: formData.name.length < 10,
            business_area: !formData.business_area,
            scenario_type: !formData.scenario_type,
            contact_base_type: !formData.contact_base_type,
         });
         setFormError(true);
         return;
      }

      try {
         await addProject(formData).unwrap();
         refetch();
         alert('Проект успешно добавлен');
         setFormData({
            name: '',
            business_area: '',
            scenario_type: '',
            contact_base_type: '',
            is_draft: true,
         });
         setFormError(false);
      } catch (error) {
         console.error('Failed to add project:', error);
      }
   };

   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;

      if (name === 'name') {
         setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.length < 10,
         }));
      }

      setFormData((prevData) => ({ ...prevData, [name]: value }));
   };

   const handleSelectChange =
      (name: keyof typeof formData) => (event: SelectChangeEvent<string>) => {
         setFormData((prevData) => ({ ...prevData, [name]: event.target.value }));
         setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      };

   return (
      <form style={formCreateProjectsStyles} onSubmit={handleSubmit}>
         <InputDefault
            stylesWrapper={inputStyles}
            onChange={handleChange}
            value={formData.name}
            placeholder="Название проекта"
            labelStyles={labelStyles}
            labelVariant="overline"
            label="Необходимо для внутреннего использования"
            name="name"
            error={
               errors.name ? (
                  <span
                     style={{
                        color: `${errorColor}`,
                        fontSize: '12px',
                        paddingLeft: '8px',
                     }}
                  >
                     Минимальная длина 10 символов
                  </span>
               ) : undefined
            }
            sx={{
               borderColor: errors.name ? errorColor : undefined,
            }}
         />

         <SelectDefault
            placeholder="Сфера проекта"
            onChange={handleSelectChange('business_area')}
            arrValues={businessAreaOptions}
            value={formData.business_area}
            label="Укажите вашу сферу"
            labelStyles={labelStyles}
            labelVariant="overline"
            sx={{ borderColor: errors.business_area ? errorColor : undefined }}
            error={Boolean(errors.business_area)}
         />
         <SelectDefault
            placeholder="Направление сценария"
            onChange={handleSelectChange('scenario_type')}
            arrValues={scenarioTypeOptions}
            value={formData.scenario_type}
            label="Укажите ваше направление"
            labelStyles={labelStyles}
            labelVariant="overline"
            sx={{ borderColor: errors.scenario_type ? errorColor : undefined }}
            error={Boolean(errors.scenario_type)}
         />
         <SelectDefault
            placeholder="Вовлеченность контактов"
            onChange={handleSelectChange('contact_base_type')}
            arrValues={contactBaseTypeOptions}
            value={formData.contact_base_type}
            label="Советуем разделять проекты по типу вовлеченности контактов"
            labelStyles={labelStyles}
            labelVariant="overline"
            sx={{ borderColor: errors.contact_base_type ? errorColor : undefined }}
            error={Boolean(errors.contact_base_type)}
         />
         {formError && <p style={{ color: errorColor }}>Заполните все поля</p>}
         <Box sx={formBoxButtonStyles}>
            <ButtonDefault
               label="Добавить черновик"
               onClick={() =>
                  setFormData((prevData) => ({
                     ...prevData,
                     is_draft: true,
                  }))
               }
               disabled={isLoading}
            />
            <ButtonDefault
               backgroundColor={light}
               backgroundColorHover={light}
               color={light900}
               colorHover={light900}
               label="Перейти в редактор"
               onClick={handleSubmit}
               disabled={isLoading}
            />
         </Box>
         {isSuccess && <p>Проект успешно добавлен!</p>}
         {isError && <p>Ошибка при добавлении проекта</p>}
      </form>
   );
};
