import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { black50 } = colorsUI;

export const visualScriptModalInnerStylesStyles: CSSProperties = {
   height: '100%',
   top: '80%',
   width: '100%',
   maxWidth: 'none',
   background: black50,
   border: 'none',
};

export const visualScriptButtonModalList: CSSProperties = {
   position: 'absolute',
   right: '6px',
   top: '6px',
   background: black50,
   padding: '8px',
   borderRadius: '4px',
   lineHeight: '1',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
};
export const visualScriptButtonModalForm: CSSProperties = {
   position: 'absolute',
   left: '6px',
   top: '6px',
   background: black50,
   padding: '8px',
   borderRadius: '4px',
   lineHeight: '1',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
};
