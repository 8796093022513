import zIndex from '@mui/material/styles/zIndex';
import { colorsUI } from 'constants/colorsUI';
import { Theme } from '@mui/material';

const { light900 } = colorsUI;

export const buttonBurgerStyles = ({ open }: { open: boolean }, theme: Theme) => ({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'center',
   maxWidth: '40px',
   zIndex: '10000',
   height: '32px',
   cursor: 'pointer',
   paddingRight: '14px',
   paddingLeft: '14px',
   transition: ' all .5s',

   '& div': {
      backgroundColor: light900,
      height: '2px',
      width: '18px',
      transition: 'transform 0.3s ease, opacity 0.3s ease',
   },

   '& div:nth-of-type(1)': {
      transform: open ? 'rotate(45deg) translate(5px, 2px)' : 'none',
   },
   '& div:nth-of-type(2)': {
      opacity: open ? 0 : 1,
   },
   '& div:nth-of-type(3)': {
      transform: open ? 'rotate(-45deg) translate(5px, -3px)' : 'none',
   },

   // Добавляем медиазапрос для скрытия на экранах больше md (960px)
   [theme.breakpoints.up('lg')]: {
      display: 'none',
   },
});
