import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box } from '@mui/material';

import type { ColumnProps } from '../types';

import { columnCenteredStyles } from '../styles';

export const ColumnCentered: FC<ColumnProps> = ({
   gap = '24px',
   gapMiddle = '22px',
   gapMobile = '19px',
   children,
   styles,
}) => {
   const { theme } = useTheme();

   const innerStyles = columnCenteredStyles({
      theme,
      gap,
      gapMiddle,
      gapMobile,
      customStyles: styles,
   });

   return <Box sx={innerStyles}>{children}</Box>;
};
