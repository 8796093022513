import React from 'react';
import { ResetPassword } from '../../../modules/auth';

import { CenteredContainer } from 'shared/containers';

const AuthResetPasswordPage = () => {
   return (
      <CenteredContainer>
         <ResetPassword />
      </CenteredContainer>
   );
};

export default AuthResetPasswordPage;
