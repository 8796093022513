import { SxProps, Theme } from '@mui/material/styles';
import { colorsUI } from 'constants/colorsUI';
import { shadowUI } from 'constants/shadowUI';
import { CSSProperties } from 'react';

const { light, black400, black500 } = colorsUI;
const { shadowModal } = shadowUI;

export const modalDefaultStyles: CSSProperties = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: '100%',
   maxWidth: 'fit-content',
   display: 'flex',

   justifyContent: 'center',
   background: light,
   borderRadius: '12px',
   padding: '24px 24px 32px 24px',
   boxShadow: shadowModal,
   margin: 0,
};

export const modalDefaultButtonCloseStyles = (theme: Theme): SxProps<Theme> => ({
   position: 'absolute' as 'absolute',
   top: '20px',
   right: '10px',
   background: black400,
   color: light,
   padding: '8px',
   lineHeight: '1',
   '&:hover': {
      background: black500,
   },
});
