import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { black50 } = colorsUI;

export const visualScriptStructureStyles: CSSProperties = {
   display: 'flex',
   flexDirection: 'column',
   background: black50,
   padding: '32px 16px',
   gap: '32px',
};
