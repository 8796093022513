import { CSSProperties } from 'react';

export const visualScriptContainerStyles: CSSProperties = {
   width: '100%',
   height: 'auto',
};

export const visualScriptContainerInnerStyles: CSSProperties = {
   width: '100%',
   height: '100%',
   maxWidth: '1240px',
   display: 'flex',
   justifyContent: 'center',
   position: 'relative',
};
