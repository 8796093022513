import React, { FC } from 'react';

import { List } from '@mui/material';
import { ListItemLink } from 'shared/listItems';

import type { ListLinkProps } from '../types';

import { listDefaultStyles } from '../styles';

export const ListLink: FC<ListLinkProps> = ({ items, styles, onClick }) => {
   return (
      <List sx={{ ...listDefaultStyles, ...styles }}>
         {items.map(({ message, path }, i) => (
            <ListItemLink onClick={onClick} key={i} href={path} label={message} />
         ))}
      </List>
   );
};
