import { CSSProperties } from 'react';

export const buttonMoreStyles: CSSProperties = {
   display: 'flex',
   justifyContent: 'flex-end',
   alignItems: 'center',
   padding: '4px',
   transition: ' all .5s',
   minWidth: '10px',
};
