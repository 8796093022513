import React, { FC } from 'react';

import { Box } from '@mui/material';

import type { TabsBodyProps } from '../types';

export const TabsBody: FC<TabsBodyProps> = ({ children, index, value, ...other }) => {
   return (
      <Box
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box>{children}</Box>}
      </Box>
   );
};
