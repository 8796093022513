import { navigationRoutes } from '../../app/dashboard/routes/navigationRoutes';

export const getBreadcrumbs = (path: string) => {
   const pathSegments = path.split('/').filter(Boolean);

   return navigationRoutes
      .filter(({ path: route }) => {
         const routeSegments = route.split('/').filter(Boolean);
         return (
            pathSegments.slice(0, routeSegments.length).join('/') ===
            routeSegments.join('/')
         );
      })
      .map(({ path, message }) => ({
         message,
         path,
      }));
};
