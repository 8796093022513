import { CSSProperties } from 'react';
import { ButtonActionStylesType } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { disabledBackground, disabledText } = colorsUI;

export const buttonActionStyles: ButtonActionStylesType = ({
   color,
   colorHover,
   backgroundColor,
   backgroundColorHover,
   disabled,
}) => ({
   backgroundColor: disabled ? disabledBackground : backgroundColor,
   color: disabled ? disabledText : color,
   transition: ' all .5s',
   ...(!disabled && {
      '&:hover': {
         backgroundColor: backgroundColorHover,
         color: colorHover,
         svg: {
            fill: colorHover,
         },
      },
   }),

   pointerEvents: disabled ? 'none' : 'auto',
});

export const buttonActionIconStyles: CSSProperties = {
   width: '20px',
   height: '20px',
   objectFit: 'cover',
   marginRight: '8px',
};
