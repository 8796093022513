import { useProfileChangePasswordMutation } from 'app/dashboard/store/api/profileApi';

import { IChangePasswordsRequest } from '@projectTypes/profile';

export const useProfileChangePassword = () => {
   const [changePassword, { data, isError, isSuccess, isLoading }] =
      useProfileChangePasswordMutation();

   const changePasswordData = async (data: IChangePasswordsRequest) => {
      console.log(data);
      try {
         const result = await changePassword({
            data,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (error) {
         console.error('Ошибка:', error);
         throw new Error(error as string);
      }
   };

   return { changePasswordData, isError, isSuccess, isLoading };
};
