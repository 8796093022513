import styled from 'styled-components';

interface IOpen {
   $active: boolean;
}

interface IButton {
   $variant: 'save' | 'cancel' | 'delete';
}

export const ModalBackdrop = styled.div<IOpen>`
   height: 100%;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.496);
   position: fixed;
   z-index: 120;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: 0.2s;
   opacity: ${(props) => (props.$active ? '1' : '0')};
   pointer-events: ${(props) => (props.$active ? 'all' : 'none')};
`;

export const ModalContent = styled.div`
   padding: 45px;
   background-color: white;
   display: flex;
   flex-direction: column;
   gap: 20px;
   border-radius: 8px;
`;

export const ButtonContainer = styled.div`
   display: flex;
   gap: 15px;
   margin-top: 35px;
`;

export const InputField = styled.input`
   height: 30px;
   outline: none;
   border: none;
   border-bottom: 2px solid #ccc;
   padding: 0 10px;
`;

export const Button = styled.button<IButton>`
   padding: 10px;
   font-size: 14px;
   border-radius: 5px;
   transition: 0.4s;
   border: none;
   cursor: pointer;
   color: white;
   background-color: ${(props) => (props.$variant === 'save' ? '#299f29' : '#b32c2c')};

   &:hover {
      opacity: 0.8;
      transition: 0.4s;
   }
`;
