import React, { FC, useState } from 'react';

import { useTheme } from '@hooks/useTheme';

import { FormDefault } from '@components/forms';
import { InputPassword } from '@shared/inputs';

import handleInput from '@utils/handleInput';

import { FormChangepasswordProps } from './type';

import {
   formChangepasswordStyles,
   formChangepasswordInputStyles,
   formChangepasswordInputDescStyles,
} from './style';

import { PASSWORD_MIN_LENGTH } from '@constants/validatePassword';

export const FormChangepassword: FC<FormChangepasswordProps> = ({
   currentPassword,
   newPassword,
   confirmNewPassword,
   setConfirmNewPassword,
   setCurrentPassword,
   setNewPassword,
   errors,
}) => {
   const { theme } = useTheme();

   return (
      <FormDefault sx={{ ...formChangepasswordStyles({ theme }) }}>
         <InputPassword
            value={currentPassword}
            onChange={(e) => handleInput(e, setCurrentPassword)}
            stylesForm={formChangepasswordInputStyles}
            placeholder="Введите текущий пароль"
            error={errors?.incorrectPassword}
         />
         <InputPassword
            value={newPassword}
            onChange={(e) => handleInput(e, setNewPassword)}
            stylesForm={formChangepasswordInputStyles}
            placeholder="Введите новый пароль"
            label={`Пароль должен иметь длинну минимум ${PASSWORD_MIN_LENGTH} символов `}
            labelVariant="caption"
            labelStyles={formChangepasswordInputDescStyles}
            error={errors?.newPasswordSameAsOld || errors?.minLengthPasswordError}
         />
         <InputPassword
            value={confirmNewPassword}
            onChange={(e) => handleInput(e, setConfirmNewPassword)}
            stylesForm={formChangepasswordInputStyles}
            placeholder="Повторите новый пароль"
            error={errors?.passwordsDoNotMatch}
         />
      </FormDefault>
   );
};
