import React, { FC } from 'react';

import { notDataStyles } from './style';
import { Box } from '@mui/material';

import type { NotDataProps } from './type';

export const NotData: FC<NotDataProps> = ({ gap = '24px', sx, children }) => {
   return <Box sx={{ ...notDataStyles({ gap }), ...(sx as object) }}>{children}</Box>;
};
