import React, { FC, ChangeEventHandler, useState, ChangeEvent, useEffect } from 'react';

import { InputDefault } from '@shared/inputs';
import { ButtonIcon } from '@shared/buttons';
import { Box } from '@mui/material';
import { TooltipArrow } from '@shared/tooltips';

import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';

import clearState from '@utils/clearState';
import { onHandleIsActive, handleFieldChange, onHandleBlurField } from '../../utils';

import type { VisualScriptFormTextFieldProps } from './type';

import {
   visualScriptFormTextFieldBoxButtonStyles,
   visualScriptFormTextFieldStyles,
   visualScriptFormTextFielInputStyles,
} from '../../styles';

import { colorsUI } from '@constants/colorsUI';

const { danger, dangerActive } = colorsUI;

export const VisualScriptFormTextField: FC<VisualScriptFormTextFieldProps> = ({
   id,
   placeholder,
   onBlur,
   slug,
   type,
}) => {
   const [value, setValue] = useState('');
   const [isActive, setIsActive] = useState(false);
   const [isOpenTooltip, setIsOpenTooltip] = useState(false);

   const onChange: ChangeEventHandler<HTMLInputElement> = (
      e: ChangeEvent<HTMLInputElement>,
   ) => {
      handleFieldChange({ e, isActive, setValue, setIsOpenTooltip });
   };

   const onHandleBlur = () => {
      const data = { id, name: slug, value };
      onHandleBlurField({ data, isActive, onBlur, setIsActive, setIsOpenTooltip });
   };

   return (
      <Box sx={visualScriptFormTextFieldStyles}>
         <TooltipArrow
            isOpen={!isActive && isOpenTooltip}
            title={
               <>
                  Нажмите на карандаш <br />
                  чтобы вводить текст
               </>
            }
            placement="top"
         >
            <Box>
               <InputDefault
                  stylesForm={visualScriptFormTextFielInputStyles}
                  type={type === 'int' ? 'number' : 'text'}
                  placeholder={placeholder}
                  value={value}
                  onBlur={onHandleBlur}
                  onChange={onChange}
               />
            </Box>
         </TooltipArrow>

         <Box sx={visualScriptFormTextFieldBoxButtonStyles}>
            <ButtonIcon
               Icon={IconEdit}
               onClick={() =>
                  onHandleIsActive({ setIsActive, isOpenTooltip, setIsOpenTooltip })
               }
            />
            <ButtonIcon
               colorIcon={danger}
               colorHoverIcon={dangerActive}
               Icon={IconDelete}
               onClick={() => clearState<string>({ setValue, clearValue: '' })}
            />
         </Box>
      </Box>
   );
};
