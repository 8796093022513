import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light900 } = colorsUI;

export const acountReplenishmentBalanceStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
   [theme.breakpoints.down('md')]: {
      gap: '16px',
   },
});

export const acountReplenishmentBalanceTitleStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   fontFamily: 'Exo2-Bold',
   color: light900,
   fontWeight: 700,
   [theme.breakpoints.down('md')]: {
      lineHeight: '1.5rem',
      fontSize: '1,125rem',
   },
});
