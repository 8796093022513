import React, { useState } from 'react';

import { MenegersNone } from '../managersNone';
import { ManagersData } from '../managersData';
import { Box } from '@mui/material';
import { ColumnDashboard } from '@shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';

import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import { managersContentStyles, managersNoneButtonStyles } from './styles';

import styles from './styles/styles.module.css';
import { LinkDefault } from '@shared/links';

const { menegersInner } = styles;

export const Menegers = () => {
   const [isNoneMenegers, setIsNoneMenegers] = useState(true);

   return (
      <ColumnDashboard styles={menegersInner}>
         <ProfileHeader title="Мои менеджеры">
            <LinkDefault
               href="/managers/create-managers"
               sx={managersNoneButtonStyles as object}
               Icon={PlusIcon}
               label="Создать проект"
            />
         </ProfileHeader>
         <Box sx={managersContentStyles({ isNoneMenegers })}>
            {isNoneMenegers ? <ManagersData /> : <MenegersNone />}
         </Box>
      </ColumnDashboard>
   );
};
