import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black700 } = colorsUI;

export const actionProjectItemStyles: CSSProperties = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '40px',
};
export const actionProjectItemTypographyStyles: CSSProperties = {
   fontSize: '1.875rem',
   lineHeight: '3rem',
   fontWeight: 700,
   color: black700,
};
