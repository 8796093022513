import { CSSProperties } from 'react';

import { shadowUI } from 'constants/shadowUI';

const { shadowBase } = shadowUI;

export const popupContentStyles: CSSProperties = {
   padding: '14px 12px',
   display: 'flex',
   flexDirection: 'column',
   gap: '8px',
   borderRadius: '6px',
   boxShadow: shadowBase,
};
