import { CSSProperties } from 'react';
import type { LinkArrowStylesParams } from '../types';
import { colorsUI } from 'constants/colorsUI';

const { disabledBackground, disabledText } = colorsUI;

export const linkArrowStyles = ({
   color,
   colorHover,
   backgroundColor,
   backgroundColorHover,
   disabled,
}: LinkArrowStylesParams): CSSProperties => ({
   textDecoration: 'none',
   display: 'inline-flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '8px',
   padding: '10px 8px',
   borderRadius: '6px',
   backgroundColor: disabled ? disabledBackground : backgroundColor,
   color: disabled ? disabledText : color,
   textAlign: 'center',
   cursor: disabled ? 'not-allowed' : 'pointer',
   transition: 'all 0.5s',
   fontSize: '1rem',
   fontWeight: 700,
   lineHeight: '1.25rem',
   textTransform: 'none',
   pointerEvents: disabled ? 'none' : 'auto',
   ...(!disabled && {
      ':hover': {
         backgroundColor: backgroundColorHover,
         color: colorHover,
      },
   }),
});
