import { SxProps, Theme } from '@mui/material';

export const formAccountStyles = ({
   theme,
   gap,
}: {
   theme: Theme;
   gap: string;
}): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   gap,
});
