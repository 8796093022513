// tree.css
import { BlockType } from 'interfaces/constructor/block';
import styled from 'styled-components';

interface BlockDragProps {
   $colorIndex: number;
   $blockType: BlockType;
}

export const colors = [
   '#cde8ff',
   '#ffebcc',
   '#ffcccc',
   '#e6ffcc',
   '#ccffeb',
   '#ffcce6',
   '#e6ccff',
   '#ccffe6',
   '#ffecd9',
];

export const BlockDrag = styled.div<BlockDragProps>`
   width: 150px;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   text-align: center;
   border: 1px solid #ccc;
   border-radius: 8px;
   cursor: pointer;
   background-color: ${({ $colorIndex }) => colors[$colorIndex % colors.length]};
   margin: 5px;
   transition: box-shadow 0.3s;
   &:hover {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
   }
`;

export const Wrapper = styled.div`
   display: flex;
   width: 100%;
`;

export const Blocks = styled.div`
   display: flex;
   flex-direction: column;
   padding: 10px;
   border-right: 1px solid #ddd;
   width: 200px;
`;

export const Field = styled.div`
   flex: 1;
   border: 1px solid #ddd;
   background-color: #f7f7f7;
   position: relative;
`;
