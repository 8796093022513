import DashBoardMainPage from '../pages/dashboard/DashBoardMainPage';
import DashBoardProjectsPage from '../pages/dashboard/projectsPage/DashBoardProjectsPage';
import DashBoardProjectsCreatePage from '../pages/dashboard/projectsPage/DashBoardProjectsCreatePage';
import DashBoardManagersPage from '../pages/dashboard/managersPage/DashBoardManagersPage';
import DashBoardManegarsPage from '../pages/dashboard/managersPage/DashBoardManagesCreatePage';
import DashBoardClientsPage from '../pages/dashboard/clients/DashBoardClientsPage';
import DashboardVisualScripPage from '../pages/dashboard/visualScript/DashboardVisualScripPage';
import DashBoardLogsPage from '../pages/dashboard/logs/DashBoardLogsPage';
import DashBoardAccountPage from '../pages/dashboard/account/DashBoardAccountPage';
import DashBoardConstructorPage from '../pages/dashboard/constructor/DashBoardConstructorPage';
import AuthResetPasswordPage from '../pages/auth/resetPassword/AuthResetPasswordPage';
import AuthSignInPage from '../pages/auth/signIn/AuthSignInPage';
import AuthSignUpPage from '../pages/auth/signUp/AuthSignUpPage';
import NotFound from '../pages/NotFound';

import type { IRoute } from '../types';

export const privateRoutes: IRoute[] = [
   {
      path: '/',
      element: DashBoardMainPage,
   },
   {
      path: '/projects',
      element: DashBoardProjectsPage,
   },
   {
      path: '/projects/create-projects',
      element: DashBoardProjectsCreatePage,
   },
   {
      path: '/managers',
      element: DashBoardManagersPage,
   },
   {
      path: '/managers/create-managers',
      element: DashBoardManegarsPage,
   },
   {
      path: '/clients',
      element: DashBoardClientsPage,
   },
   {
      path: '/logs',
      element: DashBoardLogsPage,
   },
   {
      path: '/visual-script/:slug',
      element: DashboardVisualScripPage,
   },
   {
      path: '/projects/constructor/:slug',
      element: DashBoardConstructorPage,
   },
   {
      path: '/account',
      element: DashBoardAccountPage,
   },
];

export const publickRoutes: IRoute[] = [
   {
      path: '*',
      element: NotFound,
   },
];

export const authRoutes: IRoute[] = [
   {
      path: '/reset-password',
      element: AuthResetPasswordPage,
   },

   {
      path: '/Signin',
      element: AuthSignInPage,
   },
   {
      path: '/Signup',
      element: AuthSignUpPage,
   },
];
