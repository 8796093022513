import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light } = colorsUI;

export const drawerBurgerStyles: SxProps<Theme> = {
   height: '100dvh',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
};

export const drawerBurgerInnerStyles: SxProps<Theme> = {
   width: '100%',
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
   padding: '30px 20px',
};
