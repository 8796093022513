import React, { FC, Fragment, useState } from 'react';
import { Box, List, Typography } from '@mui/material';

import type { ListMoreProps } from '../types';

import { ReactComponent as IconArrow } from '../../../assets/icons/arrow-select.svg';

import {
   listMoreStyles,
   listMoreToggleIconStyle,
   listMoreToggleTextStyle,
   listMoreToggleBoxtStyle,
} from '../styles';

export const ListMore: FC<ListMoreProps> = ({
   items,
   countVisibleElements = 3,
   styles,
}) => {
   const [countVisible, setCountVisible] = useState(countVisibleElements);
   const [showAll, setShowAll] = useState(false);

   const handleToggle = () => {
      if (showAll) {
         setCountVisible(countVisibleElements);
         setShowAll(false);
      } else {
         setCountVisible(items.length);
         setShowAll(true);
      }
   };

   return (
      <>
         <List sx={{ ...listMoreStyles, ...styles }}>
            {items.slice(0, countVisible).map((item, i) => (
               <Fragment key={i}>{item}</Fragment>
            ))}
         </List>
         {items.length > countVisibleElements && (
            <Box sx={listMoreToggleBoxtStyle} onClick={handleToggle}>
               <IconArrow style={listMoreToggleIconStyle(showAll)} />
               <Typography sx={listMoreToggleTextStyle}>
                  {showAll ? 'Скрыть' : 'Еще'}
               </Typography>
            </Box>
         )}
      </>
   );
};
