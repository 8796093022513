import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SignInApiResponse } from 'app/dashboard/modules/auth/components/signInForms/type';

import {
   apiAuth,
   apiAuthLogin,
   apiAuthSignUp,
   apiAuthResetPassword,
} from '@constants/api/endpoints';

const baseUrlAuth = process.env.REACT_APP_API;

export const authApi = createApi({
   reducerPath: 'authApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrlAuth}${apiAuth}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      signUp: builder.mutation<
         { token: string },
         { email: string; accept_rules: boolean }
      >({
         query: (newUser) => ({
            url: apiAuthSignUp as string,
            method: 'POST',
            body: newUser,
         }),
      }),
      signIn: builder.mutation<
         SignInApiResponse,
         { email: string; password: string; remember_me: boolean }
      >({
         query: (data) => ({
            url: apiAuthLogin as string,
            method: 'POST',
            body: data,
         }),
      }),
      resetPassword: builder.mutation<{ token: string }, { email: string }>({
         query: (data) => ({
            url: apiAuthResetPassword as string,
            method: 'POST',
            body: data,
         }),
      }),
   }),
});

export const { useSignUpMutation, useSignInMutation, useResetPasswordMutation } = authApi;
