import React, { CSSProperties, FC } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { ButtonMore } from 'shared/buttons';
import { Link as LinRRD } from 'react-router-dom';
import { Link as LinkMUI } from '@mui/material';

import {
   TableActionsButtonMoreOptionsStyles,
   TableActionsButtonMoreOptionsInnerStyles,
} from './style';

import type { TableActionsButtonMoreOptionsProps } from './type.js';

export const TableActionsButtonMoreOptions: FC<TableActionsButtonMoreOptionsProps> = ({
   onClick,
   text,
   styles = {},
   href,
   stylesButtonMore,
}) => {
   return (
      <Box sx={TableActionsButtonMoreOptionsInnerStyles}>
         {href ? (
            <LinkMUI
               sx={{ ...TableActionsButtonMoreOptionsStyles, ...styles }}
               component={LinRRD}
               to={href}
            >
               {text}
            </LinkMUI>
         ) : (
            <Typography
               sx={{ ...TableActionsButtonMoreOptionsStyles, ...styles }}
               variant="body2"
            >
               {text}
            </Typography>
         )}
         <ButtonMore onClick={onClick} styles={stylesButtonMore as CSSProperties} />
      </Box>
   );
};
