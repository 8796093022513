import { useSignUpMutation } from 'app/dashboard/store/api/authApi';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useSignUp = () => {
   const [signUp, { isLoading, isSuccess, isError, error }] = useSignUpMutation();
   const handleSignUp = async (email: string) => {
      try {
         const response = await signUp({ email, accept_rules: true }).unwrap();

         return response;
      } catch (err) {
         console.error(err);
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return { handleSignUp, isSuccess, isLoading, isError, error: error as IErrorResponse };
};
