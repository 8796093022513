import type { ClearFormEditPasswordParams } from './type';

import clearState from '@utils/clearState';

export const clearFormEditPassword = ({
   setConfirmNewPassword,
   setCurrentPassword,
   setNewPassword,
}: ClearFormEditPasswordParams) => {
   const clearValue = '';
   clearState({ setValue: setConfirmNewPassword, clearValue });
   clearState({ setValue: setCurrentPassword, clearValue });
   clearState({ setValue: setNewPassword, clearValue });
};
