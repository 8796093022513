import React, { useState, FC, ChangeEvent } from 'react';

import { useProfileChangeEmail } from '@hooks/hooksApi/useProfileChangeEmail';

import { ModalAction } from '@shared/modals';

import editPhoneImage from '@assets/images/editPhone.png';

import { changeEmail } from '../../../utils/changeEmail';

import type { AccountPersonalDataModalEditEmailProps } from './type';

import { emailAlreadyExistsError } from '@errors/general';
import { IErrorsChangeEmail } from '../../../types/changeEmailTypes';

export const AccountPersonalDataModalEditEmail: FC<
   AccountPersonalDataModalEditEmailProps
> = ({ open, setOpen, email, onClickAction, setEmailErrors, errorsEmail }) => {
   const [prevEmail, setPrevEmail] = useState('');
   const { changeEmailData } = useProfileChangeEmail();

   const handleModal = () => {
      setOpen(!open);
   };
   const onClickEditEmail = async () => {
      console.log(email);
      console.log(prevEmail);
      if (prevEmail !== email) {
         const res = await changeEmail({
            changeEmailData,
            email,
            setErrors: setEmailErrors,
         });
         if (res) {
            onClickAction();
            setOpen(!open);
         } else {
            setPrevEmail(email);
         }
      } else {
         alert('введите новый email');
      }
   };

   return (
      <ModalAction
         disableButtonAction={!!errorsEmail?.emailAlreadyExists}
         onClickAction={onClickEditEmail}
         title="Сменить e-mail?"
         text={errorsEmail?.emailAlreadyExists ?? `Ваш новый e-mail: ${email ?? ''}`}
         open={open}
         onClose={handleModal}
         image={editPhoneImage}
         textClose="Отмена"
         textAction="Сменить E-mail"
      />
   );
};
