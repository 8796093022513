import React from 'react';

import { SignUpForms } from 'app/dashboard/modules/auth';
import { CenteredContainer } from 'shared/containers';

const AuthSignUpPage = () => {
   return (
      <CenteredContainer>
         <SignUpForms />
      </CenteredContainer>
   );
};

export default AuthSignUpPage;
