import { useState, useEffect } from 'react';
import { IReaction } from '@projectTypes/runtime';
import { getReactionTitle } from '../../components/visualScriptQuiz/utils';

import { ReactionEnum } from '@enums/runtime';

import type { useQuestionDataParams } from './type';

export const useQuestionData = ({
   messages,
   isLoading,
   reactionType,
}: useQuestionDataParams) => {
   const lastElIndex = messages.length - 1;
   const [activeQuestion, setActiveQuestion] = useState(lastElIndex);
   const [previousText, setPreviousText] = useState('');
   const [previousReactionTitle, setPreviousReactionTitle] = useState('');
   const [previousReactionType, setPreviousReactionType] = useState<
      ReactionEnum | null | undefined
   >(null);

   useEffect(() => {
      setActiveQuestion(lastElIndex);

      if (messages.length > 0) {
         setPreviousText(messages[lastElIndex].text);
      }

      if (!isLoading) {
         setPreviousReactionTitle(getReactionTitle(reactionType));
         setPreviousReactionType(reactionType);
      }
   }, [messages, reactionType, isLoading]);

   const reactionTitle = !isLoading && getReactionTitle(reactionType);

   const displayedReactionType = isLoading ? previousReactionType : reactionType;

   const isNegative =
      displayedReactionType === 'negative' || displayedReactionType === 'objection';

   const hint = messages[activeQuestion]?.hint;

   return {
      previousReactionType,
      activeQuestion,
      previousText,
      previousReactionTitle,
      reactionTitle,
      isNegative,
      hint,
   };
};
