import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { success, successActive } = colorsUI;

export const buttonCloseStyles = (): SxProps<Theme> => ({
   background: success,
   '&:hover': {
      background: successActive,
   },
});
