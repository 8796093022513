import { SxProps, Theme } from '@mui/material';
import { ButtonActionStylesType } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { disabledBackground, disabledText } = colorsUI;

export const buttonArrowStyles: ButtonActionStylesType = ({
   color,
   colorHover,
   backgroundColor,
   backgroundColorHover,
   disabled,
}): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   transition: ' all .5s',
   backgroundColor: disabled ? disabledBackground : backgroundColor,
   color: disabled ? disabledText : color,
   ...(!disabled && {
      '&:hover': {
         backgroundColor: backgroundColorHover,
         color: colorHover,
      },
   }),

   pointerEvents: disabled ? 'none' : 'auto',
});
