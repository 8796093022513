import { useSignInMutation } from 'app/dashboard/store/api/authApi';
import { useNavigate } from 'react-router-dom';

import type { IErrorResponse } from '@projectTypes/apiTypes';

type handleSignInParams = {
   email: string;
   password: string;
   remember_me: boolean;
};

export const useSignIn = () => {
   const [signIn, { isLoading, isSuccess, isError, error }] = useSignInMutation();
   const navigate = useNavigate();

   const handleSignIn = async (data: handleSignInParams) => {
      const { email, password, remember_me } = data;
      console.log(data);
      try {
         const response = await signIn({ email, password, remember_me }).unwrap();

         navigate('/');

         return response;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return { handleSignIn, isSuccess, isLoading, isError, error: error as IErrorResponse };
};
