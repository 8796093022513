import { ZodIssue, ZodSchema, z } from "zod";


export type GetErrorsAuthReturnType<T extends ZodSchema> = {
    [K in keyof z.infer<T>]?: string;
};

export const getErrorsAuth = <T extends ZodSchema>(
    arrErrors: ZodIssue[],
    schema: T
): GetErrorsAuthReturnType<T> => {
    const newObjErrors: GetErrorsAuthReturnType<T> = {};
    arrErrors.forEach(err => {
        const path = err.path[0] as keyof z.infer<T>; 
        newObjErrors[path] = err.message;
    });

    return newObjErrors;
};
