import { SxProps, Theme } from '@mui/material';
import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { black300, black500, light900 } = colorsUI;

export const headerItemIstyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '8px',
   [theme.breakpoints.down('lg')]: {
      display: 'none',
   },
});

export const headerItemIconStyles: CSSProperties = {
   fill: black500,
};

export const headerItemTitleStyles: CSSProperties = {
   color: black300,
};

export const headerItemLabelStyles: CSSProperties = {
   color: light900,
};
