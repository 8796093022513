import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black500 } = colorsUI;

export const managersNoneButtonStyles: SxProps<Theme> = {
   padding: '11px 42px',
};

export const managersNoneTypographyStyles: CSSProperties = {
   color: black500,
   textAlign: 'center',
};
