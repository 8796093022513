import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiDashboard, apiRuntimeLog } from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const runtimeLogApi = createApi({
   reducerPath: 'runtimeLogApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      runtimeLog: builder.query({
         query: () => ({
            url: `${apiRuntimeLog}?=тут_будут_параметры'`,
         }),
      }),
   }),
});

export const { useRuntimeLogQuery } = runtimeLogApi;
