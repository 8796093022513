import { styled, SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light900, light500 } = colorsUI;

export const instructionsStyles: SxProps<Theme> = {
   color: light900,
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   '&:hover': {
      color: light500,
   },
};
