import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material';

import { colorsUI } from 'constants/colorsUI';

const { light, light50, black50, black100, black500 } = colorsUI;

export const tableCheckBoxCellWithCheckboxStyles: CSSProperties = {
   background: light,
   border: 'none',
};
export const tableCheckBoxHeaderStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   background: light50,
});

export const tableCheckBoxCellStyles: CSSProperties = {
   padding: '24px 10px ',
   border: 'none',
};

// export const tableCheckBoxCellWithoutCheckboxStyles: CSSProperties = {
//    border: 'none',
//    borderRight: `1px solid ${black50}`,
// };

export const tableCheckBoxRowCellStyles: CSSProperties = {
   color: black500,
   padding: '24px',
   border: `1px solid ${black50}`,
};

export const tableCheckBoxSelectedRowStyles: SxProps = {
   backgroundColor: `${light50} !important`,
};

export const tableCellAdviceMobileStyles: CSSProperties = {
   fontFamily: 'Exo2-italic',
   color: black100,
};
