import { createTheme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { black600, light, light500, light600, light800, light900 } = colorsUI;

const fontFamilyBold = 'Exo2-Bold';
const fontFamilyRegular = 'Exo2-Regular';

const theme = createTheme({
   breakpoints: {
      values: {
         xs: 0,
         sm: 600,
         md: 980,
         lg: 1200,
         xl: 1440,
         xxl: 1660,
      },
   },

   typography: {
      fontFamily: fontFamilyRegular,
      h1: {
         fontFamily: fontFamilyBold,
         fontSize: '2rem',
         fontWeight: 900,
         lineHeight: '6.25rem',
         '@media (max-width:600px)': {
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
         },
      },
      h2: {
         fontFamily: fontFamilyBold,
         fontSize: '1.75rem',
         fontWeight: 700,
         lineHeight: '3rem',
         '@media (max-width:600px)': {
            fontSize: '1.25rem',
            lineHeight: '2.25rem',
         },
      },

      subtitle1: {
         fontFamily: fontFamilyRegular,
         fontSize: '1.5rem',
         fontWeight: 400,
         lineHeight: '2rem',
      },
      subtitle2: {
         fontFamily: fontFamilyRegular,
         fontSize: '1.25rem',
         fontWeight: 400,
         lineHeight: '1.8125rem',
      },
      body1: {
         fontFamily: fontFamilyRegular,
         fontSize: '1.125rem',
         fontWeight: 400,
         lineHeight: '1.4625rem',
      },
      body2: {
         fontFamily: fontFamilyRegular,
         fontSize: '1rem',
         fontWeight: 400,
         lineHeight: '1.20rem',
      },
      caption: {
         fontFamily: fontFamilyRegular,
         fontSize: '0.875rem',
         fontWeight: 400,
         lineHeight: '1.25rem',
      },
      overline: {
         fontFamily: fontFamilyRegular,
         fontSize: '0.75rem',
         fontWeight: 400,
         lineHeight: '1rem',
         textTransform: 'none',
      },
   },

   components: {
      MuiButton: {
         styleOverrides: {
            root: {
               padding: '10px 24px',
               fontSize: '1.125rem',
               fontWeight: 700,
               lineHeight: '1.5rem',
               fontFamily: fontFamilyBold,
               textTransform: 'none',
               borderRadius: '6px',
            },
         },
      },

      MuiLink: {
         styleOverrides: {
            root: {
               textDecoration: 'none',
               color: light600,
               '&:hover': {
                  color: light900,
               },
            },
         },
      },
      MuiRadio: {
         styleOverrides: {
            root: {
               transition: 'all .5s',

               '&.Mui-checked': {
                  color: light500,
                  '&:hover': {
                     color: light800,
                  },
               },
            },
         },
      },
   },
});

export default theme;
