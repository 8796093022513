import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { light, light50 } = colorsUI;

export const drawerDefaultStyles: CSSProperties = {
   background: light,
   borderRight: ` 1px solid ${light50}`,
   width: 'fit-content',
   minHeight: '90dvh',
};
