import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { success, danger, accentColor } = colorsUI;

export const buttonActionSuccessStyles: CSSProperties = {
   background: success,
};

export const buttonActionDangerStyles: CSSProperties = {
   background: danger,
};

export const buttonActionAccentColorStyles: CSSProperties = {
   background: accentColor,
};
