import React, { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

import { chexboxDefaultStyles, labelChexboxDefaultStyles } from '../styles';
import type { ChexboxDefaultProps } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { light500, transperent } = colorsUI;

export const ChexboxDefault: FC<ChexboxDefaultProps> = ({
   checked,
   onChange,
   disable = false,
   label,
   color = transperent,
   colorChecked = light500,
   styles = {},
   sx,
   sxLabel,
}) => {
   return (
      <FormControlLabel
         control={
            <Checkbox
               checked={checked}
               onChange={onChange}
               sx={{
                  ...chexboxDefaultStyles(disable, color, colorChecked),
                  ...((sx ?? {}) as object),
               }}
               disabled={disable}
            />
         }
         sx={{
            ...labelChexboxDefaultStyles(disable),
            ...((sxLabel ?? {}) as object),
            ...styles,
         }}
         label={label}
      />
   );
};
