import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import type { headerItemsProps } from './type';

import {
   headerItemIstyles,
   headerItemIconStyles,
   headerItemLabelStyles,
   headerItemTitleStyles,
} from './style';

export const HeaderItem: FC<headerItemsProps> = ({ Icon, title, label, sx }) => {
   return (
      <Box component={'li'} sx={headerItemIstyles}>
         <Icon sx={headerItemIconStyles} />
         <Typography sx={headerItemTitleStyles} variant="body2">
            {title}
         </Typography>
         <Typography sx={headerItemLabelStyles} variant="subtitle1">
            {label}
         </Typography>
      </Box>
   );
};
