import { SxProps, Theme } from '@mui/material/styles';
import { colorsUI } from 'constants/colorsUI';
import { shadowUI } from 'constants/shadowUI';

const {
   textFieldBorderColor,
   textFieldBorderColorHover,
   placeholderColor,
   light800,
   black500,
   black50,
   black100,
} = colorsUI;

const { shadowSmall } = shadowUI;

export const inputSearchStyles = (theme: Theme, disabled: boolean): SxProps<Theme> => ({
   width: 'auto',
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
   '& .MuiOutlinedInput-root': {
      boxShadow: shadowSmall,
      '& input': {
         fontSize: '1rem',
         color: black500,
         boxShadow: shadowSmall,
      },

      '& fieldset': {
         transition: 'all .5s',
         borderRadius: '4px',
         border: `1px solid ${black50}`,
      },

      '& .MuiInputBase-input': {
         padding: '8px 24px',
         fontSize: '1rem',
         backgroundColor: disabled ? black50 : '',
         color: disabled ? black100 : '',
         borderColor: disabled ? black100 : '',
      },
      '& .MuiInputBase-input::placeholder': {
         color: placeholderColor,
      },

      '&:hover fieldset': {
         borderColor: textFieldBorderColorHover,
      },

      '&.Mui-focused fieldset': {
         border: `1px solid ${light800}`,
      },
   },
});
