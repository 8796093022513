import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light100, black100, black400, black900, danger, dangerActive, transperent } =
   colorsUI;

export const listItemSelectStyles = (
   disable?: boolean,
   error?: boolean,
   selected?: boolean,
): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '4px',
   transition: 'all .5s',
   color: error ? danger : disable ? black100 : black400,
   background: selected ? light100 : transperent,
   ...(!disable && {
      '&:hover': {
         color: error ? dangerActive : black900,
         background: light100,
      },
   }),
});
