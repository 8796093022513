import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { danger, transperent } = colorsUI;

export const buttonDeleteStyles = (): SxProps<Theme> => ({
   background: transperent,
   color: danger,
   padding: '11px 16px',
   '&:hover': {
      background: transperent,
   },
});
