import React, { MouseEvent, useMemo, useRef, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useMediaQuery } from '@mui/material';

import { Box } from '@mui/material';
import { TableDefault, TableMobile } from '@shared/tables';
import { InputSearch } from '@shared/inputs';
import { PopupDefault } from '@shared/popups';
import { ListPopupActionContents } from 'shared/lists';
import { TableActionsButtonMoreOptions } from 'components/tableActions/tableActionsButtonMoreOptions';
import { ListMore } from '@shared/lists';
import { ModalDelete } from '@shared/modals';
import { ListItemLink } from '@shared/listItems';

import {
   managersDataStyles,
   managersDataFilterStyles,
   managersDataFilterTextFieldStyles,
} from './styles';

import type { ListPopupActionContentItemsProps } from '@shared/lists/types';

import { ReactComponent as IconPerson } from '@assets/icons/personIcon.svg';
import { ReactComponent as IconMail } from '@assets/icons/mail.svg';
import { ReactComponent as IconAnalytics } from '@assets/icons/analyticsPopupIcon.svg';
import { ReactComponent as IconLogs } from '@assets/icons/logsIcon.svg';
import { ReactComponent as IconDelete } from '@assets/icons/deleteKrest.svg';

const rows = [
   { name: '1 Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
   { name: '1 Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
   { name: '1 Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
   { name: '1 Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
   { name: '1 Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
];

export const ManagersData = () => {
   const [popupIsOpen, setPopupIsOpen] = useState(false);
   const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [rowState, setRowState] = useState<string>('');

   const handleClickPopup = (event: MouseEvent<HTMLElement>, row: string) => {
      setAnchorEl(event.currentTarget);
      setPopupIsOpen(true);
      setRowState(row);
   };

   const handleClosePopup = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
   };

   const handleOpenDeleteModal = () => {
      setAnchorEl(null);
      setPopupIsOpen(false);
      setModalDeleteIsOpen(true);
   };

   const handleCloseDeleteModal = () => {
      setModalDeleteIsOpen(false);
   };

   const handleOnDelete = () => {
      setModalDeleteIsOpen(false);
   };

   const popupActionContentItems: ListPopupActionContentItemsProps[] = useMemo(
      () => [
         {
            title: 'Перейти в профиль',
            onClick: () => alert('Перейти в профиль'),
            Icon: IconPerson,
         },
         {
            title: 'Отправить приглашение',
            onClick: () => alert('Отправить приглашение'),
            Icon: IconMail,
            disable: true,
         },
         {
            title: 'Аналитика',
            onClick: () => alert('Аналитика'),
            Icon: IconAnalytics,
         },
         {
            title: 'Логи',
            onClick: () => alert('Логи'),
            Icon: IconLogs,
         },
         {
            title: 'Удалить',
            onClick: handleOpenDeleteModal,
            Icon: IconDelete,
            error: true,
         },
      ],
      [handleClickPopup],
   );

   const reactListTest = [
      <ListItemLink href="/" label="test1" />,
      <ListItemLink href="/" label="test2" />,
      <ListItemLink href="/" label="test3" />,
      <ListItemLink href="/" label="test4" />,
   ];

   const columns: any = [
      { id: 'name', label: 'Dessert (100g serving)' },
      { id: 'calories', label: 'Calories' },
      { id: 'fat', label: 'Fat (g)' },
      { id: 'carbs', label: 'Carbs (g)' },
      {
         id: 'protein',
         label: 'Protein (g)',
         renderCell: (row: any) => (
            <ListMore countVisibleElements={2} items={reactListTest} />
         ),
      },
      {
         id: 'actions',
         label: 'Actions',
         renderCell: (row: any) => (
            <TableActionsButtonMoreOptions
               text="Редактировать"
               onClick={(e) => handleClickPopup(e as MouseEvent<HTMLButtonElement>, row)}
            />
         ),
      },
   ];

   const { theme } = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   const inputRef = useRef<HTMLInputElement>(null);

   return (
      <Box sx={managersDataStyles}>
         <ModalDelete
            open={modalDeleteIsOpen}
            onClose={handleCloseDeleteModal}
            onDelete={handleOnDelete}
            title="Удаление менеджера"
            text="Вы уверены, что хотите уволить менеджера? Вся аналитика и логи менеджера будут удалены"
         />
         <PopupDefault
            styles={{ padding: 0 }}
            handleClose={handleClosePopup}
            open={popupIsOpen}
            anchorEl={anchorEl}
         >
            <ListPopupActionContents
               styles={{ padding: 0 }}
               items={popupActionContentItems}
            />
         </PopupDefault>
         <Box sx={managersDataFilterStyles}>
            <InputSearch
               stylesForm={managersDataFilterTextFieldStyles(theme) as object}
               stylesWrapper={managersDataFilterTextFieldStyles(theme) as object}
               inputRef={inputRef}
               placeholder="Поиск"
            />
         </Box>
         {isMobile ? (
            <TableMobile columns={columns} rows={rows} />
         ) : (
            <TableDefault columns={columns} rows={rows} />
         )}
      </Box>
   );
};
