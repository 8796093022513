import React, { FC } from 'react';

import type { CardProfileProps } from '../types';
import { Box, Typography } from '@mui/material';

import {
   cardProfileStyles,
   cardProfileTilteStyles,
   cardProfileBodyStyles,
   cardProfileBodyTitleStyles,
   cardProfileFooterStyles,
} from '../styles';

export const CardProfile: FC<CardProfileProps> = ({
   headerCard,
   tileCard,
   bodyCard,
   footerCard,
   titleVariant = 'h4',
   sxTitle = {},
}) => {
   return (
      <Box sx={{ ...cardProfileStyles }}>
         <Typography sx={cardProfileTilteStyles} variant="subtitle1">
            {headerCard}
         </Typography>
         <Box sx={cardProfileBodyStyles}>
            <Typography
               sx={{ ...cardProfileBodyTitleStyles, ...sxTitle }}
               component={'h3'}
               variant={
                  titleVariant as
                     | 'h1'
                     | 'h2'
                     | 'h3'
                     | 'h4'
                     | 'h5'
                     | 'h6'
                     | 'subtitle1'
                     | 'body1'
               }
            >
               {tileCard}
            </Typography>
            <Box>{bodyCard}</Box>
         </Box>
         <Box sx={cardProfileFooterStyles}>{footerCard}</Box>
      </Box>
   );
};
