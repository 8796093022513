import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Admin from 'app/admin';
import DashBoard from 'app/dashboard';

const AppRouters: React.FC = () => {
   const hostname = window.location.hostname.split('.');

   return (
      <BrowserRouter>
         <Routes>
            {hostname[0] === 'admin' && <Route path="/*" element={<Admin />} />}
            {hostname[0] === 'dashboard' && <Route path="/*" element={<DashBoard />} />}
            <Route path="*" element={<div>404 Not Found</div>} />
         </Routes>
      </BrowserRouter>
   );
};

export default AppRouters;
