import React, { FC, useEffect, useRef } from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import type { PopupDefaultProps } from '../types';

import { popupContentStyles } from '../styles';

export const PopupDefault: FC<PopupDefaultProps> = ({
   open,
   anchorEl,
   handleClose,
   children,
   placement = 'bottom-end',
   styles,
}) => {
   const popperRef = useRef<HTMLDivElement | null>(null);

   useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
         if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
            handleClose();
         }
      };

      if (open) {
         document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, [open, handleClose]);

   return (
      <Popper
         placement={placement}
         open={open}
         anchorEl={anchorEl}
         transition
         ref={popperRef}
      >
         {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
               <Paper
                  sx={{ ...popupContentStyles, styles }}
                  onClick={(e) => e.stopPropagation()}
               >
                  {children}
               </Paper>
            </Fade>
         )}
      </Popper>
   );
};
