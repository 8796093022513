import React, { FC } from 'react';
import Button from '@mui/material/Button';
import type { ButtonDefaultProps } from '../types';

import { buttonDefaultStyles } from '../styles';

import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light900, light500, light } = colorsUI;

export const ButtonDefault: FC<ButtonDefaultProps> = ({
   onClick,
   type = 'button',
   color = light,
   colorHover = light,
   backgroundColor = light500,
   backgroundColorHover = light900,
   disabled = false,
   startIcon,
   endIcon,
   styles,
   sx,
   children,
   label,
}) => {
   return (
      <Button
         sx={{
            ...(buttonDefaultStyles({
               color,
               colorHover,
               backgroundColor,
               backgroundColorHover,
               disabled,
            }) as SxProps<Theme>),
            ...((sx as object) ?? {}),
         }}
         style={styles}
         disabled={disabled}
         type={type}
         onClick={onClick}
         startIcon={startIcon}
         endIcon={endIcon}
      >
         {children ?? label}
      </Button>
   );
};
