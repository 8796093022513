export const boxButtonStyles = {

    width:"100%", 
    display:"flex", 
    justifyContent:"end", 
    flexWrap:"wrap",
    gap: '10px',
    '@media (max-width: 410px)': {
     flexDirection: 'column', 
     alignItems: 'center',   
     gap: '8px',      
   },
   };
   

export const buttonSubmitStyles = {
    width: 'auto',
    '@media (max-width: 360px)': {
      width:"100%"
    },
  } 