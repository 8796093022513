import { SxProps, Theme } from '@mui/material';

export const accountPersonalDataStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   gap: '32px',
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '16px',
   },
});
