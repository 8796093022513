import { Dispatch, FormEvent, SetStateAction } from 'react';
import { ErrorStateSignIn, SignInData } from '../../../schemas';
import { SignInApiResponse } from '../type';
import { validateSignInForm } from './validateSignInForm';
import { setTokenInCookie } from '@utils/setTokenInCookie';
import { login } from 'app/dashboard/store/slices/auth/authSlice';

import type { AppDispatch } from 'app/dashboard/store';

type HandleSubmitFormParams = {
   e: FormEvent;
   email: string;
   password: string;
   checked: boolean;
   setErrors: Dispatch<SetStateAction<ErrorStateSignIn | null>>;
   setEmail: Dispatch<SetStateAction<string>>;
   setPassword: Dispatch<SetStateAction<string>>;
   dispatch: AppDispatch;
   errorsSignIn: ErrorStateSignIn;
   handleSignIn: (data: SignInData) => Promise<SignInApiResponse>;
};

export const handleSubmitForm = async ({
   e,
   email,
   password,
   checked,
   setErrors,
   setEmail,
   setPassword,
   dispatch,
   errorsSignIn,
   handleSignIn,
}: HandleSubmitFormParams): Promise<void> => {
   e.preventDefault();

   const data: SignInData = { email, password, remember_me: checked };
   const result = validateSignInForm({ data, setErrors, setEmail, setPassword });

   if (!result) {
      try {
         const response: SignInApiResponse = await handleSignIn(data);
         setTokenInCookie(response?.data?.personal_access_token);
         await dispatch(login());
      } catch (err) {
         setErrors({ invalidEmailOrPassword: errorsSignIn.invalidEmailOrPassword });
      }
   }
};
