import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { SxProps, Theme } from '@mui/material';

import { Box } from '@mui/material';

import type { CardProps } from '@shared/cards/types';

import { cardDefaultStyles } from '../styles/cardDefaultStyles';

export const CardDefault: FC<CardProps> = ({ children, styles, sx }) => {
   const { theme } = useTheme();

   return (
      <Box
         sx={{
            ...(cardDefaultStyles({
               theme,
            }) as SxProps<Theme>),
            ...((sx as object) ?? {}),
         }}
         style={styles}
      >
         {children}
      </Box>
   );
};
