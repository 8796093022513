import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from '@constants/colorsUI';

const { light300, black500 } = colorsUI;

export const visualScriptQuizHeaderStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '24px',
   background: light300,
   padding: '16px 32px',
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '8px',
   },
});

export const visualScriptQuizHeaderHintTextStyles = (theme: Theme): SxProps<Theme> => ({
   lineHeight: '1.625rem',
   color: black500,
   fontWeight: '700',
   [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
   },
});
