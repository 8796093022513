import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useQuestionData } from '../../hooks/useQuestionData';

import { Box, Typography } from '@mui/material';
import { VisualScriptQuizHeader } from '../visualScriptQuizHeader';

import { ReactComponent as IconCopy } from '@assets/icons/copy.svg';
import type { VisualScriptQuizProps } from './type';

import {
   visualScriptQuizStyles,
   visualScriptQuizContainerStyles,
   visualScriptQuizObjectionStyles,
   visualScriptQuizTextStyles,
   visualScriptQuizTitleStyles,
} from './styles';
import { ReactionEnum } from '@enums/runtime';

export const VisualScriptQuiz: FC<VisualScriptQuizProps> = ({
   messages,
   reactionType,
   isLoading,
}) => {
   const { theme } = useTheme();

   const {
      activeQuestion,
      previousText,
      previousReactionType,
      reactionTitle,
      isNegative,
      hint,
   } = useQuestionData({ messages, isLoading, reactionType });

   const displayedReactionType = isLoading ? previousReactionType : reactionType;

   return (
      <Box sx={{ ...visualScriptQuizContainerStyles() }}>
         {hint && <VisualScriptQuizHeader hint={hint} />}
         <Box
            sx={{
               ...visualScriptQuizStyles({
                  reaction: (displayedReactionType ?? 'positive') as ReactionEnum,
               }),
            }}
         >
            <Box>
               <Typography
                  variant="h1"
                  component="h3"
                  sx={{
                     ...visualScriptQuizTitleStyles({
                        isNegative,
                        theme,
                     }),
                  }}
               >
                  <IconCopy /> ГОВОРИ
               </Typography>
            </Box>
            <Typography
               sx={{ ...visualScriptQuizObjectionStyles(theme) }}
               variant="subtitle2"
            >
               {reactionTitle}
            </Typography>
            <Typography sx={{ ...visualScriptQuizTextStyles(theme) }} variant="subtitle1">
               {isLoading
                  ? previousText
                  : messages[activeQuestion]?.text || 'No text available'}
            </Typography>
         </Box>
      </Box>
   );
};
