import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';

const LinkArrowSvg: FC<SvgIconProps> = (props) => {
   return (
      <SvgIcon {...props}>
         <path
            width="10px"
            hanging="10px"
            d="M14.725 18.525C14.525 18.525 14.325 18.425 14.225 18.325L8.225 12.325C7.925 12.025 7.925 11.525 8.225 11.225L14.225 5.225C14.525 4.925 15.025 4.925 15.325 5.225C15.625 5.525 15.625 6.025 15.325 6.325L9.825 11.825L15.325 17.325C15.625 17.625 15.625 18.125 15.325 18.425C15.125 18.425 14.925 18.525 14.725 18.525Z"
            fill={props.color}
         />
      </SvgIcon>
   );
};

export default LinkArrowSvg;
