import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material/styles';

const { black200, black500 } = colorsUI;

export const filterStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   color: black200,
   whiteSpace: 'nowrap',
};

export const filterInnerStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   justifyContent: 'space-around',
   overflow: 'hidden',
   [theme.breakpoints.down('md')]: {
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
   },
});

export const filterItemsStyles: CSSProperties = {
   color: black200,

   cursor: 'pointer',
};

export const filterItemsActiveStyles: CSSProperties = {
   color: black500,
   fontWeight: 700,

   cursor: 'pointer',
};
