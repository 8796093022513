import React from 'react';

import { useTheme } from '@hooks/useTheme';

import Box from '@mui/material/Box';
import { TabsHeaderSmooth } from '../tabsHeader/tabsHeaderSmooth';
import { TabsBody } from '../tabsBody';

import type { TabProps } from '../types';

import { tabDefaultStyles } from './style';

export const TabDefault: React.FC<TabProps> = ({ content, labels }) => {
   const [value, setValue] = React.useState(0);

   const { theme } = useTheme();

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Box sx={tabDefaultStyles({ theme })}>
         <TabsHeaderSmooth labels={labels} onChange={handleChange} value={value} />
         {content.map((tab, index) => (
            <TabsBody key={index} index={index} value={value}>
               {tab}
            </TabsBody>
         ))}
      </Box>
   );
};
