import type { ClearStateParams } from './type';

const clearState = <T>({ setValue, clearValue }: ClearStateParams<T>) => {
   if (Array.isArray(clearValue)) {
      setValue([] as T);
   } else if (typeof clearValue === 'object' && clearValue !== null) {
      setValue({} as T);
   } else {
      setValue(clearValue);
   }
};

export default clearState;
