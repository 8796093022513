import React, { FC, useState } from 'react';

import { useRuntimeData } from '@hooks/index';
import { Theme, useMediaQuery } from '@mui/material';

import { VisualScriptContainer } from '../visualScriptContainer';
import { VisualScriptStructure } from '../visualScriptStructure';
import { VisualScriptForm } from '../visualScriptForm';
import { VisualScriptChat } from '../visualScriptChat';
import { LoaderDefault } from '@shared/loader';
import { ModalDefault } from '@shared/modals';

import { ReactComponent as IconList } from '@assets/icons/listIcon.svg';
import { ReactComponent as IconEdit } from '@assets/icons/edit.svg';

import type { VisualScriptProps } from './type';

import {
   visualScriptModalInnerStylesStyles,
   visualScriptButtonModalForm,
   visualScriptButtonModalList,
} from './style';
import { ButtonIcon } from '@shared/buttons';
import { useVisualScriptChatLogic } from '../../hooks';
import { VisualScriptExodus } from '../visualScriptExodus';

export const VisualScript: FC<VisualScriptProps> = ({ slug }) => {
   const [isOpenModalForm, setIsOpenModalForm] = useState<boolean>(false);

   const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

   const { runtimeStartData, runtimeStartIsError, runtimeStartIsLoading } =
      useRuntimeData(slug);

   const { messages, reactionsButton, isEnd, handleOnClickCell, isLoading, exodus } =
      useVisualScriptChatLogic({
         runtimeData: runtimeStartData,
      });

   const handleModalForm = () => {
      setIsOpenModalForm(!isOpenModalForm);
   };
   const handleModalList = () => {
      setIsOpenModalForm(!isOpenModalForm);
   };

   if (runtimeStartIsError) return <p>error!</p>;

   return (
      <VisualScriptContainer>
         {runtimeStartIsLoading ? (
            <LoaderDefault />
         ) : (
            <>
               {isMobile ? (
                  <ButtonIcon
                     Icon={IconList}
                     onClick={handleModalList}
                     styles={visualScriptButtonModalList}
                  />
               ) : (
                  <VisualScriptStructure />
               )}

               {isEnd ? (
                  <VisualScriptExodus exodus={exodus!} />
               ) : (
                  <VisualScriptChat
                     messages={messages}
                     handleOnClickCell={handleOnClickCell}
                     isEnd={isEnd}
                     isLoading={isLoading}
                     reactionsButton={reactionsButton}
                  />
               )}

               {isMobile ? (
                  <ButtonIcon
                     styles={visualScriptButtonModalForm}
                     Icon={IconEdit}
                     onClick={handleModalForm}
                  />
               ) : (
                  <VisualScriptForm dataStart={runtimeStartData} />
               )}
            </>
         )}
         <ModalDefault
            styleModalButtonClose={{}}
            stylesModalContent={visualScriptModalInnerStylesStyles}
            open={isOpenModalForm}
            handleClose={handleModalForm}
            handleOpen={handleModalForm}
         >
            <VisualScriptForm dataStart={runtimeStartData} />
         </ModalDefault>
      </VisualScriptContainer>
   );
};
