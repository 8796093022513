import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { light, light500, light900, success, successActive } = colorsUI;

export const modalActionBoxButtonStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   width: '100%',

   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   gap: '24px',

   [theme.breakpoints.down('md')]: {
      gap: '8px',
      flexDirection: 'column-reverse',
      maxWidth: 'none',
   },
});

export const modalActionBoxButtonCloseStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   border: `1px solid ${light500}`,
   color: light900,
   padding: '10px 50px',
   width: '40%',
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});

export const modalActionBoxButtonActionStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   background: success,
   padding: '10px 50px',
   color: light,
   width: '40%',
   '&:hover': {
      backgroundColor: successActive,
   },
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});
