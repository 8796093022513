import React, { useRef, useState } from 'react';

import { useToggleModal } from '@hooks/hooksUI';
import { useProfileUpdatePhone } from '@hooks/hooksApi/useProfileUpdatePhone';
import useAppSelector from 'app/dashboard/store/hooks/useAppSelector';
import { useTheme } from '@hooks/useTheme';

import { InputDefault, InputPhone } from '@shared/inputs';
import { Box } from '@mui/material';
import { ButtonDefault } from '@shared/buttons';
import { AccountPersonalDataModalEditPassword } from '../modals/accountPersonalDataModalEditPassword';
import { AccountPersonalDataModalEditPhone } from '../modals/accountPersonalDataModalEditPhone';
import { AccountPersonalDataModalConfirmPassword } from '../modals/accountPersonalDataModalConfirmPassword';
import { AccountPersonalDataModalEditEmail } from '../modals/accountPersonalDataModalEditEmail';
import { AccountPersonalDataModalConfirmCode } from '../modals/accountPersonalDataModalConfirmCode';
import { AccountPersonalDataModalConfirmEmail } from '../modals/accountPersonalDataModalConfirmEmail';
import { FormEditFIO } from '../forms';

import { selectProfile } from 'app/dashboard/store/selectors';

import { updatePhone } from '../../utils/updatePhone';
import { onClickEditEmail } from './utils/onClickEditEmail';

import {
   accountPersonalDataFormStyles,
   accountPersonalDataFormItemStyles,
   accountPersonalDataFormTextfieldStyles,
   accountPersonalDataFormItemButtonStyles,
   accountPersonalDataFormButtonResetPasswordStyles,
} from './style';

import { IErrorsChangeEmail } from '../../types/changeEmailTypes';

export const AccountPersonalDataForm = () => {
   const { data } = useAppSelector(selectProfile);

   console.log(data);
   const { firstName, lastName, surname } = data;

   const {
      isOpen: isOpenModalConfirmPassword,
      setIsOpen: setIsOpenModalConfirmPassword,
      toggle: toggleModalConfirmPassword,
   } = useToggleModal();

   const { isOpen: isOpenModalEmail, setIsOpen: setIsOpenModalEmail } = useToggleModal();

   const {
      isOpen: isOpenModalEditPassword,
      setIsOpen: setIsOpenModalEditPassword,
      toggle: toggleModalEditPassword,
   } = useToggleModal();

   const {
      isOpen: isOpenModalConfirmEmail,
      setIsOpen: setIsOpenModalConfirmEmail,
      toggle: toggleModalConfirmEmail,
   } = useToggleModal();

   const { isOpen: isOpenModalEditPhone, setIsOpen: setIsOpenModalEditPhone } =
      useToggleModal();

   const {
      isOpen: isOpenModalConfirmCode,
      setIsOpen: setIsOpenModalConfirmCode,
      toggle: toggleModalConfirmCode,
   } = useToggleModal();

   const [phone, setPhone] = useState('');
   const [countryCode, setCountryCode] = useState('');

   const [errorsEmail, setEmailErrors] = useState<IErrorsChangeEmail | null>(null);
   const [errorsPhone, setErrorsPhone] = useState<string | null>(null);

   const emailRef = useRef<HTMLInputElement>(null);

   const { theme } = useTheme();

   const {
      updatePhoneData,
      isError: isErrorPhoneServer,
      error: errorPhoneServer,
   } = useProfileUpdatePhone();

   const handleEditEmail = () => {
      const email = emailRef?.current?.value;
      onClickEditEmail({ email, setEmailErrors, setIsOpenModalEmail });
   };

   const handleConfirmCode = async () => {
      await updatePhone({
         countryCode,
         phone,
         setErrorsPhone,
         setPhone,
         toggleModalConfirmCode,
         updatePhoneData,
      });
   };

   return (
      <Box sx={accountPersonalDataFormStyles({ theme })}>
         <FormEditFIO firstName={firstName} lastName={lastName} surname={surname} />

         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputPhone
               error={
                  isErrorPhoneServer
                     ? errorPhoneServer?.data?.data.message
                     : (errorsPhone as string)
               }
               placeholder="+7-777-777-77-77"
               sxWrapper={{ width: '100%' }}
               phone={phone}
               setPhone={setPhone}
               setCountry={setCountryCode}
            />
            <ButtonDefault
               onClick={handleConfirmCode}
               sx={accountPersonalDataFormItemButtonStyles({ theme })}
            >
               Сменить
            </ButtonDefault>
         </Box>

         <Box sx={accountPersonalDataFormItemStyles({ theme })}>
            <InputDefault
               type="email"
               stylesWrapper={accountPersonalDataFormTextfieldStyles}
               stylesForm={accountPersonalDataFormTextfieldStyles}
               ref={emailRef}
               placeholder="E-mail"
               error={errorsEmail?.incorectEmail || errorsEmail?.emailAlreadyExists}
            />
            <ButtonDefault
               onClick={handleEditEmail}
               sx={accountPersonalDataFormItemButtonStyles({ theme })}
            >
               Сменить
            </ButtonDefault>
         </Box>
         <ButtonDefault
            onClick={toggleModalEditPassword}
            sx={accountPersonalDataFormButtonResetPasswordStyles({ theme })}
         >
            Сбросить пароль
         </ButtonDefault>
         <AccountPersonalDataModalEditPassword
            onClickAction={toggleModalConfirmPassword}
            setOpen={setIsOpenModalEditPassword}
            open={isOpenModalEditPassword}
         />
         <AccountPersonalDataModalEditPhone
            open={isOpenModalEditPhone}
            setOpen={setIsOpenModalEditPhone}
            newPhone={phone}
         />
         <AccountPersonalDataModalConfirmPassword
            setOpen={setIsOpenModalConfirmPassword}
            open={isOpenModalConfirmPassword}
         />
         <AccountPersonalDataModalConfirmEmail
            setOpen={setIsOpenModalConfirmEmail}
            open={isOpenModalConfirmEmail}
         />
         <AccountPersonalDataModalEditEmail
            errorsEmail={errorsEmail}
            setEmailErrors={setEmailErrors}
            onClickAction={toggleModalConfirmEmail}
            email={emailRef.current?.value as string}
            open={isOpenModalEmail}
            setOpen={setIsOpenModalEmail}
         />
         <AccountPersonalDataModalConfirmCode
            open={isOpenModalConfirmCode}
            setOpen={setIsOpenModalConfirmCode}
         />
      </Box>
   );
};
