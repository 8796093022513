import React, { FormEvent, useState, MouseEvent, ChangeEvent } from 'react';

import { useGoBack } from '@hooks/useGoBack';
import { useSignUp } from '../../hooks/useSignUp';

import { Box, Typography } from '@mui/material';
import FormAuth from 'components/forms/formAuth';
import Logo from '@shared/logo';
import { InputDefault } from '@shared/inputs';
import { ButtonDefault } from '@shared/buttons';
import { Link } from 'react-router-dom';
import { NotificationMessage } from '../notificationMessage';
import { ErrorMessageInput } from '@shared/errorMessage';

import { boxButtonStyles } from './style';

import { onSubmitForm as onSubmitFormSignUp } from './utils';

import type { ErrorStateSignUp } from '../../schemas';

import { colorsUI } from '@constants/colorsUI';

const { light900, black300, black200, light500, light600, light, transperent } = colorsUI;

export const SignUpForms = () => {
   const [email, setEmail] = useState('');
   const [error, setErrors] = useState<ErrorStateSignUp | null>(null);

   const {
      handleSignUp,
      isLoading,
      isSuccess,
      isError,
      error: errorSignUp,
   } = useSignUp();

   const goBack = useGoBack();

   const onSubmitForm = async (e: FormEvent) => {
      onSubmitFormSignUp({
         e,
         email,
         setErrors,
         handleSignUp,
      });
   };

   const handleChaneElamil = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
   };

   const handleGoBackClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      goBack();
   };

   return (
      <>
         {isSuccess ? (
            <NotificationMessage
               label="Спасибо за регистрацию!"
               title="На указанный вами адрес было отправленно письмо, перейдите по ссылке, чтобы завершить регистрацию"
            ></NotificationMessage>
         ) : (
            <FormAuth styles={{ maxWidth: '520px' }} onSubmit={onSubmitForm} gap="24px">
               <Logo style={{ width: '100%', maxWidth: '260px' }} />
               <Box sx={{ width: '100%' }}>
                  <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
                     Быстрая регистрация
                  </Typography>
                  <Typography sx={{ color: black300, mr: '4px' }} variant="caption">
                     Уже есть аккаунт?
                  </Typography>
                  <Link style={{ color: light900, fontSize: '14px' }} to="/signin">
                     Войти
                  </Link>
               </Box>
               <Box sx={{ width: '100%' }}>
                  <InputDefault
                     disabled={isLoading}
                     required={true}
                     stylesForm={{ width: '100%' }}
                     value={email}
                     type="email"
                     placeholder="Введите E-Mail"
                     onChange={handleChaneElamil}
                     error={error?.email}
                  />
                  {isError && (
                     <ErrorMessageInput
                        stylesInner={{ margin: '10px 0' }}
                        message={errorSignUp?.data.data.message}
                     />
                  )}

                  <Typography
                     sx={{ color: black200, marginTop: '12px' }}
                     variant="overline"
                  >
                     *Нажимая на кнопку «зарегистрироваться», Вы соглашаетесь на обработку
                     своих персональных данных.
                  </Typography>
               </Box>
               <Box sx={boxButtonStyles}>
                  <ButtonDefault
                     onClick={goBack}
                     label="Вернуться назад"
                     color={light600}
                     colorHover={light900}
                     backgroundColor={transperent}
                     backgroundColorHover={transperent}
                  />
                  <ButtonDefault
                     disabled={email.length === 0 || isLoading}
                     label="Зарегистрироваться"
                     type="submit"
                     color={light}
                     backgroundColor={light500}
                     backgroundColorHover={light900}
                  />
               </Box>
            </FormAuth>
         )}
      </>
   );
};
