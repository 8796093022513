import React, { FC } from 'react';
import { ListPopupActionContentProps } from '../types';

import { List } from '@mui/material';
import { ListItemWithIcon } from 'shared/listItems';

export const ListPopupActionContents: FC<ListPopupActionContentProps> = ({
   items,
   styles,
}) => {
   return (
      <List sx={styles}>
         {items.map(({ Icon, onClick, title, disable, error, href }) => (
            <ListItemWithIcon
               variantTypography="body2"
               key={title}
               label={title}
               Icon={Icon}
               onClick={onClick}
               disable={disable}
               error={error}
               href={href}
            />
         ))}
      </List>
   );
};
