import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

export const tabDefaultStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '40px',
   [theme.breakpoints.down('md')]: {
      gap: '16px',
   },
});
