import { useRuntimeStartQuery } from 'app/dashboard/store/api/runtimeApi';

export const useRuntimeData = (slug: string | null | undefined) => {
   const {
      data: runtimeStartData,
      currentData,
      isLoading: runtimeStartIsLoading,
      isError: runtimeStartIsError,
      isSuccess: runtimeStartIsSuccess,
      isFetching: runtimeStartIsFetching,
   } = useRuntimeStartQuery({ slug }, { skip: !slug });

   console.log(currentData);

   return {
      runtimeStartData,
      runtimeStartIsLoading,
      runtimeStartIsError,
      runtimeStartIsSuccess,
      runtimeStartIsFetching,
   };
};
