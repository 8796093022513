import React, { FC } from 'react';

import { Box, Drawer, Theme } from '@mui/material';
import { ButtonBurger } from 'shared/buttons';
import type { DrawerBurgerProps } from '../types';
import { SystemStyleObject } from '@mui/system';
import { drawerBurgerStyles, drawerBurgerInnerStyles } from '../styles';

export const DrawerBurgerMenu: FC<DrawerBurgerProps> = ({
   children,
   onClose,
   open = false,
   sx,
   variant,
}) => {
   return (
      <>
         <ButtonBurger isOpen={open} setIsOpen={onClose} />
         <Drawer
            variant={variant}
            open={open}
            onClose={onClose}
            anchor="left"
            sx={drawerBurgerStyles as SystemStyleObject<Theme>}
         >
            <Box sx={drawerBurgerInnerStyles}>{children}</Box>
         </Drawer>
      </>
   );
};
