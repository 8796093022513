import React, { useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useMediaQuery } from '@mui/material';

import { Box, Grid } from '@mui/material';
import { ButtonArrow } from '@shared/buttons';
import { Typography } from '@mui/material';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { BillboardUser } from '../components/billboards';
import { CardProfile } from '@shared/cards';
import { ActionProjectItem } from '../components/actionProjectItem';
import { LinkDefault } from '@shared/links';

import { ColumnDashboard } from '@shared/column';

import { TableDefault, TableMobile } from '@shared/tables';

import {
   profileCardLinkStyles,
   profileCardTitleleStyles,
   profileCardValideDateStyles,
   profileCardLinkMoreStyles,
} from '../styles';

import { colorsUI } from '@constants/colorsUI';
import { ColumnTable, DataRowTable } from '@shared/tables/types';

const { light900, transperent } = colorsUI;

const columns: ColumnTable<{
   name: string;
   calories: number;
   fat: number;
   carbs: number;
   protein: number;
}>[] = [
   { id: 'name', label: 'Name' },
   { id: 'calories', label: 'Calories' },
   { id: 'fat', label: 'Fat' },
   { id: 'carbs', label: 'Carbs' },
   { id: 'protein', label: 'Protein' },
];

const rows: DataRowTable<{
   name: string;
   calories: number;
   fat: number;
   carbs: number;
   protein: number;
}>[] = [
   { name: 'Apple', calories: 95, fat: 0.3, carbs: 25, protein: 0.5 },
   { name: 'Banana', calories: 105, fat: 0.3, carbs: 27, protein: 1.3 },
   { name: 'Orange', calories: 62, fat: 0.2, carbs: 15, protein: 1.2 },
];
export const buttonDefaultStyles = ({}) => ({
   backgroundColor: 'red',
   color: 'blue',
   transition: 'all .5s',

   '&:hover': {
      backgroundColor: 'blue',
      color: 'red',
      transition: 'all .5s',
   },

   pointerEvents: 'auto',
});

export const ProfileMain = () => {
   const { theme } = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <ColumnDashboard>
         <ProfileHeader title="Главная">
            <ButtonArrow>Создать новый скрипт</ButtonArrow>
         </ProfileHeader>

         <Grid container spacing={3}>
            <Grid item xxl={3} md={6} xs={12}>
               <BillboardUser name="Vetal" />
            </Grid>
            <Grid item xxl={3} md={6} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  headerCard="Активные проекты"
                  tileCard="Дистанционное БФЛ"
                  bodyCard={<ActionProjectItem countClients={250} countProjects={25} />}
                  footerCard={
                     <LinkDefault
                        sx={profileCardLinkStyles}
                        href="/"
                        label="Перейти к проекту"
                        backgroundColorHover={light900}
                     ></LinkDefault>
                  }
               />
            </Grid>
            <Grid item xxl={3} md={5} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  sxTitle={profileCardTitleleStyles}
                  headerCard="Баланс"
                  tileCard="15.00 руб."
                  footerCard={
                     <LinkDefault
                        sx={profileCardLinkStyles}
                        href="/"
                        label="Перейти к проекту"
                        backgroundColorHover={light900}
                     ></LinkDefault>
                  }
               />
            </Grid>
            <Grid item xxl={3} md={7} xs={12}>
               <CardProfile
                  titleVariant="body1"
                  sxTitle={profileCardTitleleStyles}
                  headerCard="Активный тариф"
                  tileCard="Standart"
                  bodyCard={
                     <Typography sx={profileCardValideDateStyles} variant="body2">
                        Действует до: 16.04.2024
                     </Typography>
                  }
                  footerCard={
                     <>
                        <LinkDefault
                           sx={profileCardLinkStyles}
                           href="/"
                           label="Сменить тариф"
                           backgroundColorHover={light900}
                        ></LinkDefault>
                        <LinkDefault
                           sx={profileCardLinkMoreStyles}
                           href="/"
                           label="Подробнее"
                           backgroundColor={transperent}
                        ></LinkDefault>
                     </>
                  }
               />
            </Grid>
         </Grid>

         <Box>
            {isMobile ? (
               <TableMobile columns={columns} rows={rows} />
            ) : (
               <TableDefault columns={columns} rows={rows} />
            )}
         </Box>
      </ColumnDashboard>
   );
};
