import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

const { light100, black300 } = colorsUI;

export const formCreateManagerItemStyles = (isActive: boolean): SxProps<Theme> => ({
   width: '100%',
   background: isActive ? light100 : '',
   display: 'flex',
   justifyContent: 'center',
   padding: '10px',
});
export const formCreateManagerItemContentStyles = (): SxProps<Theme> => ({
   width: '100%',
   maxWidth: '750px',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   gap: '24px',
});

export const labelStyles: CSSProperties = {
   color: black300,
   display: 'block',
   padding: '4px 8px',
};

export const selectStyles: CSSProperties = {
   width: '100%',
};

export const inputStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
};

export const formBoxButtonStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   gap: '16px',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});
