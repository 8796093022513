import { removeCountryCode } from '@utils/phone';

import type { UpdatePhoneParams } from './type';
import type { IUpdatePhoneRequest } from '@projectTypes/profile';

import { incorectPhoneError } from '@errors/general';

export const updatePhone = async ({
   countryCode,
   phone,
   setErrorsPhone,
   setPhone,
   toggleModalConfirmCode,
   updatePhoneData,
}: UpdatePhoneParams) => {
   const phoneData: IUpdatePhoneRequest = {
      phone_number: removeCountryCode({ phone, code: countryCode }),
      phone_code: countryCode,
   };
   if (countryCode) {
      const res = await updatePhoneData(phoneData);
      if (res) {
         setPhone('');
         toggleModalConfirmCode();
      }
   } else {
      setErrorsPhone(incorectPhoneError);
   }
};
