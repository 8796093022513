import React, { useState, FC } from 'react';

import { ModalAction } from '@shared/modals';

import editPhoneImage from '@assets/images/editPhone.png';

import type { AccountPersonalDataModalEditPhoneProps } from './type';
import { InputDefault } from '@shared/inputs';

import handleInput from '@utils/handleInput';

export const AccountPersonalDataModalEditPhone: FC<
   AccountPersonalDataModalEditPhoneProps
> = ({ open, setOpen, newPhone }) => {
   const [code, setCode] = useState<string>('');

   const handleModal = () => {
      setOpen(!open);
   };
   const onDelete = () => {
      setOpen(!open);
   };

   return (
      <ModalAction
         onClickAction={onDelete}
         text="На указанный вами номер был отправлен код, введите его ниже, чтобы сменить данные"
         title="Сменить номер телефона"
         open={open}
         onClose={handleModal}
         image={editPhoneImage}
         textClose="Нет, оставить"
         textAction="Сменить номер"
      >
         <InputDefault
            stylesWrapper={{ width: '100%', maxWidth: '475px' }}
            sx={{ width: '100%' }}
            value={code}
            onChange={(e) => handleInput(e, setCode)}
         />
      </ModalAction>
   );
};
