import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { light900, black200 } = colorsUI;

export const accountPersonalDataCardStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   minWidth: '412px',
   height: 'auto',
   [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      gap: '42px',
   },
});

export const accountPersonalDataCardTitleStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   fontWeight: 700,
});

export const accountPersonalDataCardRateStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   fontWeight: 700,
   fontSize: '1.875rem',
   color: light900,
   margin: '16px 0 8px 0',
});

export const accountPersonalDataCardDeteValidStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   color: black200,
});
