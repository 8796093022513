import { useRuntimeSaveLeadMutation } from 'app/dashboard/store/api/runtimeApi';

import type { IRuntimeResponseData } from '@projectTypes/runtime';
import type { IRuntimeSaveLeadDataRequets } from '@projectTypes/runtime';

export const useSaveLead = () => {
   const [saveLead, { data, isError: isErrorLead }] = useRuntimeSaveLeadMutation();

   const saveLeadData = async (
      dataStart: IRuntimeResponseData,
      dataLocal: IRuntimeSaveLeadDataRequets,
   ) => {
      console.log(dataStart.data.uuid);
      console.log(dataLocal);
      try {
         const result = await saveLead({
            id: dataStart.data.uuid,
            data: dataLocal,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (error) {
         console.error('Ошибка при сохранении лида:', error);
         return null;
      }
   };

   return { saveLeadData, isErrorLead };
};
