import React, { useState, FC } from 'react';

import { ModalAction } from '@shared/modals';

import editPasswordImage from '@assets/images/editPassword.png';

import type { AccountPersonalDataModalEditPasswordProps } from './type';

import { buttonCloseStyles } from './style';

export const AccountPersonalDataModalConfirmPassword: FC<
   AccountPersonalDataModalEditPasswordProps
> = ({ open, setOpen }) => {
   const handleModal = () => {
      setOpen(!open);
   };
   const onDelete = () => {
      setOpen(!open);
   };

   return (
      <>
         <ModalAction
            onClickAction={onDelete}
            text="На вашу почту было отправленно письмо, перейдите по ссылке, чтобы завершить сброс пароля"
            title="Сброс пароля"
            open={open}
            onClose={handleModal}
            image={editPasswordImage}
            textClose="Вернуться назад"
            textAction="Ок"
            sxButtonAction={buttonCloseStyles()}
         />
      </>
   );
};
