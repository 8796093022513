import React, { FC } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import type { RadioDefaultProps } from '../types';

import { radioDefaultStyles, labelRadioDefaultStyles } from '../styles';

const RadioDefault: FC<RadioDefaultProps> = ({
   checked,
   onChange,
   value,
   disable = false,
   label,
   color,
   colorChecked,
   sx,
}) => {
   return (
      <FormControlLabel
         control={
            <Radio
               value={value}
               checked={checked}
               onChange={onChange}
               disabled={disable}
               sx={{
                  ...radioDefaultStyles(disable, color, colorChecked),
                  ...((sx ?? {}) as object),
               }}
            />
         }
         sx={labelRadioDefaultStyles(disable)}
         label={label}
      />
   );
};

export default RadioDefault;
