import React from 'react';

import { ReactComponent as InstructionsIcon } from '@assets/icons/instructionsIcon.svg';
import { Link } from 'react-router-dom';
import { Typography, Link as LinkMui } from '@mui/material';

import { instructionsStyles } from './style';

export const InstructionsItem = () => {
   return (
      <LinkMui component={Link} to="/" sx={instructionsStyles}>
         <InstructionsIcon />
         <Typography variant="body2">Инструкция</Typography>
      </LinkMui>
   );
};
