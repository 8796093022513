import React from 'react';

import { Box, Typography } from '@mui/material';
import { ReactComponent as SettingsIcon } from '@assets/icons/settingsIcon.svg';

import Logo from 'shared/logo';

import { footerStyles, footerTextStyles } from './style';

export const Footer = () => {
   return (
      <Box component={'footer'} sx={footerStyles}>
         <Logo />
         <Typography style={footerTextStyles} variant="body2">
            Нижнее меню
         </Typography>
         <SettingsIcon />
      </Box>
   );
};
