import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material';



export const IconEye: FC<SvgIconProps> = (props) => {

  return (
    <SvgIcon
        fill="none"
      viewBox="0 0 22 16"
      {...props}
    >
      <path
        fill="none"

        d="M1.42012 8.71318C1.28394 8.49754 1.21584 8.38972 1.17772 8.22342C1.14909 8.0985 1.14909 7.9015 1.17772 7.77658C1.21584 7.61028 1.28394 7.50246 1.42012 7.28682C2.54553 5.50484 5.8954 1 11.0004 1C16.1054 1 19.4553 5.50484 20.5807 7.28682C20.7169 7.50246 20.785 7.61028 20.8231 7.77658C20.8517 7.9015 20.8517 8.0985 20.8231 8.22342C20.785 8.38972 20.7169 8.49754 20.5807 8.71318C19.4553 10.4952 16.1054 15 11.0004 15C5.8954 15 2.54553 10.4952 1.42012 8.71318Z"
        strokeWidth="2"
      
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0004 11C12.6573 11 14.0004 9.65685 14.0004 8C14.0004 6.34315 12.6573 5 11.0004 5C9.34355 5 8.0004 6.34315 8.0004 8C8.0004 9.65685 9.34355 11 11.0004 11Z"
        strokeWidth="2"
        fill="none"

        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
