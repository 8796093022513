import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { dangerBg } = colorsUI;

export const visualScriptQuizSuccsessStyles: CSSProperties = {};
export const visualScriptQuizNegativeStyles: CSSProperties = {
   background: dangerBg,
};
