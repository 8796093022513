import React, { FC } from 'react';

import { Box } from '@mui/material';

import type { ErrorMessageProps } from '../types';

import { errorMessageStyles } from '../styles';
import { Typography } from '@mui/material';

export const ErrorMessage: FC<ErrorMessageProps> = ({
   message,
   stylesErrorMessage,
   stylesInner,
   sxErrorMessage,
   sxInner,
}) => {
   return (
      <Box sx={{ ...stylesInner, ...((sxInner ?? {}) as object) }}>
         <Typography
            sx={{
               ...stylesErrorMessage,
               ...errorMessageStyles,
               ...((sxErrorMessage ?? {}) as object),
            }}
         >
            {message}
         </Typography>
      </Box>
   );
};
