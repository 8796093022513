import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { light500, light900, transperent } = colorsUI;

export const accountPersonalDataFormStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
   maxWidth: '408px',
   [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
   },
});

export const accountPersonalDataFormTextfieldStyles: CSSProperties = {
   width: '100%',
};

export const accountPersonalDataFormItemStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',

   gap: '24px',
});

export const accountPersonalDataFormItemButtonStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   background: transperent,
   color: light900,
});

export const accountPersonalDataFormButtonResetPasswordStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   background: transperent,
   color: light900,
   border: `1px solid ${light500}`,
});
