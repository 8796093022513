import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

export const buttonBoxFooterStyles = (theme: Theme): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '8px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
   },
});

export const buttonLabelStyles: CSSProperties = {
   fontSize: '1rem',
};

export const buttonsaveBoxStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   justifyContent: 'start',
};

export const buttonsaveStyles = (theme: Theme): SxProps<Theme> => ({
   [theme.breakpoints.down('md')]: {
      width: '100%',
   },
});
