import { SxProps, Theme } from '@mui/material';
import { colorsUI } from 'constants/colorsUI';

const { light500, black200, black400, black600 } = colorsUI;

export const radioDefaultStyles = (
   disable: boolean = false,
   color: string = black600,
   colorChecked: string = light500,
): SxProps<Theme> => ({
   color: color,
   padding: 0,
   '&.Mui-checked': {
      color: colorChecked,
   },
   '&.Mui-checked path': {
      fill: disable ? black200 : colorChecked,
   },
   '&:not(.Mui-checked) path': {
      stroke: disable ? black200 : color,
      fill: disable ? black200 : color,
      strokeWidth: '.1px',
   },
});

export const labelRadioDefaultStyles = (disable: boolean): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   margin: 0,
   color: disable ? black400 : black600,
   fontSize: '0.875rem',
   fontWeight: 400,
   lineHeight: '20px',
});
