import { CSSProperties } from 'react';
import { ButtonIconStylesParams } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { transperent, disabledBackground, black400 } = colorsUI;

export const buttonIconStyles = ({
   disabled,
   colorIcon,
   colorHoverIcon,
}: ButtonIconStylesParams) => ({
   display: 'inline-block',
   minWidth: 'fit-content',
   maxWidth: 'fit-content',
   pointerEvents: disabled ? 'none' : 'auto',
   transition: ' all .5s',
   backgroundColor: disabled ? disabledBackground : transperent,
   color: colorIcon ? colorIcon : black400,
   padding: '3px',
   ...(!disabled && {
      '&:hover': {
         color: colorHoverIcon && colorHoverIcon,
      },
   }),
});
