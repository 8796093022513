import React from 'react';

import { TabDefault } from '@components/tabs';
import { AccountPersonalData } from '../accountPersonalData';
import { AccountProjects } from '../accountProjects';
import { AccountClients } from '../accountClients';
import { AccountStatistics } from '../accountStatistics';
import { AccountReplenishmentBalance } from '../accountReplenishmentBalance';
import { AccountBalance } from '../accountBalance';

export const AccountData = () => {
   const tabsData = [
      <AccountPersonalData />,
      <AccountProjects />,
      <AccountClients />,
      <AccountStatistics />,
      <AccountReplenishmentBalance />,
      <AccountBalance />,
   ];
   const labels = [
      'Личные данные',
      'Проекты',
      'Клиенты',
      'Статистика',
      'Пополнение баланса',
      'Баланс',
   ];

   return <TabDefault content={tabsData} labels={labels} />;
};
