import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { ModalDefault } from '../modalDefault';

import { ButtonDefault } from 'shared/buttons';
import { Box, Typography } from '@mui/material';

import type { ModalActionProps } from '../types';

import {
   modalDeleteStyles,
   modalDeleteContentStyles,
   imageStyles,
   titleStyles,
   textStyles,
   modalActionBoxButtonStyles,
   modalActionBoxButtonCloseStyles,
   modalActionBoxButtonActionStyles,
} from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light, light900, transperent, danger, dangerActive } = colorsUI;

export const ModalAction: FC<ModalActionProps> = ({
   onClose,
   onClickAction,
   open,
   text,
   title,
   sxButtonAction,
   sxButtonCanel,
   sxImage,
   sxText,
   sxTitle,
   children,
   image,
   alt,
   textAction = 'Действие',
   textClose = 'Нет',
   disableButtonAction = false,
}) => {
   const { theme } = useTheme();

   return (
      <ModalDefault
         stylesModalContent={modalDeleteStyles(theme) as object}
         open={open}
         handleClose={onClose}
      >
         <Box sx={modalDeleteContentStyles}>
            {image && (
               <Box
                  component="img"
                  sx={{ ...imageStyles(theme), ...((sxImage ?? {}) as object) }}
                  src={image}
                  alt={alt}
               />
            )}

            {title && (
               <Typography
                  sx={{ ...titleStyles({ theme }), ...((sxTitle ?? {}) as object) }}
               >
                  {title}
               </Typography>
            )}
            {text && (
               <Typography sx={{ ...textStyles, ...(sxTitle ?? {}) }} variant="body1">
                  {text}
               </Typography>
            )}
            {children}
            <Box sx={modalActionBoxButtonStyles({ theme })}>
               <ButtonDefault
                  sx={{
                     ...(modalActionBoxButtonCloseStyles({ theme }) as object),
                     ...(sxButtonCanel ?? {}),
                  }}
                  backgroundColor={transperent}
                  backgroundColorHover={light900}
                  color={light900}
                  colorHover={light}
                  label={textClose}
                  onClick={onClose}
               />
               {onClickAction && (
                  <ButtonDefault
                     sx={{
                        ...(modalActionBoxButtonActionStyles({ theme }) as object),
                        ...(sxButtonAction ?? {}),
                     }}
                     backgroundColor={transperent}
                     backgroundColorHover={light900}
                     color={light900}
                     colorHover={light}
                     label={textAction}
                     onClick={onClickAction}
                     disabled={disableButtonAction}
                  />
               )}
            </Box>
         </Box>
      </ModalDefault>
   );
};
