import { IRuntimeResponseData } from '@projectTypes/runtime';
import { VisualScriptChatMessageType } from '../../types';
import type { handleReactionClickParams } from './type';

export const handleReactionClick = async ({
   reaction,
   runtimeData,
   triggerClickCell,
   setMessages,
   setUserHasReplied,
   setReactionsButton,
   setIsEnd,
   userHasReplied,
   setExodus,
}: handleReactionClickParams) => {
   const { id: reactionId, properties, type } = reaction;

   const res = (await triggerClickCell({
      uuid: runtimeData.data.uuid,
      cellId: reactionId,
   }).unwrap()) as IRuntimeResponseData;

   console.log(reaction);
   console.log(res);

   const { data } = res;
   console.log(Boolean(!data.layer.node));
   if (data.layer.node) {
      const id = data.layer.node.id;

      const textUser = properties.text;

      const text = data.layer.node.properties.text;
      const newReactions = data.layer.reactions;

      if (!userHasReplied) {
         setMessages((prevMessages: VisualScriptChatMessageType[]) => [
            ...prevMessages,
            { id: reactionId, type: 'user', text: textUser },
         ]);
         setReactionsButton(newReactions);
         setUserHasReplied(true);
      }

      setMessages((prevMessages: VisualScriptChatMessageType[]) => [
         ...prevMessages,
         { id: id, type: 'manager', text: text },
      ]);
      setReactionsButton(newReactions);
      setUserHasReplied(false);
   } else {
      const exodus = properties.exodus;
      console.log(exodus);
      setExodus(exodus);
      setIsEnd(true);
   }
};
