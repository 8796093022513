import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { light500, light900 } = colorsUI;

export const buttonCloseStyles = (): SxProps<Theme> => ({
   background: light500,
   '&:hover': {
      background: light900,
   },
});
