import { useProfileDeleteMutation } from 'app/dashboard/store/api/profileApi';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProfileDelete = () => {
   const [deleteProfile, { isError, error, isLoading, isSuccess }] =
      useProfileDeleteMutation();

   const deleteProfileData = async () => {
      try {
         const result = await deleteProfile({}).unwrap();
         console.log('Ответ от сервера:', result);

         return result;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      deleteProfileData,
      isError,
      error: error as IErrorResponse,
      isLoading,
      isSuccess,
   };
};
