import { useProfileChangeEmailMutation } from 'app/dashboard/store/api/profileApi';

import type { IChangeEmailRequest } from '@projectTypes/profile/changeEmailTypes';

export const useProfileChangeEmail = () => {
   const [changeEmail, { data, isError, error, isLoading, isSuccess }] =
      useProfileChangeEmailMutation();

   const changeEmailData = async (data: IChangeEmailRequest) => {
      console.log(data);
      try {
         const result = await changeEmail({
            data,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         console.log(data);
         return result;
      } catch (error) {
         console.error('Ошибка:', error);
         throw new Error(error as string);
      }
   };

   return { changeEmailData, isError, data, error, isLoading, isSuccess };
};
