import React, { useState } from 'react';
import { useTheme } from '@hooks/useTheme';

import { ColumnDashboard } from 'shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';

import { ButtonAction } from 'shared/buttons';
import { ModalDefault } from 'shared/modals';
import { MobileBoxButton } from './components/mobileBoxButton';
import { FormCreateProjects } from './components';
import { Box, useMediaQuery } from '@mui/material';

import { ReactComponent as IconCopy } from '@assets/icons/copy.svg';
import { ReactComponent as IconSave } from '@assets/icons/save.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';
import { ReactComponent as MoreOptionsIcon } from '@assets/icons/more-options-icon.svg';

import {
   modalStyles,
   headerBoxStyles,
   buttonSaveStyles,
   headerBoxFormStyles,
   headerBoxModileStyles,
} from './style';
import { colorsUI } from 'constants/colorsUI';

const { transperent, black500, black600, success, successActive, errorColor } = colorsUI;

export const CreateProjects = () => {
   const [openModal, setOpenModal] = useState(false);

   const { theme } = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

   const hanldeModal = () => {
      setOpenModal(!openModal);
   };

   const onClickCopy = () => {
      alert('copy!');
      hanldeModal();
   };
   const onClickSave = () => {
      alert('save');
      hanldeModal();
   };
   const onClickDelete = () => {
      alert('delete!');
      hanldeModal();
   };

   return (
      <ColumnDashboard>
         <ModalDefault
            stylesModalContent={modalStyles}
            open={openModal}
            handleClose={hanldeModal}
         >
            <MobileBoxButton />
         </ModalDefault>
         <ProfileHeader styles={headerBoxModileStyles as object} title="Создание проекта">
            {isMobile ? (
               <MoreOptionsIcon onClick={hanldeModal} />
            ) : (
               <Box sx={headerBoxStyles}>
                  <ButtonAction
                     color={black500}
                     colorHover={black600}
                     backgroundColor={transperent}
                     backgroundColorHover={transperent}
                     onClick={onClickCopy}
                     label="копировать"
                     Icon={IconCopy}
                     iconStyles={{ fill: 'none' }}
                     sx={buttonSaveStyles}
                  />
                  <ButtonAction
                     backgroundColor={success}
                     backgroundColorHover={successActive}
                     onClick={onClickSave}
                     iconStyles={{ fill: 'none' }}
                     label="сохранить"
                     Icon={IconSave}
                  />
                  <ButtonAction
                     backgroundColor={errorColor}
                     backgroundColorHover={errorColor}
                     onClick={onClickDelete}
                     iconStyles={{ fill: 'none' }}
                     label="Удалить проект"
                     Icon={IconDelete}
                  />
               </Box>
            )}
         </ProfileHeader>
         <Box sx={headerBoxFormStyles}>
            <FormCreateProjects />
         </Box>
      </ColumnDashboard>
   );
};
