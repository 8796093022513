import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { IProjectCustomFields, IProjectResponse } from '@projectTypes/projects';
import type { IProjectCreate } from '@projectTypes/projects/projectTypes';

import {
   apiDashboard,
   apiDashboardProject,
   apiDashboardProjectCustomFields,
} from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

console.log(baseUrl);

export const projectApi = createApi({
   reducerPath: 'projectApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      project: builder.query<IProjectResponse, {}>({
         query: () => ({
            url: apiDashboardProject as string,
         }),
      }),
      projectCustomFields: builder.query<IProjectCustomFields, { id?: number }>({
         query: ({ id }) => ({
            url: `${apiDashboardProjectCustomFields}${id ? `?project_id=${id}` : ''}`,
         }),
      }),
      addProject: builder.mutation<void, IProjectCreate>({
         query: (newProject) => ({
            url: apiDashboardProject as string,
            method: 'POST',
            body: newProject,
         }),
      }),
      deleteProject: builder.mutation<void, { slug: string }>({
         query: ({ slug }) => ({
            url: `${apiDashboardProject}/${slug}`,
            method: 'DELETE',
         }),
      }),
   }),
});

export const {
   useProjectQuery,
   useProjectCustomFieldsQuery,
   useAddProjectMutation,
   useDeleteProjectMutation,
} = projectApi;
