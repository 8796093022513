import { Dispatch, SetStateAction } from 'react';

type OnHandleIsActiveParams = {
   setIsActive: Dispatch<SetStateAction<boolean>>;
   setIsOpenTooltip: Dispatch<SetStateAction<boolean>>;
   isOpenTooltip: boolean;
};
export const onHandleIsActive = ({
   isOpenTooltip,
   setIsActive,
   setIsOpenTooltip,
}: OnHandleIsActiveParams) => {
   setIsOpenTooltip(!isOpenTooltip);
   setIsActive(true);
};
