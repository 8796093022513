import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Tab, Tabs, useMediaQuery } from '@mui/material';

import { allyProps } from '../../utils';

import { tabsHeaderSmoothTabStyles, tabsHeaderSmoothStyles } from './style';

import type { TabsHeaderProps } from '@components/tabs/types';

export const TabsHeaderSmooth: FC<TabsHeaderProps> = ({ labels, onChange, value }) => {
   const { theme } = useTheme();

   const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md'));

   return (
      <Tabs
         selectionFollowsFocus
         variant={isMiddleScreen ? 'scrollable' : 'scrollable'}
         sx={tabsHeaderSmoothStyles({ theme })}
         value={value}
         scrollButtons={false}
         onChange={onChange}
         aria-label="таб"
         TabIndicatorProps={{ style: { display: 'none' } }}
      >
         {labels.map((label, index) => (
            <Tab
               sx={tabsHeaderSmoothTabStyles({ theme })}
               key={index}
               label={label}
               {...allyProps(index)}
            />
         ))}
      </Tabs>
   );
};
