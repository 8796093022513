import { OutlinedInput } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/material/styles';
import { colorsUI } from 'constants/colorsUI';

const {
   textFieldBorderColor,
   textFieldBorderColorHover,
   placeholderColor,
   light800,
   black500,
   black50,
   black100,
   errorColor,
} = colorsUI;

export const CustomOutlinedInput = styled(OutlinedInput)(() => ({
   '& ': {
      width: '100%',
      transition: 'all .5s',
      borderRadius: '4px',
      border: `1px solid ${textFieldBorderColor}`,
      fontSize: '1rem',
      color: black500,
   },

   '&:hover ': {
      borderColor: textFieldBorderColorHover,
   },
   '.MuiInputBase-input': {
      padding: '10px 16px',
      // Default placeholder color
      '&::placeholder': {
         color: placeholderColor, // Default placeholder color
      },
   },

   '&.Mui-disabled': {
      backgroundColor: black50,
      color: black100,
      borderColor: black100,
      pointerEvents: 'none',
   },
   '&.Mui-focused': {
      border: `1px solid ${light800}`,
   },
   '&.Mui-error': {
      border: `1px solid ${errorColor}`,
      color: errorColor,

      '.MuiInputBase-input::placeholder': {
         color: 'red',
      },
   },
}));
