import type { ConfirmCodeParams } from './type';

import { confirmCodeErrorMessages } from '@errors/profile/confirmCodeErrors';

export const confirmCode = async ({ code, confirmCodeData }: ConfirmCodeParams) => {
   try {
      await confirmCodeData({ code });
      return true;
   } catch (err) {
      console.error(err);

      throw new Error(err as string);
   }
};
