import React from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnCentered } from '@shared/column';

import imageNoneLogs from '@assets/images/logs.png';

import { LogsNoneDataImageStyles } from './style';

export const LogsNoneData = () => {
   return (
      <ColumnCentered>
         <Box
            component="img"
            sx={LogsNoneDataImageStyles()}
            src={imageNoneLogs}
            alt="нет логов работы скриптов"
         />
         <Typography variant="body1">Тут пока нет логов работы скриптов</Typography>
      </ColumnCentered>
   );
};
