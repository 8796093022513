import React, { FormEvent, useState } from 'react';

import { Box } from '@mui/material';
import { FormDefault } from '@components/forms';
import { InputPassword } from '@shared/inputs';
import { ButtonAction } from '@shared/buttons';

import { ReactComponent as IconDelete } from '@assets/icons/delete.svg';

import handleInput from '@utils/handleInput';
import clearState from '@utils/clearState';

import { buttonDeleteStyles } from './style';

import { colorsUI } from '@constants/colorsUI';
import { useTheme } from '@hooks/useTheme';

const { danger, transperent } = colorsUI;

export const AccountReplenishmentBalanceForm = () => {
   const [value, setValue] = useState('');

   const { theme } = useTheme();

   const onSubmit = (e: FormEvent) => {
      alert('submit');
   };

   return (
      <FormDefault onSubmit={onSubmit}>
         <InputPassword
            placeholder="XXXX-XXXX-XXXX-XX12"
            value={value}
            onChange={(e) => handleInput(e, setValue)}
         />
         <ButtonAction
            sx={{ ...buttonDeleteStyles() }}
            onClick={() => clearState({ setValue, clearValue: '' })}
         >
            <IconDelete />
         </ButtonAction>
      </FormDefault>
   );
};
