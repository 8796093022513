import type { ConvertToCamelCaseParams } from './type';

export const convertToCamelCase = ({
   text,
   separator = '_',
}: ConvertToCamelCaseParams): string => {
   return text
      .split(separator)
      .map((word, index) =>
         index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      .join('');
};
