import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black500 } = colorsUI;

export const listItemLinkStyles: CSSProperties = {
   textDecoration: 'underline',
   fontSize: '1.125rem',
   lineHeight: '1.5rem',
   fontWeight: 400,
   color: black500,
};
