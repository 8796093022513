import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
   apiDashboardRuntime,
   apiRuntimeStart,
   apiRuntimeClickCell,
   apiRuntimeSaveLead,
} from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const runtimeApi = createApi({
   reducerPath: 'runtimeApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboardRuntime}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      runtimeStart: builder.query({
         query: ({ slug, data }) => ({
            url: `${apiRuntimeStart}/${slug}` as string,
            method: 'POST',
            body: {
               lead_id: 1, // пока так ждем пока дадут инфу откуда брать
            },
         }),
      }),
      runtimeClickCell: builder.mutation({
         query: ({ uuid, cellId }) => ({
            url: `${uuid}${apiRuntimeClickCell}/${cellId}`,
            method: 'POST',
         }),
      }),
      runtimeSaveLead: builder.mutation({
         query: ({ id, data }) => ({
            url: `${id}${apiRuntimeSaveLead}`,
            method: 'POST',
            body: data,
         }),
      }),
   }),
});

export const {
   useRuntimeClickCellMutation,
   useRuntimeStartQuery,
   useRuntimeSaveLeadMutation,
} = runtimeApi;
