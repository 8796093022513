import React, { FC } from 'react';

import type { ListAccordionProps } from '../types';
import { Typography } from '@mui/material';

export const ListAccordion: FC<ListAccordionProps> = ({
   items,
   open,
   setOpen,
   styles,
}) => {
   const onClick = () => {
      setOpen(!open);
   };

   return (
      <>
         {items.map((el, i) => (
            <Typography key={i}>{el}</Typography>
         ))}
      </>
   );
};
