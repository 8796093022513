import React from 'react';

import { useTheme } from '@hooks/useTheme';

import { LinkDefault } from '@shared/links';
import { NotData } from '@componentsDashBoard/notData';

import {
   projectsNoneImageStyles,
   projectsNoneButtonStyles,
   projectsNoneStyles,
} from './style';

import imgPathIsNoneProjects from '@assets/images/isNoneProjects.png';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

export const ProjectsNone = () => {
   const { theme } = useTheme();

   return (
      <NotData sx={{ ...projectsNoneStyles(theme) }}>
         <img
            style={projectsNoneImageStyles}
            src={imgPathIsNoneProjects}
            alt="у вас нет проектов"
         />
         <LinkDefault
            href="/projects/create-projects"
            sx={projectsNoneButtonStyles as object}
            Icon={PlusIcon}
            label="Создать проект"
         />
      </NotData>
   );
};
