import React, { useState } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box, Typography } from '@mui/material';
import RadioGroup from '@shared/radioGroup';
import { AccountReplenishmentBalanceForm } from './components';

import {
   acountReplenishmentBalanceStyles,
   acountReplenishmentBalanceTitleStyles,
} from './style';

export const AccountReplenishmentBalance = () => {
   const [selectedOption, setSelectedOption] = useState<string>('');

   const { theme } = useTheme();

   const handleRadioChange = (value: string) => {
      setSelectedOption(value);
      console.log('Selected value:', value);
   };

   const radioOptions = [
      { value: 'По СПБ', label: 'По СПБ' },
      { value: 'По номеру карты', label: 'По номеру карты' },
      { value: 'Через электронный кошелек', label: 'Через электронный кошелек' },
   ];

   return (
      <Box sx={{ ...acountReplenishmentBalanceStyles({ theme }) }}>
         <Typography
            component="h2"
            variant="subtitle1"
            sx={{ ...acountReplenishmentBalanceTitleStyles({ theme }) }}
         >
            Выберите способ пополнения:
         </Typography>
         <RadioGroup
            options={radioOptions}
            defaultValue="option1"
            onChange={handleRadioChange}
            name="ВыберитеCпособПополнения"
         />
         <AccountReplenishmentBalanceForm />
      </Box>
   );
};
