import React, { CSSProperties, FC, Fragment } from 'react';
import { Link as LinkMUI, SxProps, Typography, Theme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { SvgInner } from 'shared/svgInner';

import type { NavigationItemProps } from '../types';
import { navigationItemIconStyles, iconStyles, CustomStyledNavLink } from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { black500, light600, light900 } = colorsUI;

export const NavigationItemIcon: FC<NavigationItemProps> = ({
   label,
   path,
   Icon,
   styles,
   active = false,
   colorActive = light600,
   color = black500,
   colorHover = light900,
   disable = false,
   direction = 'row',
}) => {
   const linkStyles = {
      ...navigationItemIconStyles({
         disabled: disable,
         isActived: active,
         colorActive,
         color,
         colorHover,
      }),
      ...styles,
   };

   return (
      <CustomStyledNavLink to={path} style={{ flexDirection: direction }} sx={linkStyles}>
         {Icon && <Icon sx={{ fill: active ? colorActive : color }} />}
         <Typography>{label}</Typography>
      </CustomStyledNavLink>
   );
};
