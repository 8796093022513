import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ActionProjectItemProps } from './type';

import { actionProjectItemStyles, actionProjectItemTypographyStyles } from './style';

export const ActionProjectItem: FC<ActionProjectItemProps> = ({
   countClients,
   countProjects,
}) => {
   return (
      <Box sx={actionProjectItemStyles}>
         <Typography sx={actionProjectItemTypographyStyles}>{countClients}</Typography>
         <Typography sx={actionProjectItemTypographyStyles}>{countProjects}</Typography>
         <Typography sx={actionProjectItemTypographyStyles}>
            {countClients / countProjects}%
         </Typography>
      </Box>
   );
};
