import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '@hooks/useTheme';

import type { ListItemWithIconProps } from '../types';
import { ListItem, Typography } from '@mui/material';

import { listItemWithIconStyles } from './styles';

export const ListItemWithIcon: FC<ListItemWithIconProps> = ({
   Icon,
   label,
   href,
   onClick,
   positon = 'before',
   variantTypography = 'body1',
   stylesInner,
   stylesIcon,
   stylesLabel,
   disable = false,
   error = false,
}) => {
   const { theme } = useTheme();

   return (
      <>
         {href ? (
            <ListItem
               component={Link}
               to={href}
               onClick={onClick}
               sx={{
                  ...listItemWithIconStyles(theme, disable, error),
                  ...(stylesInner && typeof stylesInner === 'object' ? stylesInner : {}),
               }}
            >
               {positon === 'before' && <Icon style={stylesIcon} />}
               <Typography variant={variantTypography} sx={stylesLabel}>
                  {label}
               </Typography>
               {positon === 'after' && <Icon style={stylesIcon} />}
            </ListItem>
         ) : (
            <ListItem
               onClick={onClick}
               sx={{
                  ...listItemWithIconStyles(theme, disable, error),
                  ...(stylesInner && typeof stylesInner === 'object' ? stylesInner : {}),
               }}
            >
               {positon === 'before' && <Icon style={stylesIcon} />}
               <Typography variant={variantTypography} sx={stylesLabel}>
                  {label}
               </Typography>
               {positon === 'after' && <Icon style={stylesIcon} />}
            </ListItem>
         )}
      </>
   );
};
