import React, { FC } from 'react';

import { CenteredContainer } from '@shared/containers';
import { Box } from '@mui/material';

import { visualScriptContainerStyles, visualScriptContainerInnerStyles } from './style';

import type { VisualScriptContainerProps } from './type';

export const VisualScriptContainer: FC<VisualScriptContainerProps> = ({ children }) => {
   return (
      <CenteredContainer styles={visualScriptContainerStyles}>
         <Box sx={visualScriptContainerInnerStyles}>{children}</Box>
      </CenteredContainer>
   );
};
