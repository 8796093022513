import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiDashboardTree, apiDashboard } from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const treeApi = createApi({
   reducerPath: 'treeApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      tree: builder.query({
         query: ({ slug }) => ({
            url: `${apiDashboardTree}/${slug}` as string,
         }),
      }),
   }),
});

export const { useTreeQuery } = treeApi;
