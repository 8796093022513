import {
   validateMinLength,
   areStringsEqual,
   areStringsNotEqual,
} from '@utils/validation';

import type { ValidatePasswordChangeParams } from './type';
import type { IErrorsChangePassword } from '../../types/changePasswordTypes';

import { changePasswordErrorMessages } from '@errors/profile/changePasswordErrors';
import { minLengthPasswordError } from '@errors/general/minLengthPassword';

export const validatePasswordChange = ({
   currentPassword,
   newPassword,
   confirmNewPassword,
   setErrors,
}: ValidatePasswordChangeParams): string[] => {
   const errors: IErrorsChangePassword = {};

   const { incorrectPassword, newPasswordSameAsOld, passwordsDoNotMatch } =
      changePasswordErrorMessages;

   const isNewPasswordNotEqualToCurrent = areStringsNotEqual(
      currentPassword,
      newPassword,
   );
   const isConfirmNewPassword = areStringsEqual(newPassword, confirmNewPassword);
   const isGreaterMinLength = validateMinLength(newPassword);

   console.log(isConfirmNewPassword);

   if (!isGreaterMinLength) {
      console.log(!isGreaterMinLength);
      errors.minLengthPasswordError = minLengthPasswordError;
   }
   console.log(errors);

   if (!isNewPasswordNotEqualToCurrent) {
      errors.newPasswordSameAsOld = newPasswordSameAsOld;
   }
   if (!isConfirmNewPassword) {
      errors.passwordsDoNotMatch = passwordsDoNotMatch;
   }
   if (!incorrectPassword) {
      errors.incorrectPassword = 'типа не верный пароль';
   }

   setErrors(errors);

   return Object.values(errors);
};
