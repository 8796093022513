import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ButtonAction } from '@shared/buttons';

import { ReactComponent as IconDowload } from '@assets/icons/download.svg';

import { logsHeaderStyles, logsHeaderButtonStyles } from './style';

import type { LogsHeaderProps } from './type';

export const LogsHeader: FC<LogsHeaderProps> = ({ disabled }) => {
   const { theme } = useTheme();

   return (
      <ProfileHeader styles={logsHeaderStyles({ theme }) as object} title="Логи">
         <ButtonAction
            sx={logsHeaderButtonStyles({ theme })}
            colorHover=""
            Icon={IconDowload}
            disabled={disabled}
         >
            Выгрузить в CVS
         </ButtonAction>
      </ProfileHeader>
   );
};
