import React, { FC } from 'react';
import { List, ListItem } from '@mui/material';
import { ListItemSelect } from 'shared/listItems';
import { ListSelectProps } from '../types';

export const ListSelect: FC<ListSelectProps> = ({
   items,
   styles,
   select,
   handleSelectChange,
}) => {
   const handleClick = (i: number) => {
      handleSelectChange(i);
   };

   return (
      <List sx={styles}>
         {items.map(({ Icon, label, disable, error }, i) => (
            <ListItemSelect
               variantTypography="body2"
               key={label}
               label={label}
               Icon={Icon}
               onClick={() => handleClick(i)}
               disable={disable}
               error={error}
               selected={select === i}
            />
         ))}
      </List>
   );
};
