import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
   apiDashboard,
   apiDashboardProfile,
   apiDashboardProfileUpdatePhone,
   apiDashboardProfileChangePassword,
   apiDashboardProfileConfirmCode,
   apiDashboardProfileChangeEmail,
} from '@constants/api/endpoints';

const baseUrlDashboard = process.env.REACT_APP_API;

console.log(baseUrlDashboard);

export const profileApi = createApi({
   reducerPath: 'profileApi',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrlDashboard}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      profile: builder.query({
         query: (data) => ({
            url: apiDashboardProfile as string,
         }),
      }),
      profileUpdateFIO: builder.mutation({
         query: ({ data }) => ({
            url: `${apiDashboardProfile}`,
            method: 'PUT',
            body: data,
         }),
      }),
      profileChangePassword: builder.mutation({
         query: ({ data }) => ({
            url: `${apiDashboardProfile}${apiDashboardProfileChangePassword}`,
            method: 'PUT',
            body: data,
         }),
      }),
      profileChangeEmail: builder.mutation({
         query: ({ data }) => ({
            url: `${apiDashboardProfile}${apiDashboardProfileChangeEmail}`,
            method: 'POST',
            body: data,
         }),
      }),
      profileUpdatePhone: builder.mutation({
         query: ({ data }) => ({
            url: `${apiDashboardProfile}${apiDashboardProfileUpdatePhone}`,
            method: 'POST',
            body: data,
         }),
      }),
      profileConfirmCode: builder.mutation({
         query: ({ data }) => ({
            url: `${apiDashboardProfile}${apiDashboardProfileConfirmCode}`,
            method: 'POST',
            body: data,
         }),
      }),
      profileDelete: builder.mutation({
         query: () => ({
            url: `${apiDashboardProfile}`,
            method: 'DELETE',
         }),
      }),
   }),
});

export const {
   useProfileQuery,
   useProfileChangePasswordMutation,
   useProfileChangeEmailMutation,
   useProfileUpdatePhoneMutation,
   useProfileConfirmCodeMutation,
   useProfileUpdateFIOMutation,
   useProfileDeleteMutation,
} = profileApi;
