import {configureStore} from "@reduxjs/toolkit";
import treeSlice from "modules/Constructor/store/TreeSlice";

export const store = configureStore({
  reducer: {
    tree: treeSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
