import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { LinkArrow } from '@shared/links';

import { ReactComponent as ArrowSvg } from '@assets/icons/arrow.svg';

import type { BillboardUserProps } from './type';

import {
   billboardUserStyles,
   billboardUserHeaderTitleStyles,
   billboardUserFooterLinkStyles,
   billboardUserFooterLinkIvonStyles,
} from './style';

import { colorsUI } from '@constants/colorsUI';

const { transperent, light } = colorsUI;

export const BillboardUser: FC<BillboardUserProps> = ({ name, sx }) => {
   return (
      <Box sx={{ ...billboardUserStyles, ...sx }}>
         <Box>
            <Typography variant="body2">Приветствуем,</Typography>
            <Typography
               sx={{ ...billboardUserHeaderTitleStyles }}
               component={'h2'}
               variant="h2"
            >
               {name} 👋🏼
            </Typography>
            <Typography variant="body2">Рады видеть Вас снова</Typography>
         </Box>
         <Box>
            <LinkArrow
               sx={billboardUserFooterLinkStyles}
               href="/"
               label="Аккаунт"
               arrowPosition="after"
               IconSvg={ArrowSvg}
               styleIcon={billboardUserFooterLinkIvonStyles}
               backgroundColor={transperent}
               color={light}
            />
         </Box>
      </Box>
   );
};
