import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { light500 } = colorsUI;

export const buttonHeaderLeaveWithoutSavingStyles: CSSProperties = {
   border: `1px solid ${light500}`,
   padding: '10px 24px',
};
