import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

const { light, light500, black200, black500 } = colorsUI;

export const profileCardActionProjectStyles = {};
export const profileCardLinkStyles: CSSProperties = {
   color: light,
   background: light500,
   padding: '8px 24px',
   fontSize: '1.125rem',
   fontWeight: 700,
   lineHeight: '24px',
};

export const profileCardTitleleStyles: CSSProperties = {
   fontSize: '1.875rem',
};

export const profileCardValideDateStyles: CSSProperties = {
   color: black200,
};

export const profileCardLinkMoreStyles: CSSProperties = {
   color: black500,

   padding: '8px 24px',
   fontSize: '1.125rem',
   fontWeight: 700,
   lineHeight: '24px',
};
