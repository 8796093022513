import { Tooltip, Zoom } from '@mui/material';
import React, { FC } from 'react';

import type { TooltipProps } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { black600, light } = colorsUI;

export const TooltipArrow: FC<TooltipProps> = ({
   children,
   title,
   placement = 'bottom',
   isOpen = true,
}) => {
   return (
      <Tooltip
         TransitionComponent={Zoom}
         title={title}
         arrow
         open={isOpen}
         placement={placement}
         componentsProps={{
            tooltip: {
               sx: {
                  bgcolor: black600,
                  color: light,
                  padding: '8px 12px',
                  '& .MuiTooltip-arrow': {
                     color: black600,
                  },
               },
            },
         }}
      >
         {children}
      </Tooltip>
   );
};
