import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light900, black700 } = colorsUI;

export const cardProfileStyles: CSSProperties = {
   width: '100%',
   height: '100%',

   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'start',
   padding: '24px',
   borderRadius: '8px',
   gap: '16px',
};

export const cardProfileTilteStyles: CSSProperties = {
   color: black700,
   fontWeight: 700,
};

export const cardProfileBodyStyles: SxProps<Theme> = {
   display: 'flex',
   flexDirection: 'column',
   gap: '16px',
   minHeight: '48px',
   '@media (max-width:1460px)': {
      width: '100%',
      justifyContent: 'space-between',
   },
};

export const cardProfileBodyTitleStyles: CSSProperties = {
   color: light900,
   fontWeight: 700,
};

export const cardProfileFooterStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   [theme.breakpoints.down('md')]: {
      width: '100%',
      flexWrap: 'wrap',
      '& > *': {
         width: '100%',
      },
   },
});
