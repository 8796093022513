import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

const { black300 } = colorsUI;

export const labelStyles: CSSProperties = {
   color: black300,
   display: 'block',
   padding: '4px 8px',
};

export const inputStyles: CSSProperties = {
   display: 'flex',
   flexDirection: 'column',
};

export const formCreateProjectsStyles: CSSProperties = {
   width: '100%',
   maxWidth: '758px',
   display: 'flex',
   flexDirection: 'column',
   gap: '16px',
};

export const formBoxButtonStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   gap: '16px',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});
