import React from 'react';

import { useTheme } from '@hooks/useTheme';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import type { TableProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableHeaderCellStyles,
   tableCellStyles,
   tableRowCellStyles,
} from '../styles';

export const TableDefault = <T extends object>({
   columns,
   rows,
   sx,
   sxTableCell,
   sxTableHead,
   sxContainer,
}: TableProps<T>) => {
   const { theme } = useTheme();

   return (
      <TableContainer sx={{ ...sxContainer }} component={Paper}>
         <Table sx={{ ...tableStyles, ...(sx ?? {}) }} aria-label="universal table">
            <TableHead
               sx={{ ...tableHeaderStyles({ theme }), ...(sxTableHead as object) }}
            >
               <TableRow>
                  {columns.map((column) => (
                     <TableCell
                        sx={{
                           ...(tableCellStyles as object),
                        }}
                        key={String(column.id)}
                        align="left"
                     >
                        <Typography
                           sx={{
                              ...tableHeaderCellStyles({ theme }),
                           }}
                           variant="subtitle2"
                        >
                           {column.label}
                        </Typography>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {rows.map((row, index) => (
                  <TableRow key={index}>
                     {columns.map((column) => (
                        <TableCell
                           sx={{ ...tableCellStyles, ...sxTableCell }}
                           key={String(column.id)}
                           align="left"
                        >
                           {column.renderCell ? (
                              <>{column.renderCell(row)}</>
                           ) : (
                              <Typography
                                 sx={{ ...tableRowCellStyles({ theme }) }}
                                 variant="body1"
                              >
                                 {String(row[column.id as keyof T] ?? '-')}
                              </Typography>
                           )}
                        </TableCell>
                     ))}
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
};
