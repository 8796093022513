import React, { useState } from 'react';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ColumnDashboard } from '@shared/column';
import { ButtonDefault } from '@shared/buttons';
import { ClientsNoneData } from '../clientsNoneData';
import { ModalClientAdd } from '../components/modalClientAdd';
import { ClientsData } from '../clientsData';

import { clientsHeaderBoxStyles, clientsHeaderButtonStyles } from './styles';

import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';

export const Clients = () => {
   const [openModalClientAdd, setOpenModalClientAdd] = useState(false);

   const handleCloseModalClientAdd = () => {
      setOpenModalClientAdd(false);
   };

   const handleOpenModalClientAdd = () => {
      setOpenModalClientAdd(true);
   };

   return (
      <ColumnDashboard>
         <ModalClientAdd open={openModalClientAdd} onClose={handleCloseModalClientAdd} />
         <ProfileHeader styles={clientsHeaderBoxStyles as object} title="Мои клиенты">
            <ButtonDefault
               onClick={handleOpenModalClientAdd}
               startIcon={<IconPlus />}
               label=""
               sx={clientsHeaderButtonStyles() as object}
            />
         </ProfileHeader>
         <ClientsData />
      </ColumnDashboard>
   );
};
