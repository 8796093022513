import { CSSProperties } from 'react';
import { Theme, SxProps } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light, light500, light900, black200, black300, black600, transperent } = colorsUI;

export const modalStyles: CSSProperties = {
   maxWidth: '678px',
   maxHeight: '90vh',
   overflowY: 'auto',
};

export const modalClientsFiltersStyles: CSSProperties = {
   alignItems: 'center',
   width: '100%',
   position: 'relative',
   overflow: 'auto',
};

export const modalClientsFiltersInputBox: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '16px',
};

export const modalClientsFiltersInputsTitle = (theme: Theme): SxProps<Theme> => ({
   fontSize: '1.875rem',
   color: black600,
   textTransform: 'uppercase',
   [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
   },
});

export const modalClientsFiltersButtonStyles = (): SxProps<Theme> => ({
   width: '100%',
});

export const modalClientsFiltersButtonCanelStyles = (): SxProps<Theme> => ({
   background: transperent,
   color: light900,
   border: `1px solid ${light500}`,
   '&:hover': {
      color: light,
      background: light500,
   },
});

export const modalClientsFiltersImgStyles: CSSProperties = {
   width: '100%',
   maxWidth: '161px',
   height: '132px',
};

export const modalClientsFiltersSelectStyles: CSSProperties = {
   width: '100%',
};

export const modalClientsFiltersInputStyles: CSSProperties = {
   gap: '4px',
   display: 'flex',
   flexDirection: 'column',
};

export const modalClientsFiltersLabelStyles: CSSProperties = {
   color: black300,
};

export const modalClientsFiltersButtonBoxStyles = (theme: Theme): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'stretch',
   gap: '24px',
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '8px',
   },
});

export const modalClientAddCloseIconStyles = (): SxProps<Theme> => ({
   position: 'absolute',
   right: '15px',
   top: '49pxpx',
   color: black200,
   transition: 'all .5s',
   cursor: 'pointer',

   '&:hover': {
      color: black300,
   },
});
