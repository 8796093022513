import { ChangeEvent, Dispatch, SetStateAction } from 'react';

const handleInput = <T>(
   e: ChangeEvent<HTMLInputElement>,
   setValue: Dispatch<SetStateAction<T>>,
) => {
   setValue(e.target.value as T);
};

export default handleInput;
