import React, {
   createContext,
   FC,
   useState,
   useRef,
   useEffect,
   DragEvent,
   SetStateAction,
   Dispatch,
} from 'react';
import { Graph } from '@antv/x6';
import { Wrapper, Field, Blocks, BlockDrag, colors } from './tree.css';
import { IBlock, BlockSelect } from 'interfaces/constructor/block';
import { blockTypes } from '../../constant/blockArr';
import EditModal from '../EditModal/EditModal';

interface IContextTree {
   blocks: IBlock[];
   editBlock: Dispatch<SetStateAction<IBlock[]>>;
   openModal: Dispatch<SetStateAction<boolean>>;
   currentBlock: IBlock | null;
   handleEditBlock: (block: IBlock) => void;
   deleteBlock: (id: number) => void;
}

interface IEdge {
   id: string;
   source: string;
   target: string;
}

interface TreeProps {
   slug: string;
}

export const ContextTree = createContext<IContextTree>({
   blocks: [],
   editBlock: () => {},
   openModal: () => {},
   currentBlock: null,
   handleEditBlock: () => {},
   deleteBlock: () => {},
});

const Tree: FC<TreeProps> = ({ slug }) => {
   const [blocks, setBlocks] = useState<IBlock[]>([]);
   const [edges, setEdges] = useState<IEdge[]>([]);
   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
   const containerRef = useRef<HTMLDivElement>(null);
   const graphRef = useRef<Graph>();
   const [currentBlock, setCurrentBlock] = useState<IBlock | null>(null);

   const saveBlocksToLocalStorage = (slug: string, blocks: IBlock[]) => {
      localStorage.setItem(`blocks_${slug}`, JSON.stringify(blocks));
   };

   const loadBlocksFromLocalStorage = (slug: string) => {
      const storedBlocks = localStorage.getItem(`blocks_${slug}`);
      if (storedBlocks) {
         const parsedBlocks: IBlock[] = JSON.parse(storedBlocks);
         setBlocks(parsedBlocks);
         parsedBlocks.forEach((block) => {
            if (graphRef.current) {
               graphRef.current.addNode({
                  id: String(block.id),
                  x: block.x,
                  y: block.y,
                  width: 220,
                  height: 60,
                  label: block.title,
                  data: block,
                  attrs: {
                     body: {
                        fill: block.color,
                        stroke: '#FF910F',
                        overflow: 'hidden',
                        strokeWidth: 1,
                        rx: 8,
                        ry: 8,
                     },
                     label: {
                        text: block.title,
                        fill: '#333',
                        fontSize: 14,
                        fontWeight: '400',
                        textWrapMode: 'wrap',
                     },
                  },
                  ports: {
                     items: [
                        { id: 'in', group: 'in' },
                        { id: 'out', group: 'out' },
                     ],
                     groups: {
                        in: {
                           position: 'top',
                           attrs: {
                              circle: {
                                 r: 6,
                                 magnet: 'passive',
                                 stroke: '#FF910F',
                                 strokeWidth: 2,
                                 fill: '#fff',
                              },
                           },
                        },
                        out: {
                           position: 'bottom',
                           attrs: {
                              circle: {
                                 r: 6,
                                 magnet: true,
                                 stroke: '#FF910F',
                                 strokeWidth: 2,
                                 fill: '#fff',
                              },
                           },
                        },
                     },
                  },
               });
            }
         });
      }
   };

   const saveEdgesToLocalStorage = (slug: string, edges: IEdge[]) => {
      localStorage.setItem(`edges_${slug}`, JSON.stringify(edges));
   };

   const loadEdgesFromLocalStorage = (slug: string) => {
      const storedEdges = localStorage.getItem(`edges_${slug}`);
      if (storedEdges) {
         const parsedEdges: IEdge[] = JSON.parse(storedEdges);
         setEdges(parsedEdges);
         parsedEdges.forEach((edge) => {
            if (graphRef.current) {
               graphRef.current.addEdge({
                  id: edge.id,
                  source: edge.source,
                  target: edge.target,
                  attrs: {
                     line: {
                        stroke: '#FF910F',
                        strokeWidth: 2,
                        targetMarker: { name: 'block', args: { size: 6 } },
                     },
                  },
               });
            }
         });
      }
   };

   const handleEditBlock = (block: IBlock) => {
      setCurrentBlock(block);
      setIsModalOpen(true);
   };

   const deleteBlock = (id: number) => {
      setBlocks((prevBlocks) => {
         const updatedBlocks = prevBlocks.filter((block) => block.id !== id);
         saveBlocksToLocalStorage(slug, updatedBlocks);
         return updatedBlocks;
      });
      graphRef.current?.getCellById(id.toString())?.remove();
      setIsModalOpen(false);
   };

   const saveBlockChanges = (updatedBlock: IBlock) => {
      setBlocks((prevBlocks) => {
         const updatedBlocks = prevBlocks.map((block) =>
            block.id === updatedBlock.id ? updatedBlock : block,
         );
         saveBlocksToLocalStorage(slug, updatedBlocks);
         return updatedBlocks;
      });

      const node = graphRef.current?.getCellById(updatedBlock.id.toString());
      if (node && node.isNode()) {
         (node as any).setAttrs({
            label: { text: updatedBlock.title },
         });
      }
      setIsModalOpen(false);
   };

   const onDrop = (event: DragEvent) => {
      event.preventDefault();
      if (!graphRef.current) {
         console.error('Graph instance is not initialized');
         return;
      }

      const block: BlockSelect & { color: string } = JSON.parse(
         event.dataTransfer.getData('blockType'),
      );
      const { offsetX, offsetY } = event.nativeEvent;

      const newBlock: IBlock = {
         id: Date.now(),
         title: block.text,
         type: block.type,
         x: offsetX,
         y: offsetY,
         parent: { id: null },
         child: [],
         color: block.color,
      };

      if (!blocks.some((existingBlock) => existingBlock.id === newBlock.id)) {
         setBlocks((prev) => {
            const updatedBlocks = [...prev, newBlock];
            saveBlocksToLocalStorage(slug, updatedBlocks);
            return updatedBlocks;
         });

         graphRef.current.addNode({
            id: String(newBlock.id),
            x: offsetX,
            y: offsetY,
            width: 220,
            height: 60,
            label: newBlock.title,
            data: newBlock,
            attrs: {
               body: {
                  fill: newBlock.color,
                  stroke: '#FF910F',
                  overflow: 'hidden',
                  strokeWidth: 1,
                  rx: 8,
                  ry: 8,
               },
               label: {
                  text: newBlock.title,
                  fill: '#333',
                  fontSize: 14,
                  fontWeight: '400',
                  textWrapMode: 'wrap',
               },
            },
            ports: {
               items: [
                  { id: 'in', group: 'in' },
                  { id: 'out', group: 'out' },
               ],
               groups: {
                  in: {
                     position: 'top',
                     attrs: {
                        circle: {
                           r: 6,
                           magnet: 'passive',
                           stroke: '#FF910F',
                           strokeWidth: 2,
                           fill: '#fff',
                        },
                     },
                  },
                  out: {
                     position: 'bottom',
                     attrs: {
                        circle: {
                           r: 6,
                           magnet: true,
                           stroke: '#FF910F',
                           strokeWidth: 2,
                           fill: '#fff',
                        },
                     },
                  },
               },
            },
         });
      }
   };

   useEffect(() => {
      if (containerRef.current && !graphRef.current) {
         graphRef.current = new Graph({
            container: containerRef.current,
            grid: true,
            connecting: {
               snap: true,
               allowBlank: false,
               highlight: true,
               connector: 'rounded',
               connectionPoint: 'anchor',
               router: {
                  name: 'manhattan',
               },
               createEdge() {
                  return graphRef.current!.createEdge({
                     attrs: {
                        line: {
                           stroke: '#FF910F',
                           strokeWidth: 2,
                           targetMarker: {
                              name: 'block',
                              args: { size: 6 },
                           },
                        },
                     },
                  });
               },
            },
         });

         graphRef.current.on('node:dblclick', ({ node }) => {
            const blockData = node.getData() as IBlock;
            if (blockData) {
               handleEditBlock(blockData);
            }
         });

         graphRef.current.on('node:moved', ({ node }) => {
            const { x, y } = node.position();
            const blockId = Number(node.id);

            setBlocks((prevBlocks) => {
               const updatedBlocks = prevBlocks.map((block) =>
                  block.id === blockId ? { ...block, x, y } : block,
               );
               saveBlocksToLocalStorage(slug, updatedBlocks);
               return updatedBlocks;
            });
         });

         graphRef.current.on('edge:connected', ({ edge }) => {
            const newEdge: IEdge = {
               id: edge.id,
               source: edge.getSourceCellId(),
               target: edge.getTargetCellId(),
            };
            setEdges((prevEdges) => {
               const updatedEdges = [...prevEdges, newEdge];
               saveEdgesToLocalStorage(slug, updatedEdges);
               return updatedEdges;
            });
         });

         graphRef.current.on('edge:removed', ({ edge }) => {
            setEdges((prevEdges) => {
               const updatedEdges = prevEdges.filter((e) => e.id !== edge.id);
               saveEdgesToLocalStorage(slug, updatedEdges);
               return updatedEdges;
            });
         });

         loadBlocksFromLocalStorage(slug);
         loadEdgesFromLocalStorage(slug);
      }
   }, [slug]);

   return (
      <ContextTree.Provider
         value={{
            blocks,
            editBlock: setBlocks,
            openModal: setIsModalOpen,
            currentBlock,
            handleEditBlock,
            deleteBlock,
         }}
      >
         <Wrapper>
            <Blocks>
               {blockTypes.map((block, index) => (
                  <BlockDrag
                     key={index}
                     $blockType={block.type}
                     $colorIndex={index}
                     draggable
                     onDragStart={(e) => {
                        const blockData = {
                           text: block.text,
                           type: block.type,
                           color: colors[index % colors.length],
                        };
                        e.dataTransfer.setData('blockType', JSON.stringify(blockData));
                     }}
                  >
                     {block.text}
                  </BlockDrag>
               ))}
            </Blocks>
            <Field
               ref={containerRef}
               onDrop={onDrop}
               onDragOver={(e) => e.preventDefault()}
            />
         </Wrapper>
         {isModalOpen && currentBlock && (
            <EditModal
               open={isModalOpen}
               setOpen={setIsModalOpen}
               item={currentBlock}
               onSave={saveBlockChanges}
               onDelete={() => deleteBlock(currentBlock!.id)}
            />
         )}
      </ContextTree.Provider>
   );
};

export default Tree;
