import React from 'react';
import { useParams } from 'react-router-dom';
import Tree from '../Tree/Tree';
import { ConstructorLayout } from './constructor.css';

const Constructor = () => {
   const { slug } = useParams<{ slug: string }>();

   if (!slug) {
      return <div>Ошибка: не указан идентификатор проекта</div>;
   }

   return (
      <ConstructorLayout>
         <Tree slug={slug} />
      </ConstructorLayout>
   );
};

export default Constructor;
