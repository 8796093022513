import React, { FC } from 'react';

import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';

import type { FilterProps } from '../types';
import { Box, Typography } from '@mui/material';

import {
   filterStyles,
   filterInnerStyles,
   filterItemsStyles,
   filterItemsActiveStyles,
} from '../styles';

export const FiltersDefault: FC<FilterProps> = ({
   title = 'Сортировать по:',
   value,
   arrValues,
   onFilterChange,
}) => {
   return (
      <Box sx={filterStyles}>
         <FilterIcon />
         <Typography variant="subtitle2">{title}</Typography>
         <Box sx={filterInnerStyles}>
            {arrValues.map((el) => (
               <Typography
                  variant="subtitle2"
                  sx={value === el ? filterItemsActiveStyles : filterItemsStyles}
                  onClick={() => onFilterChange(el)}
                  key={el}
               >
                  {el}
               </Typography>
            ))}
         </Box>
      </Box>
   );
};
