import React, { FC } from 'react';

import { SxProps, Theme } from '@mui/material';

import { FormControl } from '@mui/material';
import { formAccountStyles } from './style';

import type { FormProps } from '../types';
import { useTheme } from '@hooks/useTheme';

export const FormAccount: FC<FormProps> = ({
   children,
   onSubmit,
   styles,
   sx,
   gap = '24px',
}) => {
   const { theme } = useTheme();

   return (
      <FormControl
         component="form"
         sx={{
            ...(formAccountStyles({ theme, gap }) as SxProps<Theme>),
            ...((sx as object) ?? {}),
         }}
         style={styles}
         onSubmit={onSubmit}
      >
         {children}
      </FormControl>
   );
};
