import React, { FC } from 'react';

import { Button } from '@mui/material';

import type { ButtonIconType } from '../types';

import { buttonIconStyles } from '../styles';

export const ButtonIcon: FC<ButtonIconType> = ({
   Icon,
   onClick,
   disabled = false,
   styles,
   colorIcon,
   colorHoverIcon,
   sx,
}) => {
   return (
      <Button
         onClick={onClick}
         sx={{
            ...buttonIconStyles({ disabled, colorIcon, colorHoverIcon }),
            ...((sx as object) ?? {}),
         }}
         style={styles}
      >
         <Icon />
      </Button>
   );
};
