import { Dispatch, FormEvent, SetStateAction } from 'react';
import { validateClientAddForm } from '../validateForm';

import type { onSubmitClientsAddFormParams } from './type';

export const onSubmitClientsAddForm = ({
   e,
   onClose,
   setErrors,
   data,
}: onSubmitClientsAddFormParams) => {
   e.preventDefault();

   const { name, tel, email } = data;

   const { success, errors } = validateClientAddForm({
      name,
      tel,
      email,
   });

   if (!success) {
      setErrors(errors);
   } else {
      console.log('Form submitted', { name, tel, email });
      setErrors({ name: '', tel: '', email: '' });
      onClose();
   }
};
