import React, { FC, FormEvent, useState } from 'react';

import { useTheme } from '@hooks/useTheme';

import { ModalDefault } from '@shared/modals';
import { ColumnWrapper } from '@shared/column';
import { Box, Typography } from '@mui/material';
import { SelectDefault, SelectCheckbox } from '@shared/selects';
import { ButtonDefault } from '@shared/buttons';
import { DatePickerDefault } from '@shared/datePicker';

import handleSelect from '@utils/handleSelect';

import type { ClientsAddFormErrorsType, ModalClientAddProps } from './types';

import dayjs, { Dayjs } from 'dayjs';

import {
   modalStyles,
   modalClientsFiltersButtonBoxStyles,
   modalClientsFiltersButtonStyles,
   modalClientsFiltersInputBox,
   modalClientsFiltersInputsTitle,
   modalClientsFiltersSelectStyles,
   modalClientsFiltersStyles,
   modalClientsFiltersButtonCanelStyles,
} from './style';

import type { SelectCheckboxOptionType } from 'shared/selects/types';

const managersArr = ['первый', 'второй', 'третий'];

const sourceArr: SelectCheckboxOptionType[] = [
   { id: 1, label: 'Bitrix' },
   { id: 2, label: 'Ручной' },
   { id: 3, label: 'Импорт' },
];

export const ModalClientsFilters: FC<ModalClientAddProps> = ({ open, onClose }) => {
   const [dateAddFilter, setDateAddFilter] = useState<Dayjs>(dayjs());
   const [dateExodusFilter, setDateExodusFilter] = useState<Dayjs>(dayjs());

   const [sourceFilter, setSourceFilter] = useState<SelectCheckboxOptionType[]>([]);
   const [managerFilter, setManagerFilter] = useState<string>('');

   const { theme } = useTheme();

   const onSubmitForm = async (e: FormEvent) => {
      e.preventDefault();
      onClose();
   };

   return (
      <ModalDefault stylesModalContent={modalStyles} open={open} handleClose={onClose}>
         <ColumnWrapper
            component="form"
            onSubmit={onSubmitForm}
            styles={modalClientsFiltersStyles}
            maxWidth="464px"
         >
            <Typography variant="h2" sx={modalClientsFiltersInputsTitle(theme)}>
               Фильтр
            </Typography>
            <Box sx={modalClientsFiltersInputBox}>
               <DatePickerDefault
                  selectedDate={dateExodusFilter}
                  setSelectedDate={setDateExodusFilter}
                  placeholder="По дате добавления"
               />
               <DatePickerDefault
                  selectedDate={dateAddFilter}
                  setSelectedDate={setDateAddFilter}
                  placeholder="По дате исхода"
               />
               <SelectCheckbox
                  arrValues={sourceArr}
                  value={sourceFilter}
                  setValue={setSourceFilter}
                  placeholder="По источнику"
               />
               <SelectDefault
                  styles={modalClientsFiltersSelectStyles}
                  value={managerFilter}
                  onChange={(e) => handleSelect<string>(e, setManagerFilter)}
                  arrValues={managersArr}
                  placeholder="По Менеджеру"
               />
            </Box>

            <Box sx={modalClientsFiltersButtonBoxStyles(theme)}>
               <ButtonDefault
                  onClick={onClose}
                  type="submit"
                  label="Отмена"
                  sx={{
                     ...modalClientsFiltersButtonCanelStyles(),
                     ...(modalClientsFiltersButtonStyles() as object),
                  }}
               />
               <ButtonDefault
                  type="submit"
                  onClick={onSubmitForm}
                  sx={modalClientsFiltersButtonStyles() as object}
                  label="Применить фильтр"
               />
            </Box>
         </ColumnWrapper>
      </ModalDefault>
   );
};
