import React, { FC, ReactNode } from 'react';
import { useAuth } from 'app/dashboard/hooks/useAuth';

import { LayoutLoader } from 'layouts/layoutLoader';

type AuthLayoutProps = {
   children: ReactNode;
};

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
   const { isLoading } = useAuth();

   if (isLoading) return <LayoutLoader />;

   return <>{children}</>;
};

export default AuthLayout;
