import React, { FC } from 'react';

import { FormControl } from '@mui/material';
import { formAuthStyles } from './style';

import type { FormAuthProps } from './type';

const FormAuth:FC<FormAuthProps> = ({children,onSubmit,gap,styles}) => {
    return (
        <FormControl    
        component="form"
        sx={formAuthStyles(gap)}
        style={styles}
        onSubmit={onSubmit}>
            {children}
        </FormControl>
    );
}

export default FormAuth;
