import { SxProps, Theme } from '@mui/material/styles';
import { getStatusBackgroundColor } from '../utils';

import { ReactionEnum } from '@enums/runtime';


import { colorsUI } from '@constants/colorsUI';

const { light900, black800, scriptGray01, danger } = colorsUI;

export const visualScriptQuizStyles = ({
   reaction,
}: {
   reaction: ReactionEnum;
}): SxProps<Theme> => ({
   ...getStatusBackgroundColor(reaction),
   display: 'flex',
   flexDirection: 'column',
   gap: '8px',
   padding: '32px',
});

export const visualScriptQuizContainerStyles = (): SxProps<Theme> => ({});

export const visualScriptQuizTitleStyles = ({
   isNegative,
   theme,
}: {
   isNegative: boolean;
   theme: Theme;
}): SxProps<Theme> => ({
   width: 'fit-content',
   color: isNegative ? black800 : light900,
   borderBottom: `4px dashed ${isNegative ? black800 : light900}`,
   [theme.breakpoints.down('md')]: {
      width: '100%',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '18px',
      lineHeight: '4.5rem',
      flexDirection: 'row-reverse',
   },
});

export const visualScriptQuizTextStyles = (theme: Theme): SxProps<Theme> => ({
   color: scriptGray01,
   [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
   },
});

export const visualScriptQuizObjectionStyles = (theme: Theme): SxProps<Theme> => ({
   color: danger,
   [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem',
      lineHeight: '1.625px',
      fontWeight: 700,
   },
});
