import { SxProps, Theme } from '@mui/material';

import { colorsUI } from '@constants/colorsUI';

const { danger, dangerActive } = colorsUI;

export const accountHeaderStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      background: 'red',
      gap: '16px',
   },
});

export const accountHeaderButtonStyles = (): SxProps<Theme> => ({
   background: danger,
   '&:hover': {
      background: dangerActive,
   },
});
