import React, { ChangeEvent, FC, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';

import { InputDefault } from 'shared/inputs';
import { SelectDefault } from 'shared/selects';
import { ButtonDefault } from 'shared/buttons';

import { formCreateStyles, formCreateContentStyles } from './style';

import { FormProps } from '../types';

import { colorsUI } from 'constants/colorsUI';

const { light, light900 } = colorsUI;

const selectValue = ['Option 1', 'Option 2', 'Option 3'];

export const FormCreate: FC<FormProps> = ({
   children,
   onSubmit,
   gap = '24px',
   stylesInner,
   stylesItem,
}) => {
   return (
      <Box sx={{ ...formCreateStyles, ...stylesInner }}>
         <Box
            sx={{ ...formCreateContentStyles, gap: gap, ...stylesItem }}
            component="form"
            onSubmit={onSubmit}
         >
            {children}
         </Box>
      </Box>
   );
};
