export const boxButtonStyles = {

   width:"100%", 
   display:"flex", 
   justifyContent:"center", 
   flexWrap:"wrap",
   gap: '10px',
   '@media (max-width: 360px)': {
    flexDirection: 'column', 
    alignItems: 'center',   
    gap: '8px',      
  },
  };
  