import { z } from 'zod';

import { errorsSignUp } from '../errors';

const { invalidEmail, emailAlreadyExists, unknownError } = errorsSignUp;

export const signUpSchema = z
   .object({
      email: z.string().email({ message: invalidEmail }),
   })
   .strip();

export type SignUpData = z.infer<typeof signUpSchema>;
export type ErrorStateSignUp = Partial<SignUpData> & {
   unknownError?: string;
   emailOtherErrorMessage?: string;
   emailAlreadyExists?: string;
};
