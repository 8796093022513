import { colorsUI } from "constants/colorsUI";
import { CSSProperties } from "react";

const {black400} = colorsUI

export const boxInputstyles  = {
 width:"100%", 
 display:"flex", 
 flexWrap:"wrap",
 gap: '8px',
 '@media (max-width: 360px)': {
  flexDirection: 'column', 
  alignItems: 'center',      
},
};

export const recoveryPasswordStyles:CSSProperties = {
    width:"100%",
    textAlign:"right",
    color:black400,
    fontSize:"1rem",
    lineHeight:"20px",
    fontWeight:700,
    
   };
   
export const chexboxStyles:CSSProperties = {
    width:"100%",
    textAlign:"left",
   };
   

export const boxButtonStyles = {
    width:"100%", 
    display:"flex", 
    justifyContent:"end", 
    flexWrap:"wrap",
    gap: '10px',
    '@media (max-width: 360px)': {
     flexDirection: 'column-reverse', 
     alignItems: 'center',   
     gap: '8px',     
      
   },
   };

export const buttonSubmitStyles = {
  width: 'auto',
  '@media (max-width: 360px)': {
    width:"100%"
  },
} 