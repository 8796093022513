import { validatePasswordChange } from '../checkPasswordChangeErrors';

import { changePasswordErrorMessages } from '@errors/profile/changePasswordErrors';

import type { ChangePasswordParams } from './type';

export const changePassword = async ({
   changePasswordData,
   data,
   setErrors,
}: ChangePasswordParams) => {
   const { confirmNewPassword, currentPassword, newPassword } = data;
   const { incorrectPassword } = changePasswordErrorMessages;

   const responseData = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmNewPassword,
   };

   const res = validatePasswordChange({
      confirmNewPassword,
      currentPassword,
      newPassword,
      setErrors,
   });

   if (res.length === 0) {
      try {
         await changePasswordData(responseData);
         return true;
      } catch (err) {
         console.error(err);
         setErrors({ incorrectPassword });
         return false;
      }
   }
};
