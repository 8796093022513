import React, { FC } from 'react';

import AuthLayout from 'app/dashboard/layout/AuthLayout';

import { Route, Routes } from 'react-router-dom';
import { LayoutAuth } from 'app/dashboard/layout/layoutAuth';
import { LayoutProfile } from 'app/dashboard/layout/layoutProfile';
import { LayoutVisualScript } from 'app/dashboard/layout/layoutVisualScript';
import DashboardVisualScripPage from 'app/dashboard/pages/dashboard/visualScript/DashboardVisualScripPage';
import { authRoutes, privateRoutes, publickRoutes } from '../../routes/index';
import useAppSelector from 'app/dashboard/store/hooks/useAppSelector';

const DashboardRouter: FC = () => {
   const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

   const routes = isAuthenticated
      ? publickRoutes.concat(privateRoutes)
      : publickRoutes.concat(authRoutes);

   const Layout = isAuthenticated ? LayoutProfile : LayoutAuth;

   return (
      <Routes>
         <Route path="/visual-script/:slug" element={<LayoutVisualScript />}>
            <Route path="" element={<DashboardVisualScripPage />} />
         </Route>
         <Route element={<Layout />}>
            {routes.map(({ element: Element, path }) => (
               <Route key={path} path={path} element={<Element />} />
            ))}
         </Route>
      </Routes>
   );
};

export default DashboardRouter;
