import { SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const handleSelect = <T>(
   e: SelectChangeEvent<T>,
   setValue: Dispatch<SetStateAction<T>>,
) => {
   setValue(e.target.value as T);
};

export default handleSelect;
