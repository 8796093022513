import { CSSProperties } from 'react';
import { Theme, SxProps } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { light, light500, light900, black200, black300, black600, transperent } = colorsUI;

export const modalStyles: CSSProperties = {
   maxWidth: '678px',
};

export const modalClientAddStyles: CSSProperties = {
   alignItems: 'center',
   width: '100%',
   position: 'relative',

   overflowY: 'auto',
};

export const modalClientsAddInputBox: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '24px',
};

export const modalClientsAddInputsTitle = (theme: Theme): SxProps<Theme> => ({
   fontSize: '1.875rem',
   color: black600,
   [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
   },
});

export const modalClientsAddButtonStyles = (theme: Theme): SxProps<Theme> => ({
   background: transperent,
   color: light900,
   border: `1px solid ${light500}`,
   '&:hover': {
      color: light,
      background: light500,
   },
});

export const modalClientAddImgStyles: CSSProperties = {
   width: '100%',
   maxWidth: '161px',
   height: '132px',
};

export const modalClientAddSelectStyles: CSSProperties = {
   width: '100%',
};

export const modalClientAddInputStyles: CSSProperties = {
   gap: '4px',
   display: 'flex',
   flexDirection: 'column',
};

export const modalClientAddLabelStyles: CSSProperties = {
   color: black300,
};

export const modalClientAddButtonBoxStyles: CSSProperties = {
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'stretch',
   gap: '8px',
};

export const modalClientAddCloseIconStyles = (): SxProps<Theme> => ({
   position: 'absolute',
   right: '15px',
   top: '49pxpx',
   color: black200,
   transition: 'all .5s',
   cursor: 'pointer',

   '&:hover': {
      color: black300,
   },
});
