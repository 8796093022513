import React, { FC } from 'react';

import ReactPhoneInput, { CountryData } from 'react-phone-input-material-ui';
import { TextField, Box } from '@mui/material';
import { ErrorMessageInput } from '@shared/errorMessage';

import type { InputPhoneProps } from '../types';
import { inputPhoneStyles } from '../styles';
import 'react-phone-input-material-ui/lib/style.css';

export const InputPhone: FC<InputPhoneProps> = ({
   phone,
   setPhone,
   setCountry,
   label = '',
   sx,
   placeholder = '',
   disabled = false,
   error = '',
   sxWrapper,
}) => {
   const handlePhoneChange = (value: string, country: CountryData) => {
      if (setCountry && country.dialCode) {
         setCountry(country.dialCode);
      }

      setPhone(value);
   };

   return (
      <Box sx={{ ...(sxWrapper ?? {}) }}>
         <ReactPhoneInput
            value={phone}
            onChange={handlePhoneChange}
            component={TextField}
            buttonStyle={{
               left: 'auto',
               right: '0',
               position: 'absolute',
               marginLeft: '8px',
            }}
            inputProps={{
               placeholder,
               'aria-label': 'phone number',
               label,
               variant: 'outlined',
               sx: { ...inputPhoneStyles(!!error, disabled), ...((sx as object) ?? {}) },
            }}
            containerStyle={{ position: 'relative' }}
         />
         <ErrorMessageInput message={error} />
      </Box>
   );
};
