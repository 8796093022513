import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { black50, black200, black800, light400, light500 } = colorsUI;

export const calendarStyles = (): SxProps<Theme> => ({
   '& .MuiDayCalendar-weekDayLabel': {
      display: 'none',
   },
   '& .MuiDayCalendar-weekContainer': {
      margin: 0,
      padding: 0,
   },
   '& .MuiPickersDay-root': {
      padding: '20px',
      margin: 0,
      lineHeight: '1.25rem',
      borderRadius: '0',
      border: `1px solid ${black50}`,
      color: black800,
   },
   '& .MuiPickersCalendarHeader-label': {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      textTransform: 'capitalize',
   },
   '& .MuiPickersCalendarHeader-root ': {
      marginBottom: '12px',
   },
   '& .MuiPickersDay-dayOutsideMonth': {
      color: black200,
   },
   '& .Mui-selected': {
      backgroundColor: `${light500} !important`,
      color: '#fff',
      fontSize: '0.875rem',

      '&:hover': {
         backgroundColor: `${light400} !important`,
      },
   },
});
