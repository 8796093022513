import { SxProps, Theme } from '@mui/material';

export const LogsNoneDataStyles = (): SxProps<Theme> => ({});

export const LogsNoneDataImageStyles = (): SxProps<Theme> => ({
   width: '100%',
   maxWidth: {
      sm: '410px',
      xs: 'none',
   },
   height: {
      md: '380px',
      xs: '270px',
   },
   objectFit: 'contain',
});
