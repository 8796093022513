import React, { FC } from 'react';

import { Box } from '@mui/material';

import type { ContainerProps } from '../types';

import { centeredContainerSteles } from './styles';

export const CenteredContainer: React.FC<ContainerProps> = ({ children, styles }) => {
   return (
      <Box
         sx={{
            ...centeredContainerSteles,
            ...styles,
         }}
      >
         {children}
      </Box>
   );
};
