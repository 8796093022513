import React, { FC, useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

import type { AlertSnackbarProps } from '../types';

export const AlertSnackbar: FC<AlertSnackbarProps> = ({
   open,
   setOpen,
   Icon,
   horizontal = 'right',
   vertical = 'top',
   message,
   severity = 'success',
   sx,
   sxIcon,
   styles,
   stylesIcon,
   variant = 'standard',
   duration = 3000,
}) => {
   const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen(false);
   };

   return (
      <div>
         <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
         >
            <Alert
               variant={variant}
               onClose={handleClose}
               severity={severity}
               sx={{ ...((sx ?? {}) as object), ...(styles as object) }}
               icon={
                  Icon && (
                     <Icon
                        sx={{ ...((sxIcon ?? {}) as object), ...(stylesIcon as object) }}
                        fontSize="inherit"
                     />
                  )
               }
            >
               {message}
            </Alert>
         </Snackbar>
      </div>
   );
};

export default AlertSnackbar;
