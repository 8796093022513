import React from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box, Typography } from '@mui/material';
import { CardDefault } from '@shared/cards';

import {
   accountPersonalDataCardStyles,
   accountPersonalDataCardTitleStyles,
   accountPersonalDataCardRateStyles,
   accountPersonalDataCardDeteValidStyles,
} from './style';
import { LinkDefault } from '@shared/links';

export const AccountPersonalDataCard = () => {
   const { theme } = useTheme();

   return (
      <CardDefault sx={accountPersonalDataCardStyles({ theme })}>
         <Box>
            <Typography
               sx={accountPersonalDataCardTitleStyles({ theme })}
               variant="subtitle1"
            >
               Активный тариф
            </Typography>
            <Typography sx={accountPersonalDataCardRateStyles({ theme })} variant="h2">
               Standart
            </Typography>
            <Typography
               sx={accountPersonalDataCardDeteValidStyles({ theme })}
               variant="body2"
            >
               Действует до: 16.04.2024
            </Typography>
         </Box>
         <LinkDefault href="/">Расширить тариф</LinkDefault>
      </CardDefault>
   );
};
