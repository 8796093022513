import type {
   IRuntimeSaveLeadDataRequets,
   IRuntimeResponseData,
} from '@projectTypes/runtime';

import type { OnBlurDataType } from '../types';
import { Dispatch, SetStateAction } from 'react';

export const onBlurVisualScript = (
   data: OnBlurDataType,
   dataStart: IRuntimeResponseData,
   setDataLocal: Dispatch<SetStateAction<IRuntimeSaveLeadDataRequets>>,
   saveLeadData: Function,
) => {
   const obj = {
      name: dataStart.data.lead.name,
      phone_code: dataStart.data.lead.phone_code,
      phone_number: dataStart.data.lead.phone_number,
      fields: {
         [data.name]: data.value,
      },
   } as IRuntimeSaveLeadDataRequets;
   console.log('blur', data);
   console.log(dataStart);
   setDataLocal(obj);
   saveLeadData(dataStart, obj);
};
