import { styled } from '@mui/material/styles';
import { MenuItem as MuiMenuItem } from '@mui/material';

import { colorsUI } from 'constants/colorsUI';
import { CSSProperties } from 'react';

interface SelectProps {
   disabled?: boolean;
}

const {
   light50,
   light100,
   black50,
   black200,
   black400,
   disabledText,
   disabledBackground,
   transperent,
} = colorsUI;

export const CustomMenuItemCheckBox = styled(MuiMenuItem)<SelectProps>(
   ({ disabled }) => ({
      display: 'flex',
      alignItems: 'center',
      gap: '26px',
      fontSize: '1rem',
      padding: '18px 26px',
      backgroundColor: disabled ? disabledBackground : transperent,
      color: disabled ? disabledText : black400,
      borderBottom: `1px solid ${black50}`,

      '&:hover': {
         backgroundColor: disabled ? disabledBackground : light50,
      },
      '&.Mui-selected': {
         backgroundColor: light100,
         '&:hover': {
            backgroundColor: ` ${light50} !important`,
         },
      },
      '&.Mui-selected:hover': {
         backgroundColor: light50,
      },
   }),
);

export const customMenuItemCheckBoxListItemTextStyles: CSSProperties = {
   color: black200,
   fontSize: '1rem',
};
