import type { HandleClickPopupParams } from './type';

export const handleClickPopup = ({
   event,
   row,
   setAnchorEl,
   setPopupIsOpen,
   setRowState,
}: HandleClickPopupParams) => {
   setAnchorEl(event.currentTarget);
   setPopupIsOpen(true);
   setRowState(row);
};
