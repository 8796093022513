import React from 'react';

import { Outlet } from 'react-router-dom';

import { CenteredContainer } from '@shared/containers';

export const LayoutAuth: React.FC = () => {
   return (
      <CenteredContainer>
         <Outlet />
      </CenteredContainer>
   );
};
