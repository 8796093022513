import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { black200, black500 } = colorsUI;

export const inner = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '24px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});

export const filterButtonStyles = (theme: Theme): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
   },
});

export const filterBoxStyles = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '16px',
   flexWrap: 'wrap',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
   },
});

export const actionsButtonMoreOptionsTitleStyles = (): SxProps<Theme> => ({
   color: black200,
   padding: 0,
   width: 'auto',
   transition: 'all .5s',
   '&:hover': {
      color: black500,
      fontWeight: 700,
   },
});

export const actionsButtonMoreOptionsStyles = (): SxProps<Theme> => ({
   justifyContent: 'start',
});
