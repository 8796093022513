import { CSSProperties } from 'react';
import { Theme } from '@mui/material/styles';

import { colorsUI } from '@constants/colorsUI';

const { black50 } = colorsUI;

export const visualScriptFormStyles = (theme: Theme) => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '32px',
   width: '100%',
   maxWidth: '320px',
   background: black50,
   padding: '32px 16px',
   [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
   },
});

export const visualScriptFormFormStyles: CSSProperties = {
   display: 'flex',
   flexDirection: 'column',
   gap: '16px',
};
