import { SxProps, Theme } from '@mui/material';
import { shadowUI } from '@constants/shadowUI';

const { shadowSmall } = shadowUI;

export const cardDefaultStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   height: '100%',
   padding: '24px',
   boxShadow: shadowSmall,
   borderRadius: '8px',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   gap: '20px',
});
