import { ChangeEvent, SetStateAction, Dispatch } from 'react';

import handleInput from '@utils/handleInput';

type onChangeParams = {
   e: ChangeEvent<HTMLInputElement>;
   isActive: boolean;
   setValue: Dispatch<SetStateAction<string>>;
   setIsOpenTooltip: Dispatch<SetStateAction<boolean>>;
};

export const handleFieldChange = ({
   e,
   isActive,
   setValue,
   setIsOpenTooltip,
}: onChangeParams): void => {
   if (isActive) {
      setIsOpenTooltip(false);
      handleInput(e, setValue);
   } else {
      setIsOpenTooltip(true);
   }
};
