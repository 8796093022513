import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation } from 'app/dashboard/components/navigation';
import { Header } from '@componentsDashBoard/header';
import { Box } from '@mui/material';
import { Breadcrumbs } from '@shared/breadcrumbs';
import { Footer } from '@componentsDashBoard/footer';

import { navigationRoutesNavbar } from 'app/dashboard/routes/navigationRoutes';
import { settingsRoutes } from 'app/dashboard/routes/settingsRoutes';

import { layoutProfileInnerStyles, layoutProfileMainrStyles } from './style';

export const LayoutProfile: React.FC = () => {
   const [openDrawer, setOpenDrawer] = useState(true);

   const handlerOpenDrawer = () => {
      setOpenDrawer(!openDrawer);
   };

   return (
      <>
         <Header />
         <Box style={layoutProfileInnerStyles}>
            <Navigation
               setOpen={handlerOpenDrawer}
               open={openDrawer}
               itemTop={navigationRoutesNavbar}
               itemBottom={settingsRoutes}
            />
            <Box component={'main'} sx={layoutProfileMainrStyles}>
               <Breadcrumbs />
               <Outlet />
            </Box>
         </Box>
         <Footer />
      </>
   );
};
