import { validateEmail } from '@utils/validation/validateEmail';
import type { OnClickEditEmailParams } from './type';

import { incorectEmailError } from '@errors/general';

export const onClickEditEmail = ({
   email,
   setEmailErrors,
   setIsOpenModalEmail,
}: OnClickEditEmailParams) => {
   if (validateEmail(email)) {
      setIsOpenModalEmail(true);
      setEmailErrors({ incorectEmail: null });
   } else {
      setEmailErrors({ incorectEmail: incorectEmailError });
   }
};
