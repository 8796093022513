import { SxProps, Theme } from '@mui/material/styles';
import { getStylesButton } from '../utils/getStylesButton/index';

import { ReactionEnum } from '@enums/runtime';

import { colorsUI } from '@constants/colorsUI';

const { light, light100, scriptGray01, transperent } = colorsUI;

export const visualScriptBoxActionStyles = (): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'start',
   gap: '16px',

   background: scriptGray01,
   padding: '16px 32px',
});

export const visualScriptBoxActionButtonGoBackStyles = (): SxProps<Theme> => ({
   background: transperent,
   color: light100,
   padding: '10px 2px',
   '&:hover': {
      color: light,
   },
});

export const visualScriptBoxActionButtonStyles = ({
   reactin,
}: {
   reactin: ReactionEnum;
}): SxProps<Theme> => ({
   width: '100%',
   color: light,
   ...getStylesButton({ reaction: reactin }),
});
