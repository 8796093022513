import React, { useEffect, useState } from 'react';

import { useRuntimeLogData } from '@hooks/hooksApi';

import { LogsHeader } from '../logsHeader';
import { ColumnDashboard } from '@shared/column';
import { LogsNoneData } from '../logsNoneData';
import { LogsData } from '../logsData';
import { LoaderDefault } from '@shared/loader';

export const Logs = () => {
   const disabled = false;

   const { runtimeLogtIsLoading, runtimeLogData } = useRuntimeLogData();

   const [logs, setLogs] = useState(['logs']);

   if (runtimeLogtIsLoading) return <LoaderDefault />;

   console.log(runtimeLogData);

   return (
      <ColumnDashboard gap="32px">
         <LogsHeader disabled={disabled} />
         {logs.length === 0 ? <LogsNoneData /> : <LogsData />}
      </ColumnDashboard>
   );
};
