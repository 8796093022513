import React, { FC, useState, useEffect } from 'react';
import { IBlock } from 'interfaces/constructor/block';
import {
   ModalBackdrop,
   ModalContent,
   Button,
   InputField,
   ButtonContainer,
} from './editModal.css';

interface IProps {
   open: boolean;
   setOpen: (open: boolean) => void;
   item: IBlock;
   onSave: (block: IBlock) => void;
   onDelete: () => void;
}

const EditModal: FC<IProps> = ({ open, setOpen, item, onSave, onDelete }) => {
   const [text, setText] = useState<string>(item.title);

   useEffect(() => {
      setText(item.title);
   }, [item]);

   const handleSave = () => {
      const updatedBlock = { ...item, title: text };
      onSave(updatedBlock);
      setOpen(false);
   };

   const handleDelete = () => {
      onDelete();
      setOpen(false);
   };

   return (
      <ModalBackdrop $active={open} onClick={() => setOpen(false)}>
         <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3 style={{ fontWeight: '400' }}>Редактировать блок</h3>
            <InputField value={text} onChange={(e) => setText(e.target.value)} />
            <ButtonContainer>
               <Button $variant="save" onClick={handleSave}>
                  Сохранить
               </Button>
               <Button $variant="delete" onClick={handleDelete}>
                  Удалить
               </Button>
               <Button $variant="cancel" onClick={() => setOpen(false)}>
                  Отменить
               </Button>
            </ButtonContainer>
         </ModalContent>
      </ModalBackdrop>
   );
};

export default EditModal;
