import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { light500, light900, transperent } = colorsUI;

export const accountPersonalDataFormTextfieldStyles: CSSProperties = {
   width: '100%',
};

export const accountPersonalDataFormItemStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',

   gap: '24px',
});
