import React, { FC, memo, ForwardedRef } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { inputStyles } from '../styles';

import type { InputType } from '../types';
import { colorsUI } from 'constants/colorsUI';

const { errorColor } = colorsUI;

export const InputDefault = React.forwardRef<HTMLInputElement, InputType>(
   (
      {
         value,
         label,
         type = 'text',
         onChange,
         onBlur,
         required = false,
         placeholder = 'placeholder',
         error,
         disabled = false,
         stylesWrapper,
         stylesForm,
         labelStyles,
         labelVariant = 'body1',
         sx,
         sxLabel,
         sxInner,
         name,
      },
      ref: ForwardedRef<HTMLInputElement>,
   ) => {
      return (
         <Box sx={{ ...stylesWrapper, ...(sxInner ?? {}) }}>
            <TextField
               sx={{
                  ...inputStyles(!!error, disabled),
                  ...((sx as object) ?? {}),
               }}
               value={value}
               inputRef={ref}
               type={type}
               onChange={onChange}
               onBlur={onBlur}
               placeholder={placeholder}
               variant="outlined"
               disabled={disabled}
               style={stylesForm}
               required={required}
               name={name}
            />
            {label && (
               <Typography
                  variant={labelVariant}
                  sx={{ ...labelStyles, ...(sxLabel ?? {}) }}
               >
                  {label}
               </Typography>
            )}
            {error && <Typography sx={{ color: errorColor }}>{error}</Typography>}
         </Box>
      );
   },
);
