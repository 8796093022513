import React, { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, InputAdornment, TextField } from '@mui/material';
import { CalendarDefault } from '../../calendars';

import type { DatePickerDefaultProps } from '../types';

import { ReactComponent as IconCalender } from '../../../assets/icons/calendar-date.svg';

import {
   datePickerDefaultCalendarStyles,
   datePickerDefaultTextFilendStyles,
} from '../styles';

export const DatePickerDefault: FC<DatePickerDefaultProps> = ({
   selectedDate,
   setSelectedDate,
   placeholder,
   styles,
}) => {
   const [open, setOpen] = useState<boolean>(false);

   const handleDateChange = (newValue: Dayjs) => {
      setSelectedDate(newValue);
   };

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
         <Box sx={{ ...styles }}>
            <TextField
               value={selectedDate ? selectedDate.format('DD.MM.YYYY') : ''}
               onClick={() => {
                  setOpen(!open);
               }}
               sx={datePickerDefaultTextFilendStyles()}
               fullWidth
               placeholder={placeholder}
               InputProps={{
                  endAdornment: (
                     <InputAdornment sx={{ color: 'red' }} position="end">
                        <IconCalender />
                     </InputAdornment>
                  ),
               }}
            />
            <Box sx={datePickerDefaultCalendarStyles(open)}>
               <CalendarDefault value={selectedDate} onChange={handleDateChange} />
            </Box>
         </Box>
      </LocalizationProvider>
   );
};
