import { Dispatch, SetStateAction } from 'react';
import { ZodDate, ZodError } from 'zod';

import { getErrorsAuth } from '../../../utils';

import type { SignUpData, ErrorStateSignUp } from '../../../schemas';
import { signUpSchema } from '../../../schemas';

import { errorsSignUp } from '../../../errors';

type validateSignUpFormParams = {
   data: SignUpData;
   setErrors: Dispatch<SetStateAction<ErrorStateSignUp | null>>;
};

export const validateSignUpForm = ({
   data,
   setErrors,
}: validateSignUpFormParams): ZodError | undefined => {
   try {
      signUpSchema.parse(data);
      setErrors(null);
   } catch (err) {
      if (err instanceof ZodError) {
         const resErrors = getErrorsAuth(err.errors, signUpSchema);
         setErrors(resErrors);
      } else {
         console.error('Низвестная ошибка');
         setErrors({ unknownError: errorsSignUp.unknownError });
      }

      return err as ZodError;
   }
};
