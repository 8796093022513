import React, { FC } from 'react';

import { useGoBack } from '@hooks/useGoBack';

import { Box, Typography } from '@mui/material';
import Logo from 'shared/logo';
import { ButtonDefault } from '@shared/buttons';

import { colorsUI } from '@constants/colorsUI';

import { NotificationMessageProps } from './type';
import { wrapperStyles, boxButtonStyles, buttonStyles } from './style';

const { black300, light500, light600, light50, light100 } = colorsUI;

export const NotificationMessage: FC<NotificationMessageProps> = ({ title, label }) => {
   const goBack = useGoBack();

   return (
      <Box sx={wrapperStyles}>
         <Logo />
         <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
               {title}
            </Typography>
            <Typography
               sx={{ color: black300, display: 'block', mt: '8px' }}
               variant="caption"
            >
               {label}
            </Typography>
         </Box>

         <Box sx={boxButtonStyles}>
            <ButtonDefault
               sx={{ padding: '10px 24px', ...buttonStyles }}
               onClick={goBack}
               label="Вернуться назад"
               color={light50}
               colorHover={light100}
               backgroundColor={light500}
               backgroundColorHover={light600}
            />
         </Box>
      </Box>
   );
};
