import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { ListItemMoreProps } from '../types';

import { listItemLinkStyles } from './style';

export const ListItemLink: FC<ListItemMoreProps> = ({
   href,
   label,
   stylesInner,
   onClick,
}) => {
   return (
      <Link onClick={onClick} style={{ ...listItemLinkStyles, ...stylesInner }} to={href}>
         {label}
      </Link>
   );
};
