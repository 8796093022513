import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '@componentsDashBoard/header';
import { Box } from '@mui/material';
import { Footer } from '@componentsDashBoard/footer';

import { layoutVisualScriptInnerStyles, layoutVisualScriptMainrStyles } from './style';

export const LayoutVisualScript: React.FC = () => {
   const [openDrawer, setOpenDrawer] = useState(true);

   const handlerOpenDrawer = () => {
      setOpenDrawer(!openDrawer);
   };

   return (
      <>
         <Header />
         <Box style={layoutVisualScriptInnerStyles}>
            <Box component={'main'} sx={layoutVisualScriptMainrStyles}>
               <Outlet />
            </Box>
         </Box>
         <Footer />
      </>
   );
};
