import React from 'react';

import { useParams } from 'react-router-dom';

import { VisualScript } from 'app/dashboard/modules/visual-script';

const DashboardVisualScripPage = () => {
   const { slug } = useParams<{ slug: string }>();
   return <VisualScript slug={slug} />;
};

export default DashboardVisualScripPage;
