import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography } from '@mui/material';
import type { TableProps } from '../types';
import {
   tableStyles,
   tableHeaderStyles,
   tableCellStyles,
   tableRowCellStyles,
   tableHeaderCellStyles,
   tableCellStylesMobile,
   tableCellActionStylesMobile,
} from '../styles';
import { useTheme } from '@hooks/useTheme';

export const TableMobile = <T extends object>({
   columns,
   rows,
   sx,
   sxTableCell,
   sxTableHead,
}: TableProps<T>) => {
   const { theme } = useTheme();

   return (
      <TableContainer sx={{ ...tableStyles, ...sx }} component={Paper}>
         {rows.map((row, rowIndex) => (
            <Grid container key={rowIndex}>
               {columns.map((column) => (
                  <React.Fragment key={String(column.id)}>
                     {column?.renderCell ? (
                        <Grid
                           sx={{
                              ...tableCellStyles,
                              ...tableCellStylesMobile,
                              ...sxTableCell,
                           }}
                           item
                           xs={12}
                        >
                           <Box sx={{ ...tableCellActionStylesMobile }}>
                              {column.renderCell(row)}
                           </Box>
                        </Grid>
                     ) : (
                        <>
                           <Grid
                              sx={{
                                 ...(tableHeaderStyles({ theme }) as object),
                                 ...(tableCellStyles as object),
                              }}
                              item
                              xs={6}
                           >
                              <Typography
                                 sx={{
                                    ...tableHeaderCellStyles({ theme }),
                                    ...(sxTableHead as object),
                                 }}
                                 variant="subtitle2"
                              >
                                 {column.label}
                              </Typography>
                           </Grid>
                           <Grid
                              sx={{
                                 ...tableCellStyles,
                                 ...tableCellStylesMobile,
                                 ...sxTableCell,
                              }}
                              item
                              xs={6}
                           >
                              {column.renderCell ? (
                                 <Box>{column.renderCell(row)}</Box>
                              ) : (
                                 <Typography
                                    sx={{ ...tableRowCellStyles({ theme }) }}
                                    variant="body1"
                                 >
                                    {String(row[column.id as keyof T] ?? '-')}
                                 </Typography>
                              )}
                           </Grid>
                        </>
                     )}
                  </React.Fragment>
               ))}
            </Grid>
         ))}
      </TableContainer>
   );
};
