import { useResetPasswordMutation } from 'app/dashboard/store/api/authApi';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useResetPassword = () => {
   const [resetPassword, { isLoading, isSuccess, isError, error }] =
      useResetPasswordMutation();
   const handleResetPassword = async (email: string) => {
      try {
         const response = await resetPassword({ email }).unwrap();
         return response;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      handleResetPassword,
      isSuccess,
      isLoading,
      isError,
      error: error as IErrorResponse,
   };
};
