import React, { CSSProperties, FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { ReactComponent as IconKrest } from '@assets/icons/deleteKrest.svg';

import type { ModalProps } from '../types';

import { modalDefaultStyles, modalDefaultButtonCloseStyles } from '../styles';
import { ButtonIcon } from '@shared/buttons';
export const ModalDefault: FC<ModalProps> = ({
   children,
   open,
   handleClose,
   handleOpen,
   stylesModal,
   stylesModalContent,
   styleModalButtonClose,
}) => {
   const { theme } = useTheme();

   return (
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         open={open}
         onClose={handleClose}
         closeAfterTransition
         sx={stylesModal}
         slots={{ backdrop: Backdrop }}
         slotProps={{
            backdrop: {
               timeout: 500,
            },
         }}
      >
         <Fade in={open}>
            <Box sx={{ ...modalDefaultStyles, ...stylesModalContent }}>
               {styleModalButtonClose && (
                  <ButtonIcon
                     styles={{
                        ...(modalDefaultButtonCloseStyles(theme) as object),
                        ...styleModalButtonClose,
                     }}
                     Icon={IconKrest}
                     onClick={handleClose}
                  />
               )}

               {children}
            </Box>
         </Fade>
      </Modal>
   );
};
