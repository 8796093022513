import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import type { CircularProgressPropsColorOverrides } from '@mui/material/CircularProgress';
import type { LoaderProps } from '../types';

import { colorsUI } from '@constants/colorsUI';

const { light900 } = colorsUI;

export const LoaderDefault: FC<LoaderProps> = ({
   color = light900,
   stylesInner,
   stylesLoading,
   strokeWidth = 4,
}) => {
   return (
      <Box sx={{ display: 'flex', ...stylesInner }}>
         <CircularProgress
            sx={{ ...stylesLoading, color, strokeWidth }}
            thickness={strokeWidth}
         />
      </Box>
   );
};
