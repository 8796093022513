import type { ChangeEmailParams } from './type';

import { emailAlreadyExistsError } from '@errors/general';

export const changeEmail = async ({
   email,
   changeEmailData,
   setErrors,
}: ChangeEmailParams) => {
   try {
      await changeEmailData({ email });
      return true;
   } catch (err) {
      setErrors({ emailAlreadyExists: emailAlreadyExistsError });
      return false;
   }
};
