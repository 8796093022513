import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { light } = colorsUI;

export const visualScriptFormTextFieldStyles: CSSProperties = {
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '16px',
};

export const visualScriptFormTextFieldBoxButtonStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
};

export const visualScriptFormTextFielInputStyles: CSSProperties = {
   background: light,
};
