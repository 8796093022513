import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBlock } from 'interfaces/constructor/block';
import { RootState } from 'store/store';

interface IProps {
   blocks: IBlock[];
}

const initialState: IProps = {
   blocks: [],
};

export const TreeSlice = createSlice({
   name: 'TreeSlice',
   initialState,
   reducers: {
      loadBlocks: (state, action: PayloadAction<IBlock[]>) => {
         state.blocks = action.payload;
      },
   },
});

export const { loadBlocks } = TreeSlice.actions;

export const getAllBlocks = (store: RootState) => store.tree.blocks;

export default TreeSlice.reducer;
