import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

const { light50, black300 } = colorsUI;

export const footerStyles: SxProps<Theme> = (theme: Theme) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '48px',
   flexWrap: 'wrap',
   background: light50,
   padding: '32px',
   [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '19px',
   },
});

export const footerTextStyles: CSSProperties = {
   color: black300,
};
