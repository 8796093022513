import React, { FormEvent } from 'react';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ColumnDashboard } from '@shared/column';
import { LinkDefault } from '@shared/links';
import { FormCreate } from 'components/forms/formCreate';

import { FormCreateManagerContent } from './components/formCreateManagerContent/index';

import { buttonHeaderLeaveWithoutSavingStyles } from './style';

import { colorsUI } from '@constants/colorsUI';

const { light, light500, light900, transperent } = colorsUI;

export const CreateManagers = () => {
   const onSubmit = (e: FormEvent) => {
      e.preventDefault();
      console.log(e);
   };

   return (
      <ColumnDashboard>
         <ProfileHeader title="Добавление менеджера">
            <LinkDefault
               color={light900}
               colorHover={light}
               backgroundColor={transperent}
               backgroundColorHover={light900}
               href="/managers"
               label="Выйти без сохранения"
               sx={buttonHeaderLeaveWithoutSavingStyles}
            />
         </ProfileHeader>
         <FormCreate onSubmit={onSubmit}>
            <FormCreateManagerContent />
         </FormCreate>
      </ColumnDashboard>
   );
};
