import { SxProps, styled } from '@mui/material';
import { colorsUI } from 'constants/colorsUI';

import type { NavigationItemStylesProps, IconPropsStyles } from '../types';
import { NavLink } from 'react-router-dom';

const { disabledText, black500, light600, light900 } = colorsUI;

export const navigationItemIconStyles = ({
   disabled,
   isActived,
   color,
   colorActive,
   colorHover,
}: NavigationItemStylesProps): SxProps => ({
   width: 'fit-content',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '8px',
   color: disabled ? disabledText : isActived ? colorActive : color,
   transition: 'all .5s',
   '&:hover': {
      color: isActived ? colorActive : colorHover,
   },
   pointerEvents: disabled ? 'none' : 'auto',
});

export const iconStyles = ({ disabled, color }: IconPropsStyles): SxProps => ({
   fill: disabled ? disabledText : color,
});

export const CustomStyledNavLink = styled(NavLink)<{
   direction?: 'row' | 'column';
   disable?: boolean;
   color?: string;
   colorActive?: string;
   colorHover?: string;
}>(
   ({
      theme,
      direction = 'row',
      disable,
      color = black500,
      colorActive = light600,
      colorHover = light900,
   }) => ({
      display: 'flex',
      flexDirection: direction,
      textDecoration: 'none',
      alignItems: 'center',
      padding: theme.spacing(1),
      color: color,
      cursor: disable ? 'not-allowed' : 'pointer',
      opacity: disable ? 0.5 : 1,
      '&.active': {
         color: colorActive,
      },
      '&:hover': {
         color: colorHover,
      },
   }),
);
