import { CSSProperties } from 'react';
import { SxProps, Theme } from '@mui/material/styles';

import { colorsUI } from 'constants/colorsUI';

const { black400 } = colorsUI;

export const listMoreStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'start',
   flexDirection: 'column',
   gap: '8px',
};

export const listMoreToggleBoxtStyle: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'start',
   gap: '8px',
   color: black400,
};

export const listMoreToggleTextStyle: CSSProperties = {
   fontWeight: 700,
   lineHeight: '20px',
};

export const listMoreToggleIconStyle = (showAll: boolean): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   gap: '8px',
   fill: black400,
   transform: `rotate(${showAll ? '-180deg' : '0deg'})`,
});
