import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IProject, IProjectResponse } from '@projectTypes/projects';

const initialState: IProjectResponse = {
   data: [
      {
         name: '',
         slug: '',
         business_area: '',
         scenario_type: '',
         contact_base_type: '',
         is_draft: false,
         created_at: '',
         updated_at: '',
         role: '',
      },
   ],
   links: {
      first: '',
      last: '',
      prev: '',
      next: '',
   },
   meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      links: [
         {
            url: '',
            label: '',
            active: false,
         },
      ],
      path: '',
      per_page: 10,
      to: 1,
      total: 1,
   },
};

const projectsSlice = createSlice({
   name: 'projects',
   initialState,
   reducers: {
      setProjectData(state, action: PayloadAction<IProjectResponse>) {
         return action.payload;
      },
      addProjectData(state, action: PayloadAction<IProject>) {
         state.data.push(action.payload);
      },
   },
});

export const { setProjectData } = projectsSlice.actions;

export default projectsSlice.reducer;
