import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const buttonActionsBoxStyles = (theme: Theme): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   gap: '10px',
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
   },
});

export const buttonActionsStyles: CSSProperties = {
   padding: '10px 0',
};
