import React from 'react';

import useAppDispatch from 'app/dashboard/store/hooks/useAppDispatch';

import { Box, Typography } from '@mui/material';
import { VisualScriptStructureAccordion } from '../visualScriptStructureAccordion';

import { visualScriptStructureStyles } from './style';

const arr = ['first', 'second', 'three'];

export const VisualScriptStructure = () => {
   return (
      <Box sx={visualScriptStructureStyles}>
         <Typography variant="subtitle1">Структура</Typography>
         <VisualScriptStructureAccordion arrValues={arr} />
      </Box>
   );
};
