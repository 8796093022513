import React, { FC, useState } from 'react';
import { useTheme } from '@hooks/useTheme';

import { Box } from '@mui/material';

import {
   VisualScriptBoxAction,
   VisualScriptChatMessageList,
   VisualScriptQuiz,
} from './components';

import { visualScriptChatStyles } from './styles';

import type { VisualScriptChatProps } from './types';

import { ReactionEnum } from '@enums/runtime';

import { filterMessages } from './utils';
import { IReaction } from '@projectTypes/runtime';

export const VisualScriptChat: FC<VisualScriptChatProps> = ({
   handleOnClickCell,
   isEnd,
   isLoading,
   messages,
   reactionsButton,
}) => {
   const [reactionType, setReactionType] = useState<ReactionEnum | null>(null);

   const { theme } = useTheme();
   const messageQuiz = filterMessages({ arr: messages, typeMessage: 'manager' });

   return (
      <Box sx={{ ...visualScriptChatStyles(theme) }}>
         <VisualScriptQuiz
            messages={messageQuiz}
            reactionType={reactionType}
            isLoading={isLoading}
         />
         {/* <VisualScriptChatMessageList messages={messages} /> */}

         <VisualScriptBoxAction
            isLoading={isLoading}
            currentNode={reactionsButton}
            isEnd={isEnd}
            handleReactionClick={handleOnClickCell}
            setReactionType={setReactionType}
         />
      </Box>
   );
};
