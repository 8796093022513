import { SxProps, Theme } from '@mui/material/styles';
import type { ColumnStylesProps } from '../types';

export const columnCenteredStyles = ({
   theme,
   gap,
   gapMiddle,
   gapMobile,
   customStyles,
}: ColumnStylesProps): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'center',
   gap,
   [theme.breakpoints.down('md')]: {
      gap: gapMiddle,
   },
   [theme.breakpoints.down('sm')]: {
      gap: gapMobile,
   },
   ...customStyles,
});
