import React, { FC } from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { NavigationItemIcon } from '@shared/navigationItem/navigationItemIcon';

import type { NavigationProps } from './type';

import { navigationInnerStyles, navigationItemStyles } from './style';
import { DrawerDefault } from '@shared/drawers';

export const Navigation: FC<NavigationProps> = ({
   itemTop,
   itemBottom,
   styles,
   open = true,
   setOpen,
}) => {
   const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

   return (
      <DrawerDefault
         anchor="left"
         open={isDesktop}
         variant={isDesktop ? 'permanent' : 'temporary'}
         onClose={setOpen}
         sx={styles}
      >
         <Box sx={navigationInnerStyles}>
            <Box sx={navigationItemStyles}>
               {itemTop.map((el, i) => (
                  <NavigationItemIcon
                     key={i}
                     label={el.message}
                     path={el.path}
                     Icon={el.Icon}
                     direction="column"
                  />
               ))}
            </Box>
            <Box sx={{ ...navigationItemStyles, justifyContent: 'flex-end' }}>
               {itemBottom.map((el, i) => (
                  <NavigationItemIcon
                     key={i}
                     label={el.message}
                     path={el.path}
                     Icon={el.Icon}
                     direction="column"
                  />
               ))}
            </Box>
         </Box>
      </DrawerDefault>
   );
};
