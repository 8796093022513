import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';
import { Box, Typography } from '@mui/material';

import notActionScriptsImage from '@assets/images/notActionScripts.jpg';

import {
   visualScriptQuizHeaderHintTextStyles,
   visualScriptQuizHeaderStyles,
} from './style';

import type { VisualScriptQuizHeaderProps } from './type';

export const VisualScriptQuizHeader: FC<VisualScriptQuizHeaderProps> = ({ hint }) => {
   const { theme } = useTheme();

   return (
      <Box sx={{ ...visualScriptQuizHeaderStyles(theme) }}>
         <Box component="img" src={notActionScriptsImage} alt="подсказка" />
         <Typography
            sx={{ ...visualScriptQuizHeaderHintTextStyles(theme) }}
            variant="subtitle2"
         >
            {hint}
         </Typography>
      </Box>
   );
};
