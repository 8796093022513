import React, { FC } from 'react';
import { Button } from '@mui/material';

import { colorsUI } from 'constants/colorsUI';
import type { ButtonArrowProps } from '../types';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { buttonArrowStyles } from '../styles';

const { light, light500, light900 } = colorsUI;

export const ButtonArrow: FC<ButtonArrowProps> = ({
   onClick,
   type = 'button',
   label,
   color = light,
   colorHover = light,
   backgroundColor = light500,
   backgroundColorHover = light900,
   disabled = false,
   sx,
   children,
   styles,
   direction = 'before',
}) => {
   return (
      <Button
         type={type}
         onClick={onClick}
         sx={{
            ...buttonArrowStyles({
               color,
               colorHover,
               backgroundColor,
               backgroundColorHover,
               disabled,
            }),
            ...((sx as object) ?? {}),
         }}
         style={styles}
      >
         {direction === 'before' && <ArrowIcon fill={color} />}
         {children ?? label}
         {direction === 'after' && <ArrowIcon fill={color} />}
      </Button>
   );
};
