import { SxProps, Theme } from '@mui/material';

export const logsHeaderStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '16px',
   },
});

export const logsHeaderButtonStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   [theme.breakpoints.down('sm')]: {
      width: '100%',
   },
});
