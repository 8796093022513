import React from 'react';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { Box } from '@mui/material';
import { ProjectsNone } from '../projectsNone';
import { ProjectsData } from '../projectData';
import { ColumnDashboard } from '@shared/column';
import { LinkDefault } from '@shared/links';

import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';

import {
   projectsStyles,
   projectsContentStyles,
   projectsLoaderInnerStyles,
   projectsHeaderLinkStyles,
} from './styles';
import { useProjectData } from '@hooks/hooksApi/useProjectData';
import { LoaderDefault } from '@shared/loader';

export const Projects = () => {
   const { projectData, projectIsLoading, projectIsError } = useProjectData();

   if (projectIsError) return <p>ошибка</p>;

   return (
      <ColumnDashboard styles={projectsStyles}>
         <ProfileHeader title="Мои проекты">
            <LinkDefault
               sx={projectsHeaderLinkStyles}
               Icon={IconPlus}
               href="/projects/create-projects"
            >
               Создание проекта
            </LinkDefault>
         </ProfileHeader>

         <Box sx={projectsContentStyles({ isNoneProjects: projectIsError })}>
            {projectIsLoading ? (
               <LoaderDefault stylesInner={projectsLoaderInnerStyles} />
            ) : projectData?.data.length === 0 ? (
               <ProjectsNone />
            ) : (
               projectData && <ProjectsData projectData={projectData} />
            )}
         </Box>
      </ColumnDashboard>
   );
};
