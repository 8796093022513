import React, { useRef, FC } from 'react';

import { useProfileConfirmCode } from '@hooks/hooksApi/useProfileConfirmCode';

import { ModalAction } from '@shared/modals';
import { ErrorMessage } from '@shared/errorMessage';
import { InputDefault } from '@shared/inputs';

import editPasswordImage from '@assets/images/editPassword.png';

import { confirmCode } from '../../../utils/confirmCode';

import type { AccountPersonalDataModalConfirCodeProps } from './type';

import { buttonCloseStyles } from './style';

export const AccountPersonalDataModalConfirmCode: FC<
   AccountPersonalDataModalConfirCodeProps
> = ({ open, setOpen }) => {
   const codeRef = useRef<HTMLInputElement>(null);

   const { confirmCodeData, isError, error } = useProfileConfirmCode();

   const handleModal = () => {
      setOpen(!open);
   };
   const onClickAction = async () => {
      if (codeRef.current?.value) {
         const code = codeRef.current?.value;
         const res = await confirmCode({ confirmCodeData, code });
         if (res) {
            setOpen(!open);
         }
      }
   };

   return (
      <ModalAction
         onClickAction={onClickAction}
         text="Введите код который мы отправили вам на телефон"
         open={open}
         onClose={handleModal}
         image={editPasswordImage}
         textClose="Вернуться назад"
         textAction="Ок"
         sxButtonAction={buttonCloseStyles()}
      >
         {error && <ErrorMessage message={error && error?.data?.data.message} />}
         <InputDefault
            placeholder="Введите код"
            sxInner={{ width: '100%' }}
            sx={{ width: '100%' }}
            ref={codeRef}
         />
      </ModalAction>
   );
};
