import React, { CSSProperties, FC } from 'react';

import { Link } from 'react-router-dom';
import type { LogoProps } from './type';

import { logoStyles } from './style';

import logo from '../../assets/images/Logo.png';

const Logo: FC<LogoProps> = ({ style }) => {
   return (
      <Link to="/">
         <img style={{ ...logoStyles, ...style }} src={logo} alt="logo МастерСкрипт" />
      </Link>
   );
};

export default Logo;
