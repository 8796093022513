import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { AccountPersonalDataCard } from './components/accountPersonalDataCard';
import { AccountPersonalDataForm } from './components/accountPersonalDataForm';

import { accountPersonalDataStyles } from './style';
import { useTheme } from '@hooks/useTheme';

export const AccountPersonalData: FC = () => {
   const { theme } = useTheme();

   return (
      <Box sx={accountPersonalDataStyles({ theme })}>
         <AccountPersonalDataCard />
         <AccountPersonalDataForm />
      </Box>
   );
};
