import { SxProps, Theme } from '@mui/material';

export const navigationInnerStyles: SxProps<Theme> = {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   height: '100%',
   padding: '24px 32px',
   gap: '90px',
};
export const navigationItemStyles: SxProps<Theme> = {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   alignItems: 'center',
   height: '100%',
   padding: '0 6px',
   gap: '16px',
};
