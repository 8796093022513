import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import LinkArrowSvg from './icons/linkArrowSvg';
import { Link as LinkMUI, Typography } from '@mui/material';

import type { LinkArrowProps } from '../types';
import { linkArrowStyles } from '../styles';

import { colorsUI } from 'constants/colorsUI';

const { light900, black200, light500 } = colorsUI;

export const LinkArrow: FC<LinkArrowProps> = ({
   href,
   label,
   color = light900,
   colorHover = light500,
   backgroundColor = black200,
   backgroundColorHover,
   disabled = false,
   styles,
   onClick,
   sx,
   arrowColor,
   arrowPosition = 'before',
   IconSvg,
   styleIcon = {},
}) => {
   const linkBaseStyles = linkArrowStyles({
      color,
      colorHover,
      backgroundColor,
      backgroundColorHover,
      disabled,
      arrowPosition,
   });

   return (
      <LinkMUI
         component={Link}
         onClick={onClick}
         to={href}
         sx={{ ...linkBaseStyles, ...sx }}
         style={styles}
      >
         {arrowPosition === 'before' &&
            (IconSvg ? (
               <IconSvg style={styleIcon} />
            ) : (
               <LinkArrowSvg htmlColor={arrowColor ? arrowColor : color} />
            ))}
         <Typography variant="body1">{label}</Typography>
         {arrowPosition === 'after' &&
            (IconSvg ? (
               <IconSvg style={styleIcon} />
            ) : (
               <LinkArrowSvg htmlColor={arrowColor ? arrowColor : color} />
            ))}
      </LinkMUI>
   );
};
