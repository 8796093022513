import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { IProfile } from '@projectTypes/profile';
import { convertToCamelCase } from '@utils/formatters';

const initialState: IProfile = {
   data: {
      email: '',
      firstName: '',
      surname: '',
      lastName: '',
      phoneCode: '',
      phoneNumber: '',
      timezone: '',
      dashboardTheme: 'light',
      updatedAt: '',
      language: {
         id: null,
         name: '',
         code: '',
      },
   },
};

const convertObjectKeysToCamelCase = (obj: any): any => {
   if (Array.isArray(obj)) {
      return obj.map((item) => convertObjectKeysToCamelCase(item));
   } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
         const camelKey = convertToCamelCase({ text: key });
         acc[camelKey] = convertObjectKeysToCamelCase(obj[key]);
         return acc;
      }, {} as any);
   }
   return obj;
};

const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {
      setProfileData(state, action: PayloadAction<IProfile>) {
         state.data = convertObjectKeysToCamelCase(action.payload.data);
      },
   },
});

export const { setProfileData } = profileSlice.actions;

export default profileSlice.reducer;
