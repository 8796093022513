import React, { FC, useState } from 'react';

import { ListAccordion } from '@shared/lists';

import type { VisualScriptStructureAccordionProps } from './type';

export const VisualScriptStructureAccordion: FC<VisualScriptStructureAccordionProps> = ({
   arrValues,
}) => {
   const [open, setOpen] = useState(false);

   return (
      <div>
         <ListAccordion items={arrValues} open={open} setOpen={() => setOpen(!open)} />
      </div>
   );
};
