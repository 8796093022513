import { CSSProperties } from 'react';

import { colorsUI } from 'constants/colorsUI';

const { light500 } = colorsUI;

export const innerStyles: CSSProperties = {
   display: 'flex',
   alignItems: 'center',
   gap: '16px',
};

export const buttonStyles: CSSProperties = {
   border: `1px solid ${light500}`,
   fontSize: '0.875rem',
   fontWeight: 700,
   lineHeight: '16px',
   padding: '8px 12px',
};
