import React, { useState, FC } from 'react';

import { useProfileChangePassword } from '@hooks/hooksApi';

import { ModalAction } from '@shared/modals';
import { FormChangepassword } from '../../forms/formChangepassword';

import { changePassword } from '../../../utils/changePassword';
import { clearFormEditPassword } from '../../../utils/clearFormEditPassword';

import editPasswordImage from '@assets/images/editPassword.png';

import type { AccountPersonalDataModalEditPasswordProps } from './type';
import type { IErrorsChangePassword } from '../../../types/changePasswordTypes';

import { buttonCloseStyles } from './style';

export const AccountPersonalDataModalEditPassword: FC<
   AccountPersonalDataModalEditPasswordProps
> = ({ open, setOpen, onClickAction }) => {
   const [currentPassword, setCurrentPassword] = useState<string>('');
   const [newPassword, setNewPassword] = useState<string>('');
   const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

   const [errors, setErrors] = useState<IErrorsChangePassword | null>(null);

   const { changePasswordData } = useProfileChangePassword();

   const handleModal = () => {
      setOpen(!open);
   };
   const onClickEditPassword = async () => {
      const data = {
         currentPassword,
         newPassword,
         confirmNewPassword,
      };
      console.log(data);
      const res = await changePassword({ data, changePasswordData, setErrors });
      if (res) {
         setOpen(!open);
         onClickAction();
         clearFormEditPassword({
            setConfirmNewPassword,
            setCurrentPassword,
            setNewPassword,
         });
      }
   };

   return (
      <>
         <ModalAction
            onClickAction={onClickEditPassword}
            title="Новый пароль"
            open={open}
            onClose={handleModal}
            image={editPasswordImage}
            textClose="Отмена"
            textAction="Сменить пароль"
            sxButtonAction={buttonCloseStyles()}
         >
            <FormChangepassword
               errors={errors}
               currentPassword={currentPassword}
               newPassword={newPassword}
               confirmNewPassword={confirmNewPassword}
               setCurrentPassword={setCurrentPassword}
               setNewPassword={setNewPassword}
               setConfirmNewPassword={setConfirmNewPassword}
            />
         </ModalAction>
      </>
   );
};
