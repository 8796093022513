import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';
import { SxProps, Theme } from '@mui/material';

const { success, successActive, light100, light600, transperent } = colorsUI;

export const accountProjectsStyles = ({ theme }: { theme: Theme }): SxProps<Theme> => ({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   alignItems: 'start',
   gap: '32px',
});

export const accountProjectsTableLinkStyles: CSSProperties = {
   background: transperent,
   color: light600,
   justifyContent: 'start',
};

export const accountProjectsTableItemDraftTextStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   fontFamily: 'Exo2-italic',
   color: light100,
});

export const accountProjectsButtonActionStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   background: success,

   '&:hover': {
      background: successActive,
   },
});
