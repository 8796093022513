import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';

import { InputDefault } from '@shared/inputs';
import { SelectDefault } from '@shared/selects';

import {
   formCreateManagerItemStyles,
   formCreateManagerItemContentStyles,
   labelStyles,
   inputStyles,
   selectStyles,
} from './style';

import { FormCreateManagerItemProps } from './type';

const selectValue = ['Option 1', 'Option 2', 'Option 3'];

const FormCreateManagerItem: FC<FormCreateManagerItemProps> = ({
   fio,
   email,
   projectsManager,
   id,
   onClickActive,
   activeIdComponet,
   onUpdateManagerData,
}) => {
   const [emailState, setEmailState] = useState(email);
   const [fioState, setFioState] = useState(fio);
   const [projectsManagerState, setProjectsManagerState] = useState(projectsManager);

   const onClickAddDraft = () => {
      alert('draft');
   };

   const onClickEdit = () => {
      alert('go to edit!');
   };

   const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
      setEmailState(event.target.value);
      onUpdateManagerData(id, { email: event.target.value });
   };

   const handleSelectFio = (event: SelectChangeEvent<string>) => {
      setFioState(event.target.value);
      onUpdateManagerData(id, { fio: event.target.value });
   };
   const handleSelectProjectsManager = (event: SelectChangeEvent<string>) => {
      setProjectsManagerState(event.target.value);
      onUpdateManagerData(id, { projectsManager: event.target.value });
   };

   return (
      <Box
         sx={formCreateManagerItemStyles(activeIdComponet === id)}
         onClick={() => onClickActive(id)}
      >
         <Box sx={formCreateManagerItemContentStyles()}>
            <InputDefault
               stylesWrapper={inputStyles}
               onChange={onChangeEmail}
               value={emailState}
               type="email"
               labelStyles={labelStyles}
               labelVariant="overline"
               placeholder="Электронная почта менеджера"
               label="Почта будет использоваться для логина менеджера"
            />
            <SelectDefault
               styles={selectStyles}
               placeholder="ФИО менеджера"
               onChange={handleSelectFio}
               arrValues={selectValue}
               value={fioState}
               label="Можно использовать в константах скрипта, чтобы выводить ФИО прямо в текст фразы менеджера"
               labelStyles={labelStyles}
               labelVariant="overline"
            />
            <SelectDefault
               styles={selectStyles}
               placeholder="Проекты менеджера"
               onChange={handleSelectProjectsManager}
               arrValues={selectValue}
               value={projectsManagerState}
               label="Укажите, к каким скриптам будет доступ у вашего менеджера"
               labelStyles={labelStyles}
               labelVariant="overline"
            />
         </Box>
      </Box>
   );
};

export default FormCreateManagerItem;
