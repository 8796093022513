import { SxProps, Theme } from '@mui/material/styles';

export const profileHeadereStyle = (theme: Theme): SxProps<Theme> => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   gap: '24px',
   flexWrap: 'wrap',

   [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px',
      padding: '8px 0',
   },
});

export const profileHeaderTitleStyle: SxProps<Theme> = (theme: Theme) => ({
   textTransform: 'uppercase',
   fontSize: '1.875rem',
   fontWeight: 700,
   lineHeight: '100px',
   [theme.breakpoints.down('sm')]: {
      lineHeight: '40px',
      fontSize: '1.75rem',
   },
});
