import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const projectsNoneStyles = (theme: Theme): SxProps<Theme> => ({
   [theme.breakpoints.down('md')]: {
      alignItems: 'normal',
   },
});

export const projectsNoneImageStyles: CSSProperties = {
   width: '100%',
   maxWidth: '489px',
   objectFit: 'cover',
};

export const projectsNoneButtonStyles: SxProps<Theme> = {
   padding: '11px 42px',
};
