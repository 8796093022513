import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { colorsUI } from '@constants/colorsUI';

const { light900 } = colorsUI;

export const formChangepasswordStyles = ({
   theme,
}: {
   theme: Theme;
}): SxProps<Theme> => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: '16px',
   [theme.breakpoints.down('md')]: {
      gap: '8px',
   },
});

export const formChangepasswordInputStyles: CSSProperties = {
   width: '100%',
};

export const formChangepasswordInputDescStyles: CSSProperties = {
   marginTop: '10px',
   color: light900,
};
