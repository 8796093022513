import { colorsUI } from 'constants/colorsUI';

import { ButtonDefaultStylesType } from '../types';

const { disabledBackground, disabledText } = colorsUI;

export const buttonDefaultStyles: ButtonDefaultStylesType = ({
   color,
   colorHover,
   backgroundColor,
   backgroundColorHover,
   disabled,
}) => ({
   backgroundColor: disabled ? disabledBackground : backgroundColor,
   color: disabled ? disabledText : color,
   transition: ' all .5s',
   ...(!disabled && {
      '&:hover': {
         backgroundColor: backgroundColorHover,
         color: colorHover,
         transition: ' all .5s',
      },
   }),

   pointerEvents: disabled ? 'none' : 'auto',
});
