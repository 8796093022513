import { useState, useEffect } from 'react';
import { useTreeQuery } from 'app/dashboard/store/api/treeApi';

import type { VisualScriptChatMessageType } from '../../components/visualScriptChat/types';
import { useVisualScriptChatLogicParams } from './type';
import { useRuntimeClickCellMutation } from 'app/dashboard/store/api/runtimeApi';
import { IRuntimeReaction } from '@projectTypes/runtime';

import { handleReactionClick } from '../../components/visualScriptChat/utils';

export const useVisualScriptChatLogic = ({
   runtimeData,
}: useVisualScriptChatLogicParams) => {
   const [reactionsButton, setReactionsButton] = useState<IRuntimeReaction[] | null>(
      null,
   );
   const [messages, setMessages] = useState<VisualScriptChatMessageType[]>([]);
   const [userHasReplied, setUserHasReplied] = useState(false);
   const [isEnd, setIsEnd] = useState(false);
   const [exodus, setExodus] = useState<string | null>(null);
   const [errorClickCell, setErrorClickCell] = useState<string>('');

   const [
      triggerClickCell,
      { data: runtimeClickData, isLoading, isError: mutationError },
   ] = useRuntimeClickCellMutation();

   useEffect(() => {
      if (runtimeData && runtimeData.data.layer) {
         const firstNode = runtimeData.data.layer.node;
         const reactions = runtimeData.data.layer.reactions as IRuntimeReaction[];
         const hint = runtimeData.data.layer.node.properties.hint;
         setReactionsButton(reactions);

         setMessages((prevMessages) => [
            ...prevMessages,
            {
               id: firstNode.id,
               type: 'manager',
               text: firstNode.properties.text,
               hint,
            },
         ]);
      }
   }, [runtimeData]);

   const handleOnClickCell = async (reaction: IRuntimeReaction) => {
      try {
         await handleReactionClick({
            reaction,
            runtimeData,
            triggerClickCell,
            setMessages,
            setUserHasReplied,
            setReactionsButton,
            setIsEnd,
            userHasReplied,
            setExodus,
         });
      } catch (error) {
         console.error('Error handling reaction click:', error);
         setErrorClickCell('ошибка при обработке клика попробуйте чуть позже!');
      }
   };
   return {
      messages,
      exodus,
      isLoading,
      errorClickCell,
      reactionsButton,
      isEnd,
      handleOnClickCell,
   };
};
