import { colorsUI } from 'constants/colorsUI';
import { SxProps, Theme } from '@mui/material/styles';

const { black200, light600 } = colorsUI;

export const breadcrumbsStyles: SxProps<Theme> = (theme: Theme) => ({
   [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
   },
});
export const breadcrumbsLinkStyles: SxProps<Theme> = (theme: Theme) => ({
   color: black200,
   marginTop: '24px',
   '&:hover': {
      color: light600,
   },
});
