import { useProfileUpdatePhoneMutation } from 'app/dashboard/store/api/profileApi';

import type { IUpdatePhoneRequest } from '@projectTypes/profile';
import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useProfileUpdatePhone = () => {
   const [updatePhone, { isError, error }] = useProfileUpdatePhoneMutation();

   const updatePhoneData = async (data: IUpdatePhoneRequest) => {
      console.log(data);
      try {
         const result = await updatePhone({
            data,
         }).unwrap();
         console.log('Ответ от сервера:', result);
         return true;
      } catch (err) {
         const customError = err as IErrorResponse;

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.data.message);
            throw new Error(customError.data.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return { updatePhoneData, isError, error: error as IErrorResponse };
};
