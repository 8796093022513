import { useRuntimeLogQuery } from 'app/dashboard/store/api/runtimeLogApi';

export const useRuntimeLogData = () => {
   const {
      data: runtimeLogData,
      currentData,
      isLoading: runtimeLogtIsLoading,
      isError: runtimeLogtIsError,
      isSuccess: runtimeLogtIsSuccess,
      isFetching: runtimeLogtIsFetching,
   } = useRuntimeLogQuery({});

   console.log(currentData);

   return {
      runtimeLogData,
      runtimeLogtIsLoading,
      runtimeLogtIsError,
      runtimeLogtIsSuccess,
      runtimeLogtIsFetching,
   };
};
