import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IProjectCustomFields } from '@projectTypes/projects';

const initialState: IProjectCustomFields = {
   data: [],
   links: {
      first: '',
      last: '',
      prev: '',
      next: '',
   },
   meta: {
      current_page: 1,
      from: 0,
      last_page: 1,
      links: [],
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
   },
};
const projectsCustomFieldsSlice = createSlice({
   name: 'projects',
   initialState,
   reducers: {
      setProjectCustomFieldsData(state, action: PayloadAction<IProjectCustomFields>) {
         if (state) {
            state.data = action.payload.data;
            state.links = action.payload.links;
            state.meta = action.payload.meta;
         } else {
            return action.payload;
         }
      },
   },
});

export const { setProjectCustomFieldsData } = projectsCustomFieldsSlice.actions;
export default projectsCustomFieldsSlice.reducer;
