import { SxProps, Theme } from '@mui/material/styles';
import { CSSProperties } from 'react';

import type { ManagersStylesProps } from '../type';

export const managersContentStyles = ({
   isNoneMenegers,
}: ManagersStylesProps): SxProps<Theme> => ({
   width: '100%',
   height: '100%',
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column',
   justifyContent: isNoneMenegers ? 'center' : 'start',
});

export const managersNoneButtonStyles: SxProps<Theme> = {
   padding: '11px 42px',
};
