import React, { FC } from 'react';

import { useTheme } from '@hooks/useTheme';

import { Box } from '@mui/material';
import type { ColumnProps } from '../types';
import { columnStyles } from '../styles';

export const ColumnDashboard: FC<ColumnProps> = ({
   gap = '24px',
   gapMiddle = '22px',
   gapMobile = '19px',
   children,
   styles,
}) => {
   const { theme } = useTheme();

   const columnDashboardStyles = columnStyles({
      theme,
      gap,
      gapMiddle,
      gapMobile,
      customStyles: styles,
   });

   return <Box sx={columnDashboardStyles}>{children}</Box>;
};
